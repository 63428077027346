import React from 'react';
import styled from 'styled-components';
import { ButtonBasicText } from 'Elements/ButtonBasicText';

const ButtonBasicTextDiv = styled(ButtonBasicText)`
    ${props => props._width ? `width: ${props._width}` :'width: auto'};
    flex: 0 auto;
`;

const ButtonNotRubberText = (props) => {

    return (
        <ButtonBasicTextDiv {...props}>
            {props.children}
        </ButtonBasicTextDiv>
    );
};

export { ButtonBasicTextDiv, ButtonNotRubberText }


