import React from 'react';

export class Exceptor extends React.Component {

    defaultState = {
        isErrorState: false,
        error: null,
    };

    constructor(props) {
        super(props);
        this.state = this.defaultState;
    }

    static getDerivedStateFromError(error) {

        return {
            isErrorState: true,
            error: error,
        }
    }

    componentDidCatch(error, errorInfo) {

        const redStyle = 'background-color: #800; color: white; padding: 2px 6px; border-top: 1px white solid';

        console.log('%c= we got an error =============================================', redStyle);
        console.log('%ccall stack', redStyle);
        console.log(error.stack, redStyle);
        console.log('%ccomponent stack', redStyle);
        console.log('Error: ' + error.message + errorInfo.componentStack);

        this.setState(this.defaultState);
    }

    render() {

//        if (this.state.isErrorState) {
//            return <Redirect to="/ouch" />;
//        }

        return this.props.children;
    }
}