import React from 'react';
import styled from 'styled-components';

const TextFieldInput = styled.input`
    font-weight: 400;
    font-size: 14px;
    font-family: Roboto;
    height: 32px;
    color: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    width: ${props => props._width ?? 'auto'};
    background-color: transparent;
    padding: 8px 16px;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const TextField = (props) => {

    return (
        <TextFieldInput {...props} />
    );
}

export { TextField, TextFieldInput }
