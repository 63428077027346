/**
 * usage:
 * <Table
 *     columnTemplate="10% 30% 40% 20%" // optional
 *     data={data}
 * />
 *
 * data = {
 *     header: [
 *     ],
 *     content: [
 *         [ row 1 ],
 *         ...
 *     ],
 * }
 */

import React from 'react';
import styled from 'styled-components';
import { TextBasic } from 'Elements/TextBasic';
import { Centered } from 'Wrappers/Centered';
import { Loader } from 'Elements/Loader';


/**
 * create with template for columns value based on TableElement.props
 */
const generateGridTemplateColumn = props => {

    if (props.columnTemplate) {
        return props.columnTemplate;
    }

    return 'auto '.repeat(Object.keys(props.data.header).length).trim();

}

const TableElement = styled.table`
    width: 100%;
    border-collapse: collapse;
`

const TableHeaderCell = styled.th`
    text-align: left;
    padding-bottom: 8px;
`
const TableContentCell = styled.td`
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 10px 5px 0;
`

const TableHeadContainer = styled.thead`
    border-bottom: 1px solid #c4c4c4;
`

const TableBodyContainer = styled.tbody`
    &::before {
        line-height: 8px;
        content: "-";
        color: transparent;
        display: block;
    }
`

const TableRowContainer = styled.tr`
    & :hover {
        background-color: rgba(196, 196, 196, 0.3);
    }
`

const TableCol = styled.col`
    width: ${ props => props.widthStyle ? props.widthStyle : 'auto' };
`

/**
 * table header element
 */
const renderHeaderCell = (content, key) => {

    return (
        <TableHeaderCell key={key}>
            { typeof content == 'string' || typeof content == 'number'
                ?
                <TextBasic>
                    {content}
                </TextBasic>
                :
                content
            }
        </TableHeaderCell>
    );
}

const renderContentCell = (content, key) => {
    return (
        <TableContentCell key={key}>
            <TextBasic>
                {content}
            </TextBasic>
        </TableContentCell>
    );
}

const renderRow = (content) => {
    return (
        <TableRowContainer>
            {content}
        </TableRowContainer>
    )
}

const renderColgroup = (template) => {
    const widthGroup = template.split(' ');
    return(
        <colgroup>
            {widthGroup.map(width => {
                return (
                    <TableCol span="1" widthStyle={width} />
                )
            })}
        </colgroup>
    )
}

const tableContent = (tableData, columnTemplate) =>{
    console.log(tableData)
    return (
        <>
            {renderColgroup(columnTemplate)}
            <TableHeadContainer>
                <tr>
                    {Object.values(tableData.header).map( (titleElement, index) => {
                        return renderHeaderCell(titleElement, `head-cell-${index}`)
                    })}
                </tr>
            </TableHeadContainer>
            <TableBodyContainer>
                {tableData.content.map( (row, indexRow) => {
                    console.log(row)
                    return renderRow(Object.keys(tableData.header).map(( field , indexCell) => {
                        return renderContentCell(row[field],`body-cell-${indexRow}-${indexCell}`)
                    }))
                })}
            </TableBodyContainer>
        </>
    )
}

const Table = (props) => {

    if ( ! props.data.header) {
        throw new Error('data.header is missing');
    }

    return (
        <div className={props.className}>
            <TableElement>
                {
                    tableContent(props.data, generateGridTemplateColumn(props))
                }
            </TableElement>

            { props.loading &&
                <Centered style={{ marginTop: '16px' }}>
                    <Loader />
                </Centered>
            }

            { ! props.loading && (props.data.content.length === 0) &&
                <Centered style={{ marginTop: '24px' }}>
                    <TextBasic>
                        {props.noDataText || 'No data' }
                    </TextBasic>
                </Centered>
            }
        </div>
    );
}

export { Table }
