/**
 *
 */

import { observable, action } from 'mobx';
import { requestPost } from 'DataSource/requestPost';

class AuthStoreClass {

    urlLogin = '/api/auth/login';
    urlLogout = '/api/auth/logout';

    /**
     * индикатор состояния
     */
    @observable
    loading = false;


    /**
     * массив с данными юзера
     */
    @observable
    userinfo = {};

    /**
     * тут у нас будет токен, полученный после аутентификации
     */
    @observable
    token = '';

    /**
     * ошибка, при наличии
     */
    @observable
    error = '';

    /**
     * флажек истечения токена
     */
    @observable
    tokenInvalid = false;

    /**
     *
     */
    @action
    setLoading = (newValue) => {
        this.loading = !! newValue;
    }

    /**
     *
     */
    @action
    setUserinfo = (data) => {
        this.userinfo = data;
    }

    /**
     *
     */
    @action
    setToken = (token) => {
        this.token = token;
        this.tokenStore(token);
    }

    /**
     *
     */
    @action
    setError = (text) => {
        console.warn('got error:', text);
        this.error = text;
    }

    /**
     *
     */
    @action
    login = (email, password) => {

        this.setLoading(true);
        this.setTokenInvalidStatus(false);
        this.setUserinfo({});

        const data = {
            email: email,
            password: password,
        };

        return requestPost(this.urlLogin, data)
            .then(response => {
                this.setError('');
                this.setToken(response.data.token);
                this.setUserinfo(response.data);
            })
            .catch(error => {

                if ( ! error.response) {
                    this.setError('something went wrong');
                    return;
                }

                switch(error.response.status) {
                    case 400:
                        this.setError('not a something good');
                        break;
                    case 403:
                        this.setError('bad credentials');
                        break;
                    default:
                        this.setError('something went wrong');
                        break;
                };
            })
            .finally(data => {
                this.setLoading(false);
                return data;
            })
            ;
    }

    /**
     *
     */
    @action
    logout = () => {

        return requestPost(this.urlLogout)
            .then(response => {
                this.setToken('');
            })
            .catch(error => {
                alert('Failed to log out');
            });
    }

    /**
     *
     */
    tokenStore = () => {
        localStorage.setItem('authToken', this.token);
    }

    /**
     *
     */
    @action
    tokenRestore = () => {
        this.token = localStorage.getItem('authToken');
    }

    /**
     *
     */
    @action
    setTokenInvalidStatus = (isTokenInvalid = false) => {
        this.tokenInvalid = isTokenInvalid;
    }

}

const AuthStore = new AuthStoreClass();

export { AuthStore }

