/**
 *
 */

import { observable, action } from 'mobx';
import axios from 'axios';

class ConfigStoreClass {

    configUri = '/config.json?d=' + Math.random();

    @observable
    config = null;

    @action
    setConfig = (data) => {
        this.config = data;
    }

    loadConfig = (email, password) => {
        return axios.get(this.configUri)
            .then(
                response => this.setConfig(response.data)
            );
    }

}

const ConfigStore = new ConfigStoreClass();

export { ConfigStore }

