import React from 'react';
import styled from 'styled-components';
import { ButtonBasic } from 'Elements/ButtonBasic';

const ButtonBasicTextDiv = styled(ButtonBasic)`
    padding: 8px 16px;
`;

const ButtonBasicText = (props) => {

    return (
        <ButtonBasicTextDiv {...props}>
            {props.children}
        </ButtonBasicTextDiv>
    );
};

export { ButtonBasicTextDiv, ButtonBasicText }


