import React, {useRef, useEffect, useState} from 'react';
import styled, { css } from 'styled-components';

const DEFAULT_DURATION = '500ms';
const DEFAULT_MAX_HEIGHT = '100vh';

const CollapsibleDiv = styled.div`
    margin-left: -1px;
    padding-left: 1px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: center;
    overflow: hidden;
    opacity: 1;
    display: flex;
`;

const CollapsibleContent = styled.div`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    margin-top: ${props => props.containerHeight ? `-${props.containerHeight}px` : `-${DEFAULT_MAX_HEIGHT}`};
    transition: margin-top ${ props => props.duration ? props.duration : DEFAULT_DURATION} ease;
    
    ${ props => !props.collapsed && css`
        margin-top: 0;
    `};
`;

const Collapsible = (props) => {

    const [elHeight, setElHeight] = useState(0);
    const el = useRef();

    useEffect(
        () => {
            props.collapsed && setElHeight(el.current.scrollHeight);
        },
        [props.collapsed]
    );

    return (
            <CollapsibleDiv>
                <CollapsibleContent {...props} containerHeight={elHeight} ref={el}>
                    {props.children}
                </CollapsibleContent>
            </CollapsibleDiv>
    );
};

export { Collapsible }
