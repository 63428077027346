import React from "react";
import {observer} from "mobx-react";

import { CounterStore } from 'DataSource/Stores/CounterStore';

import { Filled } from 'Wrappers/Filled';

import { ButtonDel } from 'Elements/ButtonDel';
import { TextField } from 'Elements/TextField';


const Counter = (props) => {

    const {
        index,
        showRemoveButton
    } = props;


    return (
        <>
            <Filled>
                <TextField
                    placeholder={`Enter counter name ${index+1}`}
                    value={CounterStore.counters[index].title}
                    onChange={ e => CounterStore.setCounterValue(index, e.target.value) } />
                { showRemoveButton &&
                    <ButtonDel onClick={ () =>  CounterStore.removeCounter(index) } title="Delete counter" />
                }
            </Filled>
        </>
    )
}

const CounterObserver = observer(Counter);

export { CounterObserver as Counter }
