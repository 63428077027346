import React from 'react';
import styled from 'styled-components';

const BlockTitleDiv = styled.div`
    display: flex;
    align-items: baseline;

    & > span:nth-child(1) {
        flex: 0 10 100%;
    }

`;

const BlockTitle = (props) => {

    return (
        <BlockTitleDiv>
            { props.children }
        </BlockTitleDiv>
    );
}

export { BlockTitle }