import React from 'react';
import styled from 'styled-components';
import { Title } from 'Elements/Title';
import { TextBasic } from 'Elements/TextBasic';
import { DatePicker } from 'Elements/DatePicker';

const DateFilterDiv = styled.div`
    display: flex;
    align-items: baseline;

    .date-filter-title {
        flex: 0 10 100%;
    }

    .react-datepicker-wrapper {
        width: 150px;
        flex-shrink: 0;
        flex-grow: 0;
    }

    .date-filiter-dash {
        flex: 0 0 1px;
        margin: 0 8px;
    }

`;

const DateFilter = (props) => {

    const {
        title,
        setDateFrom,
        setDateTo,
        dateFrom,
        dateTo
    } = props;

    return (
        <DateFilterDiv>
            <Title className="date-filter-title">{title || 'title'}</Title>
            <DatePicker placeholder="from" selected={dateFrom} onChange={ date => setDateFrom(date) } />
            <TextBasic className="date-filiter-dash">&mdash;</TextBasic>
            <DatePicker placeholder="to" selected={dateTo} onChange={ date => setDateTo(date) } />
        </DateFilterDiv>
    );
}

export { DateFilter }