/**
 *
 */

import { observable, action } from 'mobx';
import { requestPost } from 'DataSource/requestPost';

export class StatStoreClass {

    /**
     * индикатор состояния
     */
    @observable
    loading = false;


    /**
     * массив с загруженными элементами
     */
    @observable
    data = [];

    /**
     *
     */
    @action
    setLoadingState = (newValue) => {
        this.loading = !! newValue;
    }

    @action
    setData = (data) => {
        this.data = data;
    }

    /**
     *
     */
    load = (url, postData = {}) => {

        this.setLoadingState(true);
        this.setData([]);

        return requestPost(url, postData)
            .then(response => {
                if (Array.isArray(response.data)) {
                    this.setData(response.data);
                } else {
                    this.setData([response.data]);
                }
            })
            .finally(data => {
                this.setLoadingState(false);
                return data;
            })
            ;
    }
}
