import React from 'react';
import { ButtonBasicIcon } from 'Elements/ButtonBasicIcon';

const ButtonRefresh = (props) => {

    return (
        <ButtonBasicIcon {...props}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="m 16,11 c 0,3 -2.692,6 -6,6 C 6.692,17 4,14.308 4,11 4,7.692 6.692,5 10,5 V 7 L 15,4 10,1 v 2 c -4.418,0 -8,3.582 -8,8 0,4.418 3.582,8 8,8 4.418,0 8,-3 8,-8 z" />
            </svg>
        </ButtonBasicIcon>
    );
}

export { ButtonRefresh }


