import axios from 'axios';
import { ConfigStore } from 'DataSource/Stores/ConfigStore.js';
import { AuthStore } from 'DataSource/Stores/AuthStore.js';

const getAxios = () => {
    const defaultConfig = {
        baseURL : ConfigStore.config.API_BASE_URL,
    };

    const axiosInstance = axios.create(defaultConfig);

    axiosInstance.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.request && error.request.status === 403) {
                AuthStore.setTokenInvalidStatus(true);
                AuthStore.setToken('');
            }
            throw error;
        });

    return axiosInstance;
}


export { getAxios }
