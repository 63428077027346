import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in';
import { observer } from 'mobx-react';

import { CampaignStore } from 'DataSource/Stores/CampaignStore';

import { Header } from 'Components/Header';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';

import { Title } from 'Elements/Title';
import { ButtonAdd } from 'Elements/ButtonAdd';
import { ButtonSearch } from 'Elements/ButtonSearch';
import { ButtonRefresh } from 'Elements/ButtonRefresh';

import { Search } from 'Blocks/Search';
import { BlockCreate } from './BlockCreate';
import { BlockTable } from './BlockTable';

const CampaignsPage = (props) => {

    const [ searchMode, stateSetSearchMode ] = useState(false);
    const [ searchText, stateSetSearchText ] = useState('');
    const [ createMode, stateSetCreateMode ] = useState(false);

    /**
     * для модуля поиска
     */
    const setSearchMode = (mode) => {

        const newMode = typeof mode !== 'undefined' ? !! mode : ! searchMode;
        stateSetSearchMode(newMode);

        if (!newMode) {
            stateSetSearchText('');
        }
    };

    /**
     *
     */
    const setCreateMode = (mode) => {

        const newMode = typeof mode !== 'undefined' ? !! mode : ! createMode;
        stateSetCreateMode(newMode);
    };

    /**
     *
     */
    const campaignCreate = (newName) => {
        CampaignStore.create({ title: newName }).then(refresh);
        setCreateMode(false);
    }

    /**
     *
     */
    const refresh = () => {
        CampaignStore.all();
    }

    /**
     *
     */
    useEffect(
        () => {
            console.log('campaign page init');
            refresh();
        },
        []
    );

    /**
     *
     */
    return (
        <>
            <FadeIn>
                <Block>
                    <Header />
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Filled>
                        <Title>
                            Campaigns
                        </Title>
                        <ButtonAdd onClick={ () => setCreateMode(!createMode) } title="Add campaign" />
                        <ButtonSearch onClick={ () => setSearchMode(!searchMode) } title="Quick filter" />
                        <ButtonRefresh onClick={refresh} title="Refresh" />
                    </Filled>
                </Block>
            </FadeIn>

            { createMode &&
                <FadeIn>
                    <BlockCreate
                        placeholder="Enter campaign name"
                        setCreateMode={setCreateMode}
                        submit={campaignCreate}
                    />
                </FadeIn>
            }

            { searchMode &&
                <FadeIn>
                    <Search
                        searchText={searchText}
                        placeholder="Enter campaign name"
                        setSearchMode={setSearchMode}
                        setSearchText={text => stateSetSearchText(text)}
                    />
                </FadeIn>
            }

            <FadeIn>
                <BlockTable
                    store={CampaignStore}
                    filterText={searchText}
                />
            </FadeIn>

        </>
    );
}

const CampaignsPageObserver = observer(CampaignsPage);

export { CampaignsPageObserver as CampaignsPage }
