import React from 'react';
import { ButtonBasicIcon } from 'Elements/ButtonBasicIcon';

const ButtonBack = (props) => {

    return (
        <ButtonBasicIcon {...props}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="m 9.999997,14.6003 h -4.72725 l 2.62967,-2.6305 -1.14693,-1.1469 -4.58856,4.5885 c 1.6758,1.6758 2.96225,2.9623 4.58856,4.5886 l 1.14693,-1.1469 -2.62967,-2.6305 h 4.72725 c 4.47989,0 8.11129,-3.6314 8.11129,-8.11131 0,-4.47987 -3.6314,-8.111289682531 -8.11129,-8.111289290889 L 1.888713,1.41822e-6 V 1.62226 h 8.111284 c 3.58359,0 6.48899,2.90546 6.48899,6.48903 0,3.58361 -2.9054,6.48901 -6.48899,6.48901 z" />
            </svg>
        </ButtonBasicIcon>
    );
}

export { ButtonBack }


