import React from 'react';
import { ButtonBasicIcon } from 'Elements/ButtonBasicIcon';

const ButtonClose = (props) => {

    return (
        <ButtonBasicIcon {...props}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.446 10L20 18.554L18.553 20L10 11.446L1.446 20L0 18.554L8.553 10L0 1.446L1.446 0L10 8.554L18.553 0L20 1.446L11.446 10Z" />
            </svg>
        </ButtonBasicIcon>
    );
}

export { ButtonClose }


