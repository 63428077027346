import React from 'react';
import { ButtonBasicIcon } from 'Elements/ButtonBasicIcon';

const ButtonDel = (props) => {

    return (
        <ButtonBasicIcon {...props}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7 16H9V8H7V16ZM11 16H13V8H11V16ZM5 18H15V6H5V18ZM7 4H13V2H7V4ZM15 4V0H5V4H0V6H3V20H17V6H20V4H15Z" fill="black"/>
            </svg>
        </ButtonBasicIcon>
    );
}

export { ButtonDel }


