import React from 'react';
import styled from 'styled-components';
import { ButtonLogout } from 'Elements/ButtonLogout';
import { Title } from 'Elements/Title';
import { Logo } from 'Elements/Logo';
import { Link } from 'Elements/Link';
import { AuthStore } from 'DataSource/Stores/AuthStore.js';

const HeaderDiv = styled.div`
    display: flex;
    align-items: center;

    & > span:nth-child(2) {
        flex: 0 10 100%;
    }

`;

const Header = (props) => {

    return (
        <HeaderDiv>
            <ButtonLogout onClick={AuthStore.logout} />
            <Title>
            </Title>
            <Link to="/">
                <Logo _width="320px" />
            </Link>
        </HeaderDiv>
    );
}

export { Header }