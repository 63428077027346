import React, { useState } from 'react';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';

import { Title } from 'Elements/Title';
import { ButtonClose } from 'Elements/ButtonClose';
import { TextField } from 'Elements/TextField';
import { ButtonBasicText } from 'Elements/ButtonBasicText';

const BlockCreate = (props) => {

    const {
        setCreateMode,
        placeholder = 'You missed placeholder prop!',
        submit,
    } = props;

    const [ newText, setText ] = useState('');

    /**
     *
     */
    const onSetText = (event) => {
        setText(event.target.value);
    }

    const onSubmit = () => {
        submit(newText);
        setText('');
    }

    /**
     *
     */
    return (
        <Block>
            <Filled>
                <Title>
                    Create new campaign
                </Title>
                <ButtonClose onClick={() => setCreateMode(false)} />
            </Filled>
            <Filled>
                <TextField placeholder={placeholder} value={newText} onChange={onSetText} />
            </Filled>
            <Filled>
                <ButtonBasicText onClick={() => onSubmit(newText)}>
                    Create campaign
                </ButtonBasicText>
            </Filled>
        </Block>

    );
}

export { BlockCreate }
