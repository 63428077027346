import React from 'react';



export const Image404 = () => {

    return (
        <svg viewBox="130 40 545 395" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="138" y="88" width="529" height="325">
                <path d="M293.6 327.1L312.4 316.2L345.4 335.3L326.6 346.1L365.8 368.7L323.1 393.4L283.9 370.8L212.4 412.0L183.8 398.4L138.7 287.0L138.3 286.8L181.1 262.1L293.6 327.1ZM217.6 370.9L250.9 351.7L194.0 318.9L195.7 323.0L217.6 370.9Z" fill="#00A8FF"/>
                <path d="M456.1 229.4C481.1 243.8 494.7 258.2 497.0 272.5C499.4 286.7 490.3 299.8 470.0 311.5C449.4 323.4 426.7 328.6 401.8 327.3C377.0 325.9 352.2 318.1 327.4 303.8L293.6 284.3C268.7 269.9 255.0 255.5 252.7 241.3C250.4 227.0 259.5 213.9 279.9 202.1C300.4 190.3 323.1 185.1 347.9 186.4C372.8 187.8 397.7 195.7 422.5 210.0L456.1 229.4ZM374.2 231.5C360.9 223.8 349.3 219.1 339.3 217.3C329.2 215.5 320.4 216.8 312.9 221.2C305.6 225.4 303.2 230.3 305.9 235.7C308.7 241.1 316.2 247.5 328.3 254.8L375.5 282.0C389.2 290.0 401.1 294.8 411.0 296.5C420.8 298.2 429.5 296.8 437.0 292.5C444.3 288.3 446.5 283.4 443.8 277.8C441.0 272.2 433.1 265.6 419.9 257.8L374.2 231.5Z" fill="#00A8FF"/>
                <path d="M594.5 153.3L613.3 142.5L646.3 161.5L627.5 172.4L666.7 195.0L624.0 219.7L584.8 197.1L513.4 238.3L484.7 224.7L439.6 113.3L439.3 113.1L482.0 88.4L594.5 153.3ZM518.6 197.2L551.8 178.0L495.0 145.2L496.6 149.2L518.6 197.2Z" fill="#00A8FF"/>
            </mask>
            <g mask="url(#mask0)" fill="#00A8FF" >
                <path d="M102.4 180.7L83.9 191.3L102.4 202.0L120.9 191.3L102.4 180.7Z" />
                <path d="M120.8 170.0L102.4 180.7L120.9 191.3L139.3 180.7L120.8 170.0Z"/>
                <path d="M139.3 159.4L120.8 170.0L139.3 180.7L157.7 170.0L139.3 159.4Z"/>
                <path d="M176.2 138.1L157.7 148.7L176.2 159.4L194.6 148.7L176.2 138.1Z"/>
                <path d="M213.0 116.8L194.6 127.4L213.1 138.1L231.5 127.4L213.0 116.8Z"/>
                <path d="M139.3 180.7L120.9 191.3L139.3 202.0L157.8 191.3L139.3 180.7Z"/>
                <path d="M157.7 170.0L139.3 180.7L157.8 191.3L176.2 180.7L157.7 170.0Z"/>
                <path d="M176.2 159.4L157.7 170.0L176.2 180.7L194.6 170.0L176.2 159.4Z"/>
                <path d="M194.6 148.7L176.2 159.4L194.6 170.0L213.1 159.4L194.6 148.7Z"/>
                <path d="M213.1 138.1L194.6 148.7L213.1 159.4L231.5 148.7L213.1 138.1Z"/>
                <path d="M231.5 127.4L213.1 138.1L231.5 148.7L250.0 138.1L231.5 127.4Z"/>
                <path d="M250.0 116.8L231.5 127.4L250.0 138.1L268.4 127.5L250.0 116.8Z"/>
                <path d="M120.9 212.6L102.4 223.3L120.9 233.9L139.3 223.3L120.9 212.6Z"/>
                <path d="M139.3 202.0L120.9 212.6L139.3 223.3L157.8 212.6L139.3 202.0Z"/>
                <path d="M157.8 191.3L139.3 202.0L157.8 212.6L176.2 202.0L157.8 191.3Z"/>
                <path d="M213.1 159.4L194.6 170.0L213.1 180.7L231.6 170.1L213.1 159.4Z"/>
                <path d="M268.4 127.5L250.0 138.1L268.4 148.8L286.9 138.1L268.4 127.5Z"/>
                <path d="M139.3 223.3L120.9 233.9L139.4 244.6L157.8 234L139.3 223.3Z"/>
                <path d="M157.8 212.6L139.3 223.3L157.8 234L176.2 223.3L157.8 212.6Z"/>
                <path d="M213.1 180.7L194.7 191.3L213.1 202.0L231.6 191.4L213.1 180.7Z"/>
                <path d="M250.0 159.4L231.6 170.1L250.0 180.7L268.5 170.1L250.0 159.4Z"/>
                <path d="M157.8 234L139.4 244.6L157.8 255.3L176.3 244.6L157.8 234Z"/>
                <path d="M194.7 212.7L176.2 223.3L194.7 234.0L213.2 223.3L194.7 212.7Z"/>
                <path d="M305.4 148.8L286.9 159.4L305.4 170.1L323.8 159.4L305.4 148.8Z"/>
                <path d="M176.3 244.6L157.8 255.3L176.3 265.9L194.7 255.3L176.3 244.6Z"/>
                <path d="M194.7 234.0L176.3 244.6L194.7 255.3L213.2 244.6L194.7 234.0Z"/>
                <path d="M250.0 202.0L231.6 212.7L250.1 223.3L268.5 212.7L250.0 202.0Z"/>
                <path d="M305.4 170.1L286.9 180.7L305.4 191.4L323.8 180.7L305.4 170.1Z"/>
                <path d="M323.8 159.4L305.4 170.1L323.8 180.7L342.3 170.1L323.8 159.4Z"/>
                <path d="M194.7 255.3L176.3 265.9L194.8 276.6L213.2 265.9L194.7 255.3Z"/>
                <path d="M231.6 234.0L213.2 244.6L231.6 255.3L250.1 244.6L231.6 234.0Z"/>
                <path d="M268.5 212.7L250.1 223.3L268.5 234.0L287.0 223.3L268.5 212.7Z"/>
                <path d="M305.4 191.4L286.9 202.0L305.4 212.7L323.9 202.0L305.4 191.4Z"/>
                <path d="M342.3 170.1L323.8 180.7L342.3 191.4L360.7 180.7L342.3 170.1Z"/>
                <path d="M213.2 265.9L194.8 276.6L213.2 287.2L231.7 276.6L213.2 265.9Z"/>
                <path d="M287.0 223.3L268.5 234.0L287.0 244.6L305.4 234.0L287.0 223.3Z"/>
                <path d="M323.9 202.0L305.4 212.7L323.9 223.4L342.3 212.7L323.9 202.0Z"/>
                <path d="M342.3 191.4L323.9 202.0L342.3 212.7L360.8 202.1L342.3 191.4Z"/>
                <path d="M360.7 180.7L342.3 191.4L360.8 202.1L379.2 191.4L360.7 180.7Z"/>
                <path d="M84.1 361.8L65.7 372.4L84.1 383.1L102.6 372.4L84.1 361.8Z"/>
                <path d="M121.0 340.5L102.6 351.1L121.0 361.8L139.5 351.1L121.0 340.5Z"/>
                <path d="M139.4 329.8L121.0 340.5L139.5 351.1L157.9 340.5L139.4 329.8Z"/>
                <path d="M157.9 319.2L139.4 329.8L157.9 340.5L176.4 329.8L157.9 319.2Z"/>
                <path d="M176.3 308.5L157.9 319.2L176.4 329.8L194.8 319.2L176.3 308.5Z"/>
                <path d="M194.8 297.9L176.3 308.5L194.8 319.2L213.2 308.5L194.8 297.9Z"/>
                <path d="M250.1 265.9L231.7 276.6L250.1 287.2L268.6 276.6L250.1 265.9Z"/>
                <path d="M268.5 255.3L250.1 265.9L268.6 276.6L287.0 266.0L268.5 255.3Z"/>
                <path d="M287.0 244.6L268.5 255.3L287.0 266.0L305.5 255.3L287.0 244.6Z"/>
                <path d="M305.4 234.0L287.0 244.6L305.5 255.3L323.9 244.7L305.4 234.0Z"/>
                <path d="M416.1 170.1L397.7 180.8L416.1 191.4L434.6 180.8L416.1 170.1Z"/>
                <path d="M434.5 159.5L416.1 170.1L434.6 180.8L453.0 170.1L434.5 159.5Z"/>
                <path d="M453.0 148.8L434.5 159.5L453.0 170.1L471.4 159.5L453.0 148.8Z"/>
                <path d="M471.4 138.2L453.0 148.8L471.4 159.5L489.9 148.8L471.4 138.2Z"/>
                <path d="M489.9 127.5L471.4 138.2L489.9 148.8L508.3 138.2L489.9 127.5Z"/>
                <path d="M102.6 372.4L84.1 383.1L102.6 393.7L121.0 383.1L102.6 372.4Z"/>
                <path d="M121.0 361.8L102.6 372.4L121.0 383.1L139.5 372.4L121.0 361.8Z"/>
                <path d="M139.5 351.1L121.0 361.8L139.5 372.4L157.9 361.8L139.5 351.1Z"/>
                <path d="M157.9 340.5L139.5 351.1L157.9 361.8L176.4 351.1L157.9 340.5Z"/>
                <path d="M268.6 276.6L250.1 287.2L268.6 297.9L287.0 287.3L268.6 276.6Z"/>
                <path d="M323.9 244.7L305.5 255.3L323.9 266.0L342.4 255.3L323.9 244.7Z"/>
                <path d="M342.3 234.0L323.9 244.7L342.4 255.3L360.8 244.7L342.3 234.0Z"/>
                <path d="M360.8 223.4L342.3 234.0L360.8 244.7L379.3 234.0L360.8 223.4Z"/>
                <path d="M397.7 202.1L379.2 212.7L397.7 223.4L416.1 212.7L397.7 202.1Z"/>
                <path d="M416.1 191.4L397.7 202.1L416.1 212.7L434.6 202.1L416.1 191.4Z"/>
                <path d="M453.0 170.1L434.6 180.8L453.0 191.4L471.5 180.8L453.0 170.1Z"/>
                <path d="M526.8 127.5L508.3 138.2L526.8 148.8L545.2 138.2L526.8 127.5Z"/>
                <path d="M139.5 372.4L121.0 383.1L139.5 393.7L158 383.1L139.5 372.4Z"/>
                <path d="M176.4 351.1L157.9 361.8L176.4 372.5L194.8 361.8L176.4 351.1Z"/>
                <path d="M213.3 329.9L194.8 340.5L213.3 351.2L231.7 340.5L213.3 329.9Z"/>
                <path d="M231.7 319.2L213.3 329.9L231.7 340.5L250.2 329.9L231.7 319.2Z"/>
                <path d="M268.6 297.9L250.1 308.6L268.6 319.2L287.1 308.6L268.6 297.9Z"/>
                <path d="M287.0 287.3L268.6 297.9L287.1 308.6L305.5 297.9L287.0 287.3Z"/>
                <path d="M323.9 266.0L305.5 276.6L323.9 287.3L342.4 276.6L323.9 266.0Z"/>
                <path d="M342.4 255.3L323.9 266.0L342.4 276.6L360.8 266.0L342.4 255.3Z"/>
                <path d="M360.8 244.7L342.4 255.3L360.8 266.0L379.3 255.3L360.8 244.7Z"/>
                <path d="M379.3 234.0L360.8 244.7L379.3 255.3L397.7 244.7L379.3 234.0Z"/>
                <path d="M397.7 223.4L379.3 234.0L397.7 244.7L416.2 234.0L397.7 223.4Z"/>
                <path d="M416.1 212.7L397.7 223.4L416.2 234.0L434.6 223.4L416.1 212.7Z"/>
                <path d="M434.6 202.1L416.1 212.7L434.6 223.4L453.0 212.7L434.6 202.1Z"/>
                <path d="M453.0 191.4L434.6 202.1L453.0 212.7L471.5 202.1L453.0 191.4Z"/>
                <path d="M508.4 159.5L489.9 170.1L508.4 180.8L526.8 170.2L508.4 159.5Z"/>
                <path d="M545.2 138.2L526.8 148.8L545.3 159.5L563.7 148.9L545.2 138.2Z"/>
                <path d="M563.7 127.5L545.2 138.2L563.7 148.9L582.2 138.2L563.7 127.5Z"/>
                <path d="M176.4 372.5L158 383.1L176.4 393.8L194.9 383.1L176.4 372.5Z"/>
                <path d="M268.6 319.2L250.2 329.9L268.6 340.5L287.1 329.9L268.6 319.2Z"/>
                <path d="M323.9 287.3L305.5 297.9L324.0 308.6L342.4 297.9L323.9 287.3Z"/>
                <path d="M379.3 255.3L360.8 266.0L379.3 276.6L397.7 266.0L379.3 255.3Z"/>
                <path d="M434.6 223.4L416.2 234.0L434.6 244.7L453.1 234.0L434.6 223.4Z"/>
                <path d="M489.9 191.4L471.5 202.1L490.0 212.8L508.4 202.1L489.9 191.4Z"/>
                <path d="M582.2 138.2L563.7 148.9L582.2 159.5L600.6 148.9L582.2 138.2Z"/>
                <path d="M158.0 404.4L139.5 415.1L158.0 425.7L176.4 415.1L158.0 404.4Z"/>
                <path d="M176.4 393.8L158.0 404.4L176.4 415.1L194.9 404.4L176.4 393.8Z"/>
                <path d="M194.9 383.1L176.4 393.8L194.9 404.4L213.3 393.8L194.9 383.1Z"/>
                <path d="M213.3 372.5L194.9 383.1L213.3 393.8L231.8 383.1L213.3 372.5Z"/>
                <path d="M268.6 340.5L250.2 351.2L268.7 361.8L287.1 351.2L268.6 340.5Z"/>
                <path d="M287.1 329.9L268.6 340.5L287.1 351.2L305.5 340.5L287.1 329.9Z"/>
                <path d="M305.5 319.2L287.1 329.9L305.5 340.5L324.0 329.9L305.5 319.2Z"/>
                <path d="M342.4 297.9L324.0 308.6L342.4 319.2L360.9 308.6L342.4 297.9Z"/>
                <path d="M379.3 276.6L360.9 287.3L379.3 297.9L397.8 287.3L379.3 276.6Z"/>
                <path d="M416.2 255.3L397.7 266.0L416.2 276.7L434.6 266.0L416.2 255.3Z"/>
                <path d="M471.5 223.4L453.1 234.0L471.5 244.7L490.0 234.1L471.5 223.4Z"/>
                <path d="M490.0 212.8L471.5 223.4L490.0 234.1L508.4 223.4L490.0 212.8Z"/>
                <path d="M508.4 202.1L490.0 212.8L508.4 223.4L526.9 212.8L508.4 202.1Z"/>
                <path d="M526.8 191.5L508.4 202.1L526.9 212.8L545.3 202.1L526.8 191.5Z"/>
                <path d="M563.7 170.2L545.3 180.8L563.8 191.5L582.2 180.8L563.7 170.2Z"/>
                <path d="M582.2 159.5L563.7 170.2L582.2 180.8L600.6 170.2L582.2 159.5Z"/>
                <path d="M600.6 148.9L582.2 159.5L600.6 170.2L619.1 159.5L600.6 148.9Z"/>
                <path d="M176.4 415.1L158.0 425.7L176.5 436.4L194.9 425.7L176.4 415.1Z"/>
                <path d="M213.3 393.8L194.9 404.4L213.3 415.1L231.8 404.4L213.3 393.8Z"/>
                <path d="M231.8 383.1L213.3 393.8L231.8 404.4L250.2 393.8L231.8 383.1Z"/>
                <path d="M250.2 372.5L231.8 383.1L250.2 393.8L268.7 383.1L250.2 372.5Z"/>
                <path d="M342.4 319.2L324.0 329.9L342.5 340.5L360.9 329.9L342.4 319.2Z"/>
                <path d="M471.5 244.7L453.1 255.4L471.6 266.0L490.0 255.4L471.5 244.7Z"/>
                <path d="M490.0 234.1L471.5 244.7L490.0 255.4L508.4 244.7L490.0 234.1Z"/>
                <path d="M526.9 212.8L508.4 223.4L526.9 234.1L545.3 223.4L526.9 212.8Z"/>
                <path d="M563.8 191.5L545.3 202.1L563.8 212.8L582.2 202.1L563.8 191.5Z"/>
                <path d="M600.6 170.2L582.2 180.8L600.7 191.5L619.1 180.8L600.6 170.2Z"/>
                <path d="M194.9 425.7L176.5 436.4L194.9 447.0L213.4 436.4L194.9 425.7Z"/>
                <path d="M250.2 393.8L231.8 404.4L250.3 415.1L268.7 404.4L250.2 393.8Z"/>
                <path d="M268.7 383.1L250.2 393.8L268.7 404.4L287.1 393.8L268.7 383.1Z"/>
                <path d="M287.1 372.5L268.7 383.1L287.1 393.8L305.6 383.1L287.1 372.5Z"/>
                <path d="M305.6 361.8L287.1 372.5L305.6 383.1L324.0 372.5L305.6 361.8Z"/>
                <path d="M397.8 308.6L379.3 319.3L397.8 329.9L416.2 319.3L397.8 308.6Z"/>
                <path d="M416.2 298.0L397.8 308.6L416.2 319.3L434.7 308.6L416.2 298.0Z"/>
                <path d="M471.6 266.0L453.1 276.7L471.6 287.3L490.0 276.7L471.6 266.0Z"/>
                <path d="M526.9 234.1L508.4 244.7L526.9 255.4L545.4 244.7L526.9 234.1Z"/>
                <path d="M545.3 223.4L526.9 234.1L545.4 244.7L563.8 234.1L545.3 223.4Z"/>
                <path d="M563.8 212.8L545.3 223.4L563.8 234.1L582.2 223.4L563.8 212.8Z"/>
                <path d="M582.2 202.1L563.8 212.8L582.2 223.4L600.7 212.8L582.2 202.1Z"/>
                <path d="M619.1 180.8L600.7 191.5L619.1 202.1L637.6 191.5L619.1 180.8Z"/>
                <path d="M637.5 170.2L619.1 180.8L637.6 191.5L656.0 180.8L637.5 170.2Z"/>
                <path d="M213.4 436.4L194.9 447.0L213.4 457.7L231.8 447.0L213.4 436.4Z"/>
                <path d="M268.7 404.4L250.3 415.1L268.7 425.8L287.2 415.1L268.7 404.4Z"/>
                <path d="M287.1 393.8L268.7 404.4L287.2 415.1L305.6 404.5L287.1 393.8Z"/>
                <path d="M342.5 361.9L324.0 372.5L342.5 383.2L360.9 372.5L342.5 361.9Z"/>
                <path d="M360.9 351.2L342.5 361.9L360.9 372.5L379.4 361.9L360.9 351.2Z"/>
                <path d="M416.2 319.3L397.8 329.9L416.3 340.6L434.7 329.9L416.2 319.3Z"/>
                <path d="M471.6 287.3L453.1 298.0L471.6 308.6L490.0 298.0L471.6 287.3Z"/>
                <path d="M526.9 255.4L508.5 266.0L526.9 276.7L545.4 266.0L526.9 255.4Z"/>
                <path d="M563.8 234.1L545.4 244.7L563.8 255.4L582.3 244.7L563.8 234.1Z"/>
                <path d="M582.2 223.4L563.8 234.1L582.3 244.7L600.7 234.1L582.2 223.4Z"/>
                <path d="M656.0 180.8L637.6 191.5L656.0 202.2L674.5 191.5L656.0 180.8Z"/>
                <path d="M231.8 447.0L213.4 457.7L231.9 468.4L250.3 457.7L231.8 447.0Z"/>
                <path d="M287.2 415.1L268.7 425.8L287.2 436.4L305.6 425.8L287.2 415.1Z"/>
                <path d="M342.5 383.2L324.0 393.8L342.5 404.5L361.0 393.8L342.5 383.2Z"/>
                <path d="M416.3 340.6L397.8 351.2L416.3 361.9L434.7 351.2L416.3 340.6Z"/>
                <path d="M434.7 329.9L416.3 340.6L434.7 351.2L453.2 340.6L434.7 329.9Z"/>
                <path d="M508.5 287.3L490.0 298.0L508.5 308.6L527 298.0L508.5 287.3Z"/>
                <path d="M526.9 276.7L508.5 287.3L527 298.0L545.4 287.3L526.9 276.7Z"/>
                <path d="M545.4 266.0L526.9 276.7L545.4 287.3L563.8 276.7L545.4 266.0Z"/>
                <path d="M563.8 255.4L545.4 266.0L563.8 276.7L582.3 266.1L563.8 255.4Z"/>
                <path d="M582.3 244.7L563.8 255.4L582.3 266.1L600.7 255.4L582.3 244.7Z"/>
                <path d="M600.7 234.1L582.3 244.7L600.7 255.4L619.2 244.8L600.7 234.1Z"/>
                <path d="M637.6 212.8L619.1 223.4L637.6 234.1L656.1 223.5L637.6 212.8Z"/>
                <path d="M397.8 372.5L379.4 383.2L397.9 393.8L416.3 383.2L397.8 372.5Z"/>
                <path d="M416.3 361.9L397.8 372.5L416.3 383.2L434.8 372.5L416.3 361.9Z"/>
                <path d="M471.6 329.9L453.2 340.6L471.6 351.2L490.1 340.6L471.6 329.9Z"/>
                <path d="M490.1 319.3L471.6 329.9L490.1 340.6L508.5 329.9L490.1 319.3Z"/>
                <path d="M508.5 308.6L490.1 319.3L508.5 329.9L527.0 319.3L508.5 308.6Z"/>
                <path d="M545.4 287.3L527 298.0L545.4 308.7L563.9 298.0L545.4 287.3Z"/>
                <path d="M619.2 244.8L600.7 255.4L619.2 266.1L637.6 255.4L619.2 244.8Z"/>
                <path d="M637.6 234.1L619.2 244.8L637.6 255.4L656.1 244.8L637.6 234.1Z"/>
                <path d="M434.8 372.5L416.3 383.2L434.8 393.8L453.2 383.2L434.8 372.5Z"/>
                <path d="M508.5 329.9L490.1 340.6L508.5 351.3L527.0 340.6L508.5 329.9Z"/>
                <path d="M527.0 319.3L508.5 329.9L527.0 340.6L545.4 330.0L527.0 319.3Z"/>
                <path d="M563.9 298.0L545.4 308.7L563.9 319.3L582.3 308.7L563.9 298.0Z"/>
                <path d="M600.7 276.7L582.3 287.4L600.8 298.0L619.2 287.4L600.7 276.7Z"/>
                <path d="M637.6 255.4L619.2 266.1L637.7 276.7L656.1 266.1L637.6 255.4Z"/>
                <path d="M674.5 234.1L656.1 244.8L674.5 255.4L693.0 244.8L674.5 234.1Z"/>
                <path d="M693.0 223.5L674.5 234.1L693.0 244.8L711.4 234.1L693.0 223.5Z"/>
                <path d="M711.4 212.8L693.0 223.5L711.4 234.1L729.9 223.5L711.4 212.8Z"/>
                <path d="M434.8 393.8L416.3 404.5L434.8 415.2L453.2 404.5L434.8 393.8Z"/>
                <path d="M453.2 383.2L434.8 393.8L453.2 404.5L471.7 393.9L453.2 383.2Z"/>
                <path d="M471.7 372.6L453.2 383.2L471.7 393.9L490.1 383.2L471.7 372.6Z"/>
                <path d="M508.5 351.3L490.1 361.9L508.6 372.6L527.0 361.9L508.5 351.3Z"/>
                <path d="M527.0 340.6L508.5 351.3L527.0 361.9L545.5 351.3L527.0 340.6Z"/>
                <path d="M582.3 308.7L563.9 319.3L582.3 330.0L600.8 319.3L582.3 308.7Z"/>
                <path d="M656.1 266.1L637.7 276.7L656.1 287.4L674.6 276.7L656.1 266.1Z"/>
                <path d="M674.5 255.4L656.1 266.1L674.6 276.7L693.0 266.1L674.5 255.4Z"/>
                <path d="M711.4 234.1L693.0 244.8L711.4 255.4L729.9 244.8L711.4 234.1Z"/>
                <path d="M729.9 223.5L711.4 234.1L729.9 244.8L748.3 234.1L729.9 223.5Z"/>
                <path d="M453.2 404.5L434.8 415.2L453.3 425.8L471.7 415.2L453.2 404.5Z"/>
                <path d="M471.7 393.9L453.2 404.5L471.7 415.2L490.1 404.5L471.7 393.9Z"/>
                <path d="M527.0 361.9L508.6 372.6L527.0 383.2L545.5 372.6L527.0 361.9Z"/>
                <path d="M563.9 340.6L545.5 351.3L563.9 361.9L582.4 351.3L563.9 340.6Z"/>
                <path d="M582.3 330.0L563.9 340.6L582.4 351.3L600.8 340.6L582.3 330.0Z"/>
                <path d="M600.8 319.3L582.3 330.0L600.8 340.6L619.3 330.0L600.8 319.3Z"/>
                <path d="M619.2 308.7L600.8 319.3L619.3 330.0L637.7 319.3L619.2 308.7Z"/>
                <path d="M637.7 298.0L619.2 308.7L637.7 319.3L656.1 308.7L637.7 298.0Z"/>
                <path d="M656.1 287.4L637.7 298.0L656.1 308.7L674.6 298.0L656.1 287.4Z"/>
                <path d="M674.6 276.7L656.1 287.4L674.6 298.0L693.0 287.4L674.6 276.7Z"/>
                <path d="M711.4 255.4L693.0 266.1L711.5 276.7L729.9 266.1L711.4 255.4Z"/>
                <path d="M748.3 234.1L729.9 244.8L748.4 255.5L766.8 244.8L748.3 234.1Z"/>
                <path d="M471.7 415.2L453.3 425.8L471.7 436.5L490.2 425.8L471.7 415.2Z"/>
                <path d="M490.1 404.5L471.7 415.2L490.2 425.8L508.6 415.2L490.1 404.5Z"/>
                <path d="M508.6 393.9L490.1 404.5L508.6 415.2L527.1 404.5L508.6 393.9Z"/>
                <path d="M600.8 340.6L582.4 351.3L600.8 361.9L619.3 351.3L600.8 340.6Z"/>
                <path d="M656.1 308.7L637.7 319.3L656.2 330.0L674.6 319.4L656.1 308.7Z"/>
                <path d="M693.0 287.4L674.6 298.0L693.0 308.7L711.5 298.1L693.0 287.4Z"/>
                <path d="M711.5 276.7L693.0 287.4L711.5 298.1L729.9 287.4L711.5 276.7Z"/>
                <path d="M729.9 266.1L711.5 276.7L729.9 287.4L748.4 276.8L729.9 266.1Z"/>
                <path d="M748.4 255.5L729.9 266.1L748.4 276.8L766.8 266.1L748.4 255.5Z"/>
                <path d="M766.8 244.8L748.4 255.5L766.8 266.1L785.3 255.5L766.8 244.8Z"/>
                <path d="M490.2 425.8L471.7 436.5L490.2 447.1L508.6 436.5L490.2 425.8Z"/>
                <path d="M508.6 415.2L490.2 425.8L508.6 436.5L527.1 425.8L508.6 415.2Z"/>
                <path d="M545.5 393.9L527.1 404.5L545.5 415.2L564.0 404.5L545.5 393.9Z"/>
                <path d="M563.9 383.2L545.5 393.9L564.0 404.5L582.4 393.9L563.9 383.2Z"/>
                <path d="M619.3 351.3L600.8 361.9L619.3 372.6L637.7 362.0L619.3 351.3Z"/>
                <path d="M729.9 287.4L711.5 298.1L730.0 308.7L748.4 298.1L729.9 287.4Z"/>
                <path d="M748.4 276.8L729.9 287.4L748.4 298.1L766.8 287.4L748.4 276.8Z"/>
                <path d="M785.3 255.5L766.8 266.1L785.3 276.8L803.7 266.1L785.3 255.5Z"/>
                <path d="M527.1 425.8L508.6 436.5L527.1 447.1L545.5 436.5L527.1 425.8Z"/>
                <path d="M564.0 404.5L545.5 415.2L564.0 425.8L582.4 415.2L564.0 404.5Z"/>
                <path d="M619.3 372.6L600.9 383.2L619.3 393.9L637.8 383.3L619.3 372.6Z"/>
                <path d="M656.2 351.3L637.7 362.0L656.2 372.6L674.6 362.0L656.2 351.3Z"/>
                <path d="M674.6 340.7L656.2 351.3L674.6 362.0L693.1 351.3L674.6 340.7Z"/>
                <path d="M711.5 319.4L693.1 330.0L711.5 340.7L730.0 330.0L711.5 319.4Z"/>
                <path d="M730.0 308.7L711.5 319.4L730.0 330.0L748.4 319.4L730.0 308.7Z"/>
                <path d="M748.4 298.1L730.0 308.7L748.4 319.4L766.9 308.7L748.4 298.1Z"/>
                <path d="M803.7 266.1L785.3 276.8L803.8 287.4L822.2 276.8L803.7 266.1Z"/>
                <path d="M527.1 447.1L508.7 457.8L527.1 468.5L545.6 457.8L527.1 447.1Z"/>
                <path d="M545.5 436.5L527.1 447.1L545.6 457.8L564.0 447.2L545.5 436.5Z"/>
                <path d="M582.4 415.2L564.0 425.8L582.5 436.5L600.9 425.9L582.4 415.2Z"/>
                <path d="M730.0 330.0L711.5 340.7L730.0 351.3L748.4 340.7L730.0 330.0Z"/>
                <path d="M748.4 319.4L730.0 330.0L748.4 340.7L766.9 330.0L748.4 319.4Z"/>
                <path d="M766.9 308.7L748.4 319.4L766.9 330.0L785.3 319.4L766.9 308.7Z"/>
                <path d="M785.3 298.1L766.9 308.7L785.3 319.4L803.8 308.7L785.3 298.1Z"/>
                <path d="M803.8 287.4L785.3 298.1L803.8 308.7L822.2 298.1L803.8 287.4Z"/>
                <path d="M822.2 276.8L803.8 287.4L822.2 298.1L840.7 287.4L822.2 276.8Z"/>
                <path d="M-82 287.1L47.2 361.8L176.3 287.2L47.1 212.6L-82 287.1ZM137.6 287.2L47.2 339.4L-43.2 287.2L47.1 235.0L137.6 287.2Z"/>
                <path d="M249.9 95.5L379.2 170.1L508.3 95.6L379.0 21.0L249.9 95.5ZM469.5 95.6L379.2 147.7L288.7 95.5L379.1 43.3L469.5 95.6Z"/>
                <path d="M250.3 479.0L379.6 553.6L508.7 479.1L379.4 404.5L250.3 479.0ZM469.9 479.1L379.5 531.3L289.1 479.0L379.4 426.9L469.9 479.1Z"/>
                <path d="M47.1 255.2L-8.1 287.2L47.2 319.1L102.5 287.2L47.1 255.2Z"/>
                <path d="M379.1 63.6L323.8 95.5L379.1 127.5L434.5 95.5L379.1 63.6Z"/>
                <path d="M379.5 447.1L324.1 479.0L379.5 511.0L434.9 479.1L379.5 447.1Z"/>
            </g>
            <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="138" y="68" width="529" height="325" fill="#00A8FF">
                <path d="M293.6 307.1L312.4 296.2L345.4 315.3L326.6 326.1L365.8 348.7L323.1 373.4L283.9 350.8L212.4 392.0L183.8 378.4L138.7 267.0L138.3 266.8L181.1 242.1L293.6 307.1ZM217.6 350.9L250.9 331.7L194.0 298.9L195.7 303.0L217.6 350.9Z"/>
                <path d="M456.1 209.4C481.1 223.8 494.7 238.2 497.0 252.5C499.4 266.7 490.3 279.8 470.0 291.5C449.4 303.4 426.7 308.6 401.8 307.3C377.0 305.9 352.2 298.1 327.4 283.8L293.6 264.3C268.7 249.9 255.0 235.5 252.7 221.3C250.4 207.0 259.5 193.9 279.9 182.1C300.4 170.3 323.1 165.1 347.9 166.4C372.8 167.8 397.7 175.7 422.5 190.0L456.1 209.4ZM374.2 211.5C360.9 203.8 349.3 199.1 339.3 197.3C329.2 195.5 320.4 196.8 312.9 201.2C305.6 205.4 303.2 210.3 305.9 215.7C308.7 221.1 316.2 227.5 328.3 234.8L375.5 262.0C389.2 270.0 401.1 274.8 411.0 276.5C420.8 278.2 429.5 276.8 437.0 272.5C444.3 268.3 446.5 263.4 443.8 257.8C441.0 252.2 433.1 245.6 419.9 237.8L374.2 211.5Z"/>
                <path d="M594.5 133.3L613.3 122.5L646.3 141.5L627.5 152.4L666.7 175.0L624.0 199.7L584.8 177.1L513.4 218.3L484.7 204.7L439.6 93.3L439.3 93.1L482.0 68.4L594.5 133.3ZM518.6 177.2L551.8 158.0L495.0 125.2L496.6 129.2L518.6 177.2Z"/>
            </mask>
            <g mask="url(#mask1)" fill="#252525">
                <path d="M102.4 160.7L83.9 171.3L102.4 182.0L120.9 171.3L102.4 160.7Z"/>
                <path d="M120.8 150.0L102.4 160.7L120.9 171.3L139.3 160.7L120.8 150.0Z"/>
                <path d="M139.3 139.4L120.8 150.0L139.3 160.7L157.7 150.0L139.3 139.4Z"/>
                <path d="M176.2 118.1L157.7 128.7L176.2 139.4L194.6 128.7L176.2 118.1Z"/>
                <path d="M213.0 96.8L194.6 107.4L213.1 118.1L231.5 107.4L213.0 96.8Z"/>
                <path d="M139.3 160.7L120.9 171.3L139.3 182.0L157.8 171.3L139.3 160.7Z"/>
                <path d="M157.7 150.0L139.3 160.7L157.8 171.3L176.2 160.7L157.7 150.0Z"/>
                <path d="M176.2 139.4L157.7 150.0L176.2 160.7L194.6 150.0L176.2 139.4Z"/>
                <path d="M194.6 128.7L176.2 139.4L194.6 150.0L213.1 139.4L194.6 128.7Z"/>
                <path d="M213.1 118.1L194.6 128.7L213.1 139.4L231.5 128.7L213.1 118.1Z"/>
                <path d="M231.5 107.4L213.1 118.1L231.5 128.7L250.0 118.1L231.5 107.4Z"/>
                <path d="M250.0 96.8L231.5 107.4L250.0 118.1L268.4 107.5L250.0 96.8Z"/>
                <path d="M120.9 192.6L102.4 203.3L120.9 213.9L139.3 203.3L120.9 192.6Z"/>
                <path d="M139.3 182.0L120.9 192.6L139.3 203.3L157.8 192.6L139.3 182.0Z"/>
                <path d="M157.8 171.3L139.3 182.0L157.8 192.6L176.2 182.0L157.8 171.3Z"/>
                <path d="M213.1 139.4L194.6 150.0L213.1 160.7L231.6 150.1L213.1 139.4Z"/>
                <path d="M268.4 107.5L250.0 118.1L268.4 128.8L286.9 118.1L268.4 107.5Z"/>
                <path d="M139.3 203.3L120.9 213.9L139.4 224.6L157.8 214L139.3 203.3Z"/>
                <path d="M157.8 192.6L139.3 203.3L157.8 214L176.2 203.3L157.8 192.6Z"/>
                <path d="M213.1 160.7L194.7 171.3L213.1 182.0L231.6 171.4L213.1 160.7Z"/>
                <path d="M250.0 139.4L231.6 150.1L250.0 160.7L268.5 150.1L250.0 139.4Z"/>
                <path d="M157.8 214L139.4 224.6L157.8 235.3L176.3 224.6L157.8 214Z"/>
                <path d="M194.7 192.7L176.2 203.3L194.7 214.0L213.2 203.3L194.7 192.7Z"/>
                <path d="M305.4 128.8L286.9 139.4L305.4 150.1L323.8 139.4L305.4 128.8Z"/>
                <path d="M176.3 224.6L157.8 235.3L176.3 245.9L194.7 235.3L176.3 224.6Z"/>
                <path d="M194.7 214.0L176.3 224.6L194.7 235.3L213.2 224.6L194.7 214.0Z"/>
                <path d="M250.0 182.0L231.6 192.7L250.1 203.3L268.5 192.7L250.0 182.0Z"/>
                <path d="M305.4 150.1L286.9 160.7L305.4 171.4L323.8 160.7L305.4 150.1Z"/>
                <path d="M323.8 139.4L305.4 150.1L323.8 160.7L342.3 150.1L323.8 139.4Z"/>
                <path d="M194.7 235.3L176.3 245.9L194.8 256.6L213.2 245.9L194.7 235.3Z"/>
                <path d="M231.6 214.0L213.2 224.6L231.6 235.3L250.1 224.6L231.6 214.0Z"/>
                <path d="M268.5 192.7L250.1 203.3L268.5 214.0L287.0 203.3L268.5 192.7Z"/>
                <path d="M305.4 171.4L286.9 182.0L305.4 192.7L323.9 182.0L305.4 171.4Z"/>
                <path d="M342.3 150.1L323.8 160.7L342.3 171.4L360.7 160.7L342.3 150.1Z"/>
                <path d="M213.2 245.9L194.8 256.6L213.2 267.2L231.7 256.6L213.2 245.9Z"/>
                <path d="M287.0 203.3L268.5 214.0L287.0 224.6L305.4 214.0L287.0 203.3Z"/>
                <path d="M323.9 182.0L305.4 192.7L323.9 203.4L342.3 192.7L323.9 182.0Z"/>
                <path d="M342.3 171.4L323.9 182.0L342.3 192.7L360.8 182.1L342.3 171.4Z"/>
                <path d="M360.7 160.7L342.3 171.4L360.8 182.1L379.2 171.4L360.7 160.7Z"/>
                <path d="M84.1 341.8L65.7 352.4L84.1 363.1L102.6 352.4L84.1 341.8Z"/>
                <path d="M121.0 320.5L102.6 331.1L121.0 341.8L139.5 331.1L121.0 320.5Z"/>
                <path d="M139.4 309.8L121.0 320.5L139.5 331.1L157.9 320.5L139.4 309.8Z"/>
                <path d="M157.9 299.2L139.4 309.8L157.9 320.5L176.4 309.8L157.9 299.2Z"/>
                <path d="M176.3 288.5L157.9 299.2L176.4 309.8L194.8 299.2L176.3 288.5Z"/>
                <path d="M194.8 277.9L176.3 288.5L194.8 299.2L213.2 288.5L194.8 277.9Z"/>
                <path d="M250.1 245.9L231.7 256.6L250.1 267.2L268.6 256.6L250.1 245.9Z"/>
                <path d="M268.5 235.3L250.1 245.9L268.6 256.6L287.0 246.0L268.5 235.3Z"/>
                <path d="M287.0 224.6L268.5 235.3L287.0 246.0L305.5 235.3L287.0 224.6Z"/>
                <path d="M305.4 214.0L287.0 224.6L305.5 235.3L323.9 224.7L305.4 214.0Z"/>
                <path d="M416.1 150.1L397.7 160.8L416.1 171.4L434.6 160.8L416.1 150.1Z"/>
                <path d="M434.5 139.5L416.1 150.1L434.6 160.8L453.0 150.1L434.5 139.5Z"/>
                <path d="M453.0 128.8L434.5 139.5L453.0 150.1L471.4 139.5L453.0 128.8Z"/>
                <path d="M471.4 118.2L453.0 128.8L471.4 139.5L489.9 128.8L471.4 118.2Z"/>
                <path d="M489.9 107.5L471.4 118.2L489.9 128.8L508.3 118.2L489.9 107.5Z"/>
                <path d="M102.6 352.4L84.1 363.1L102.6 373.7L121.0 363.1L102.6 352.4Z"/>
                <path d="M121.0 341.8L102.6 352.4L121.0 363.1L139.5 352.4L121.0 341.8Z"/>
                <path d="M139.5 331.1L121.0 341.8L139.5 352.4L157.9 341.8L139.5 331.1Z"/>
                <path d="M157.9 320.5L139.5 331.1L157.9 341.8L176.4 331.1L157.9 320.5Z"/>
                <path d="M268.6 256.6L250.1 267.2L268.6 277.9L287.0 267.3L268.6 256.6Z"/>
                <path d="M323.9 224.7L305.5 235.3L323.9 246.0L342.4 235.3L323.9 224.7Z"/>
                <path d="M342.3 214.0L323.9 224.7L342.4 235.3L360.8 224.7L342.3 214.0Z"/>
                <path d="M360.8 203.4L342.3 214.0L360.8 224.7L379.3 214.0L360.8 203.4Z"/>
                <path d="M397.7 182.1L379.2 192.7L397.7 203.4L416.1 192.7L397.7 182.1Z"/>
                <path d="M416.1 171.4L397.7 182.1L416.1 192.7L434.6 182.1L416.1 171.4Z"/>
                <path d="M453.0 150.1L434.6 160.8L453.0 171.4L471.5 160.8L453.0 150.1Z"/>
                <path d="M526.8 107.5L508.3 118.2L526.8 128.8L545.2 118.2L526.8 107.5Z"/>
                <path d="M139.5 352.4L121.0 363.1L139.5 373.7L158 363.1L139.5 352.4Z"/>
                <path d="M176.4 331.1L157.9 341.8L176.4 352.5L194.8 341.8L176.4 331.1Z"/>
                <path d="M213.3 309.9L194.8 320.5L213.3 331.2L231.7 320.5L213.3 309.9Z"/>
                <path d="M231.7 299.2L213.3 309.9L231.7 320.5L250.2 309.9L231.7 299.2Z"/>
                <path d="M268.6 277.9L250.1 288.6L268.6 299.2L287.1 288.6L268.6 277.9Z"/>
                <path d="M287.0 267.3L268.6 277.9L287.1 288.6L305.5 277.9L287.0 267.3Z"/>
                <path d="M323.9 246.0L305.5 256.6L323.9 267.3L342.4 256.6L323.9 246.0Z"/>
                <path d="M342.4 235.3L323.9 246.0L342.4 256.6L360.8 246.0L342.4 235.3Z"/>
                <path d="M360.8 224.7L342.4 235.3L360.8 246.0L379.3 235.3L360.8 224.7Z"/>
                <path d="M379.3 214.0L360.8 224.7L379.3 235.3L397.7 224.7L379.3 214.0Z"/>
                <path d="M397.7 203.4L379.3 214.0L397.7 224.7L416.2 214.0L397.7 203.4Z"/>
                <path d="M416.1 192.7L397.7 203.4L416.2 214.0L434.6 203.4L416.1 192.7Z"/>
                <path d="M434.6 182.1L416.1 192.7L434.6 203.4L453.0 192.7L434.6 182.1Z"/>
                <path d="M453.0 171.4L434.6 182.1L453.0 192.7L471.5 182.1L453.0 171.4Z"/>
                <path d="M508.4 139.5L489.9 150.1L508.4 160.8L526.8 150.2L508.4 139.5Z"/>
                <path d="M545.2 118.2L526.8 128.8L545.3 139.5L563.7 128.9L545.2 118.2Z"/>
                <path d="M563.7 107.5L545.2 118.2L563.7 128.9L582.2 118.2L563.7 107.5Z"/>
                <path d="M176.4 352.5L158 363.1L176.4 373.8L194.9 363.1L176.4 352.5Z"/>
                <path d="M268.6 299.2L250.2 309.9L268.6 320.5L287.1 309.9L268.6 299.2Z"/>
                <path d="M323.9 267.3L305.5 277.9L324.0 288.6L342.4 277.9L323.9 267.3Z"/>
                <path d="M379.3 235.3L360.8 246.0L379.3 256.6L397.7 246.0L379.3 235.3Z"/>
                <path d="M434.6 203.4L416.2 214.0L434.6 224.7L453.1 214.0L434.6 203.4Z"/>
                <path d="M489.9 171.4L471.5 182.1L490.0 192.8L508.4 182.1L489.9 171.4Z"/>
                <path d="M582.2 118.2L563.7 128.9L582.2 139.5L600.6 128.9L582.2 118.2Z"/>
                <path d="M158.0 384.4L139.5 395.1L158.0 405.7L176.4 395.1L158.0 384.4Z"/>
                <path d="M176.4 373.8L158.0 384.4L176.4 395.1L194.9 384.4L176.4 373.8Z"/>
                <path d="M194.9 363.1L176.4 373.8L194.9 384.4L213.3 373.8L194.9 363.1Z"/>
                <path d="M213.3 352.5L194.9 363.1L213.3 373.8L231.8 363.1L213.3 352.5Z"/>
                <path d="M268.6 320.5L250.2 331.2L268.7 341.8L287.1 331.2L268.6 320.5Z"/>
                <path d="M287.1 309.9L268.6 320.5L287.1 331.2L305.5 320.5L287.1 309.9Z"/>
                <path d="M305.5 299.2L287.1 309.9L305.5 320.5L324.0 309.9L305.5 299.2Z"/>
                <path d="M342.4 277.9L324.0 288.6L342.4 299.2L360.9 288.6L342.4 277.9Z"/>
                <path d="M379.3 256.6L360.9 267.3L379.3 277.9L397.8 267.3L379.3 256.6Z"/>
                <path d="M416.2 235.3L397.7 246.0L416.2 256.7L434.6 246.0L416.2 235.3Z"/>
                <path d="M471.5 203.4L453.1 214.0L471.5 224.7L490.0 214.1L471.5 203.4Z"/>
                <path d="M490.0 192.8L471.5 203.4L490.0 214.1L508.4 203.4L490.0 192.8Z"/>
                <path d="M508.4 182.1L490.0 192.8L508.4 203.4L526.9 192.8L508.4 182.1Z"/>
                <path d="M526.8 171.5L508.4 182.1L526.9 192.8L545.3 182.1L526.8 171.5Z"/>
                <path d="M563.7 150.2L545.3 160.8L563.8 171.5L582.2 160.8L563.7 150.2Z"/>
                <path d="M582.2 139.5L563.7 150.2L582.2 160.8L600.6 150.2L582.2 139.5Z"/>
                <path d="M600.6 128.9L582.2 139.5L600.6 150.2L619.1 139.5L600.6 128.9Z"/>
                <path d="M176.4 395.1L158.0 405.7L176.5 416.4L194.9 405.7L176.4 395.1Z"/>
                <path d="M213.3 373.8L194.9 384.4L213.3 395.1L231.8 384.4L213.3 373.8Z"/>
                <path d="M231.8 363.1L213.3 373.8L231.8 384.4L250.2 373.8L231.8 363.1Z"/>
                <path d="M250.2 352.5L231.8 363.1L250.2 373.8L268.7 363.1L250.2 352.5Z"/>
                <path d="M342.4 299.2L324.0 309.9L342.5 320.5L360.9 309.9L342.4 299.2Z"/>
                <path d="M471.5 224.7L453.1 235.4L471.6 246.0L490.0 235.4L471.5 224.7Z"/>
                <path d="M490.0 214.1L471.5 224.7L490.0 235.4L508.4 224.7L490.0 214.1Z"/>
                <path d="M526.9 192.8L508.4 203.4L526.9 214.1L545.3 203.4L526.9 192.8Z"/>
                <path d="M563.8 171.5L545.3 182.1L563.8 192.8L582.2 182.1L563.8 171.5Z"/>
                <path d="M600.6 150.2L582.2 160.8L600.7 171.5L619.1 160.8L600.6 150.2Z"/>
                <path d="M194.9 405.7L176.5 416.4L194.9 427.0L213.4 416.4L194.9 405.7Z"/>
                <path d="M250.2 373.8L231.8 384.4L250.3 395.1L268.7 384.4L250.2 373.8Z"/>
                <path d="M268.7 363.1L250.2 373.8L268.7 384.4L287.1 373.8L268.7 363.1Z"/>
                <path d="M287.1 352.5L268.7 363.1L287.1 373.8L305.6 363.1L287.1 352.5Z"/>
                <path d="M305.6 341.8L287.1 352.5L305.6 363.1L324.0 352.5L305.6 341.8Z"/>
                <path d="M397.8 288.6L379.3 299.3L397.8 309.9L416.2 299.3L397.8 288.6Z"/>
                <path d="M416.2 278.0L397.8 288.6L416.2 299.3L434.7 288.6L416.2 278.0Z"/>
                <path d="M471.6 246.0L453.1 256.7L471.6 267.3L490.0 256.7L471.6 246.0Z"/>
                <path d="M526.9 214.1L508.4 224.7L526.9 235.4L545.4 224.7L526.9 214.1Z"/>
                <path d="M545.3 203.4L526.9 214.1L545.4 224.7L563.8 214.1L545.3 203.4Z"/>
                <path d="M563.8 192.8L545.3 203.4L563.8 214.1L582.2 203.4L563.8 192.8Z"/>
                <path d="M582.2 182.1L563.8 192.8L582.2 203.4L600.7 192.8L582.2 182.1Z"/>
                <path d="M619.1 160.8L600.7 171.5L619.1 182.1L637.6 171.5L619.1 160.8Z"/>
                <path d="M637.5 150.2L619.1 160.8L637.6 171.5L656.0 160.8L637.5 150.2Z"/>
                <path d="M213.4 416.4L194.9 427.0L213.4 437.7L231.8 427.0L213.4 416.4Z"/>
                <path d="M268.7 384.4L250.3 395.1L268.7 405.8L287.2 395.1L268.7 384.4Z"/>
                <path d="M287.1 373.8L268.7 384.4L287.2 395.1L305.6 384.5L287.1 373.8Z"/>
                <path d="M342.5 341.9L324.0 352.5L342.5 363.2L360.9 352.5L342.5 341.9Z"/>
                <path d="M360.9 331.2L342.5 341.9L360.9 352.5L379.4 341.9L360.9 331.2Z"/>
                <path d="M416.2 299.3L397.8 309.9L416.3 320.6L434.7 309.9L416.2 299.3Z"/>
                <path d="M471.6 267.3L453.1 278.0L471.6 288.6L490.0 278.0L471.6 267.3Z"/>
                <path d="M526.9 235.4L508.5 246.0L526.9 256.7L545.4 246.0L526.9 235.4Z"/>
                <path d="M563.8 214.1L545.4 224.7L563.8 235.4L582.3 224.7L563.8 214.1Z"/>
                <path d="M582.2 203.4L563.8 214.1L582.3 224.7L600.7 214.1L582.2 203.4Z"/>
                <path d="M656.0 160.8L637.6 171.5L656.0 182.2L674.5 171.5L656.0 160.8Z"/>
                <path d="M231.8 427.0L213.4 437.7L231.9 448.4L250.3 437.7L231.8 427.0Z"/>
                <path d="M287.2 395.1L268.7 405.8L287.2 416.4L305.6 405.8L287.2 395.1Z"/>
                <path d="M342.5 363.2L324.0 373.8L342.5 384.5L361.0 373.8L342.5 363.2Z"/>
                <path d="M416.3 320.6L397.8 331.2L416.3 341.9L434.7 331.2L416.3 320.6Z"/>
                <path d="M434.7 309.9L416.3 320.6L434.7 331.2L453.2 320.6L434.7 309.9Z"/>
                <path d="M508.5 267.3L490.0 278.0L508.5 288.6L527 278.0L508.5 267.3Z"/>
                <path d="M526.9 256.7L508.5 267.3L527 278.0L545.4 267.3L526.9 256.7Z"/>
                <path d="M545.4 246.0L526.9 256.7L545.4 267.3L563.8 256.7L545.4 246.0Z"/>
                <path d="M563.8 235.4L545.4 246.0L563.8 256.7L582.3 246.1L563.8 235.4Z"/>
                <path d="M582.3 224.7L563.8 235.4L582.3 246.1L600.7 235.4L582.3 224.7Z"/>
                <path d="M600.7 214.1L582.3 224.7L600.7 235.4L619.2 224.8L600.7 214.1Z"/>
                <path d="M637.6 192.8L619.1 203.4L637.6 214.1L656.1 203.5L637.6 192.8Z"/>
                <path d="M397.8 352.5L379.4 363.2L397.9 373.8L416.3 363.2L397.8 352.5Z"/>
                <path d="M416.3 341.9L397.8 352.5L416.3 363.2L434.8 352.5L416.3 341.9Z"/>
                <path d="M471.6 309.9L453.2 320.6L471.6 331.2L490.1 320.6L471.6 309.9Z"/>
                <path d="M490.1 299.3L471.6 309.9L490.1 320.6L508.5 309.9L490.1 299.3Z"/>
                <path d="M508.5 288.6L490.1 299.3L508.5 309.9L527.0 299.3L508.5 288.6Z"/>
                <path d="M545.4 267.3L527 278.0L545.4 288.7L563.9 278.0L545.4 267.3Z"/>
                <path d="M619.2 224.8L600.7 235.4L619.2 246.1L637.6 235.4L619.2 224.8Z"/>
                <path d="M637.6 214.1L619.2 224.8L637.6 235.4L656.1 224.8L637.6 214.1Z"/>
                <path d="M434.8 352.5L416.3 363.2L434.8 373.8L453.2 363.2L434.8 352.5Z"/>
                <path d="M508.5 309.9L490.1 320.6L508.5 331.3L527.0 320.6L508.5 309.9Z"/>
                <path d="M527.0 299.3L508.5 309.9L527.0 320.6L545.4 310.0L527.0 299.3Z"/>
                <path d="M563.9 278.0L545.4 288.7L563.9 299.3L582.3 288.7L563.9 278.0Z"/>
                <path d="M600.7 256.7L582.3 267.4L600.8 278.0L619.2 267.4L600.7 256.7Z"/>
                <path d="M637.6 235.4L619.2 246.1L637.7 256.7L656.1 246.1L637.6 235.4Z"/>
                <path d="M674.5 214.1L656.1 224.8L674.5 235.4L693.0 224.8L674.5 214.1Z"/>
                <path d="M693.0 203.5L674.5 214.1L693.0 224.8L711.4 214.1L693.0 203.5Z"/>
                <path d="M711.4 192.8L693.0 203.5L711.4 214.1L729.9 203.5L711.4 192.8Z"/>
                <path d="M434.8 373.8L416.3 384.5L434.8 395.2L453.2 384.5L434.8 373.8Z"/>
                <path d="M453.2 363.2L434.8 373.8L453.2 384.5L471.7 373.9L453.2 363.2Z"/>
                <path d="M471.7 352.6L453.2 363.2L471.7 373.9L490.1 363.2L471.7 352.6Z"/>
                <path d="M508.5 331.3L490.1 341.9L508.6 352.6L527.0 341.9L508.5 331.3Z"/>
                <path d="M527.0 320.6L508.5 331.3L527.0 341.9L545.5 331.3L527.0 320.6Z"/>
                <path d="M582.3 288.7L563.9 299.3L582.3 310.0L600.8 299.3L582.3 288.7Z"/>
                <path d="M656.1 246.1L637.7 256.7L656.1 267.4L674.6 256.7L656.1 246.1Z"/>
                <path d="M674.5 235.4L656.1 246.1L674.6 256.7L693.0 246.1L674.5 235.4Z"/>
                <path d="M711.4 214.1L693.0 224.8L711.4 235.4L729.9 224.8L711.4 214.1Z"/>
                <path d="M729.9 203.5L711.4 214.1L729.9 224.8L748.3 214.1L729.9 203.5Z"/>
                <path d="M453.2 384.5L434.8 395.2L453.3 405.8L471.7 395.2L453.2 384.5Z"/>
                <path d="M471.7 373.9L453.2 384.5L471.7 395.2L490.1 384.5L471.7 373.9Z"/>
                <path d="M527.0 341.9L508.6 352.6L527.0 363.2L545.5 352.6L527.0 341.9Z"/>
                <path d="M563.9 320.6L545.5 331.3L563.9 341.9L582.4 331.3L563.9 320.6Z"/>
                <path d="M582.3 310.0L563.9 320.6L582.4 331.3L600.8 320.6L582.3 310.0Z"/>
                <path d="M600.8 299.3L582.3 310.0L600.8 320.6L619.3 310.0L600.8 299.3Z"/>
                <path d="M619.2 288.7L600.8 299.3L619.3 310.0L637.7 299.3L619.2 288.7Z"/>
                <path d="M637.7 278.0L619.2 288.7L637.7 299.3L656.1 288.7L637.7 278.0Z"/>
                <path d="M656.1 267.4L637.7 278.0L656.1 288.7L674.6 278.0L656.1 267.4Z"/>
                <path d="M674.6 256.7L656.1 267.4L674.6 278.0L693.0 267.4L674.6 256.7Z"/>
                <path d="M711.4 235.4L693.0 246.1L711.5 256.7L729.9 246.1L711.4 235.4Z"/>
                <path d="M748.3 214.1L729.9 224.8L748.4 235.5L766.8 224.8L748.3 214.1Z"/>
                <path d="M471.7 395.2L453.3 405.8L471.7 416.5L490.2 405.8L471.7 395.2Z"/>
                <path d="M490.1 384.5L471.7 395.2L490.2 405.8L508.6 395.2L490.1 384.5Z"/>
                <path d="M508.6 373.9L490.1 384.5L508.6 395.2L527.1 384.5L508.6 373.9Z"/>
                <path d="M600.8 320.6L582.4 331.3L600.8 341.9L619.3 331.3L600.8 320.6Z"/>
                <path d="M656.1 288.7L637.7 299.3L656.2 310.0L674.6 299.4L656.1 288.7Z"/>
                <path d="M693.0 267.4L674.6 278.0L693.0 288.7L711.5 278.1L693.0 267.4Z"/>
                <path d="M711.5 256.7L693.0 267.4L711.5 278.1L729.9 267.4L711.5 256.7Z"/>
                <path d="M729.9 246.1L711.5 256.7L729.9 267.4L748.4 256.8L729.9 246.1Z"/>
                <path d="M748.4 235.5L729.9 246.1L748.4 256.8L766.8 246.1L748.4 235.5Z"/>
                <path d="M766.8 224.8L748.4 235.5L766.8 246.1L785.3 235.5L766.8 224.8Z"/>
                <path d="M490.2 405.8L471.7 416.5L490.2 427.1L508.6 416.5L490.2 405.8Z"/>
                <path d="M508.6 395.2L490.2 405.8L508.6 416.5L527.1 405.8L508.6 395.2Z"/>
                <path d="M545.5 373.9L527.1 384.5L545.5 395.2L564.0 384.5L545.5 373.9Z"/>
                <path d="M563.9 363.2L545.5 373.9L564.0 384.5L582.4 373.9L563.9 363.2Z"/>
                <path d="M619.3 331.3L600.8 341.9L619.3 352.6L637.7 342.0L619.3 331.3Z"/>
                <path d="M729.9 267.4L711.5 278.1L730.0 288.7L748.4 278.1L729.9 267.4Z"/>
                <path d="M748.4 256.8L729.9 267.4L748.4 278.1L766.8 267.4L748.4 256.8Z"/>
                <path d="M785.3 235.5L766.8 246.1L785.3 256.8L803.7 246.1L785.3 235.5Z"/>
                <path d="M527.1 405.8L508.6 416.5L527.1 427.1L545.5 416.5L527.1 405.8Z"/>
                <path d="M564.0 384.5L545.5 395.2L564.0 405.8L582.4 395.2L564.0 384.5Z"/>
                <path d="M619.3 352.6L600.9 363.2L619.3 373.9L637.8 363.3L619.3 352.6Z"/>
                <path d="M656.2 331.3L637.7 342.0L656.2 352.6L674.6 342.0L656.2 331.3Z"/>
                <path d="M674.6 320.7L656.2 331.3L674.6 342.0L693.1 331.3L674.6 320.7Z"/>
                <path d="M711.5 299.4L693.1 310.0L711.5 320.7L730.0 310.0L711.5 299.4Z"/>
                <path d="M730.0 288.7L711.5 299.4L730.0 310.0L748.4 299.4L730.0 288.7Z"/>
                <path d="M748.4 278.1L730.0 288.7L748.4 299.4L766.9 288.7L748.4 278.1Z"/>
                <path d="M803.7 246.1L785.3 256.8L803.8 267.4L822.2 256.8L803.7 246.1Z"/>
                <path d="M527.1 427.1L508.7 437.8L527.1 448.5L545.6 437.8L527.1 427.1Z"/>
                <path d="M545.5 416.5L527.1 427.1L545.6 437.8L564.0 427.2L545.5 416.5Z"/>
                <path d="M582.4 395.2L564.0 405.8L582.5 416.5L600.9 405.9L582.4 395.2Z"/>
                <path d="M730.0 310.0L711.5 320.7L730.0 331.3L748.4 320.7L730.0 310.0Z"/>
                <path d="M748.4 299.4L730.0 310.0L748.4 320.7L766.9 310.0L748.4 299.4Z"/>
                <path d="M766.9 288.7L748.4 299.4L766.9 310.0L785.3 299.4L766.9 288.7Z"/>
                <path d="M785.3 278.1L766.9 288.7L785.3 299.4L803.8 288.7L785.3 278.1Z"/>
                <path d="M803.8 267.4L785.3 278.1L803.8 288.7L822.2 278.1L803.8 267.4Z"/>
                <path d="M822.2 256.8L803.8 267.4L822.2 278.1L840.7 267.4L822.2 256.8Z"/>
                <path d="M-82 267.1L47.2 341.8L176.3 267.2L47.1 192.6L-82 267.1ZM137.6 267.2L47.2 319.4L-43.2 267.2L47.1 215.0L137.6 267.2Z"/>
                <path d="M249.9 75.5L379.2 150.1L508.3 75.6L379.0 1.0L249.9 75.5ZM469.5 75.6L379.2 127.7L288.7 75.5L379.1 23.3L469.5 75.6Z"/>
                <path d="M250.3 459.0L379.6 533.6L508.7 459.1L379.4 384.5L250.3 459.0ZM469.9 459.1L379.5 511.3L289.1 459.0L379.4 406.9L469.9 459.1Z"/>
                <path d="M47.1 235.2L-8.1 267.2L47.2 299.1L102.5 267.2L47.1 235.2Z"/>
                <path d="M379.1 43.6L323.8 75.5L379.1 107.5L434.5 75.5L379.1 43.6Z"/>
                <path d="M379.5 427.1L324.1 459.0L379.5 491.0L434.9 459.1L379.5 427.1Z"/>
            </g>
            <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="138" y="48" width="529" height="325" fill="#00A8FF">
                <path d="M293.6 287.1L312.4 276.2L345.4 295.3L326.6 306.1L365.8 328.7L323.1 353.4L283.9 330.8L212.4 372.0L183.8 358.4L138.7 247.0L138.3 246.8L181.1 222.1L293.6 287.1ZM217.6 330.9L250.9 311.7L194.0 278.9L195.7 283.0L217.6 330.9Z"/>
                <path d="M456.1 189.4C481.1 203.8 494.7 218.2 497.0 232.5C499.4 246.7 490.3 259.8 470.0 271.5C449.4 283.4 426.7 288.6 401.8 287.3C377.0 285.9 352.2 278.1 327.4 263.8L293.6 244.3C268.7 229.9 255.0 215.5 252.7 201.3C250.4 187.0 259.5 173.9 279.9 162.1C300.4 150.3 323.1 145.1 347.9 146.4C372.8 147.8 397.7 155.7 422.5 170.0L456.1 189.4ZM374.2 191.5C360.9 183.8 349.3 179.1 339.3 177.3C329.2 175.5 320.4 176.8 312.9 181.2C305.6 185.4 303.2 190.3 305.9 195.7C308.7 201.1 316.2 207.5 328.3 214.8L375.5 242.0C389.2 250.0 401.1 254.8 411.0 256.5C420.8 258.2 429.5 256.8 437.0 252.5C444.3 248.3 446.5 243.4 443.8 237.8C441.0 232.2 433.1 225.6 419.9 217.8L374.2 191.5Z"/>
                <path d="M594.5 113.3L613.3 102.5L646.3 121.5L627.5 132.4L666.7 155.0L624.0 179.7L584.8 157.1L513.4 198.3L484.7 184.7L439.6 73.3L439.3 73.1L482.0 48.4L594.5 113.3ZM518.6 157.2L551.8 138.0L495.0 105.2L496.6 109.2L518.6 157.2Z"/>
            </mask>
            <g mask="url(#mask2)" fill="#fff">
                <path d="M102.4 140.7L83.9 151.3L102.4 162.0L120.9 151.3L102.4 140.7Z"/>
                <path d="M120.8 130.0L102.4 140.7L120.9 151.3L139.3 140.7L120.8 130.0Z"/>
                <path d="M139.3 119.4L120.8 130.0L139.3 140.7L157.7 130.0L139.3 119.4Z"/>
                <path d="M176.2 98.1L157.7 108.7L176.2 119.4L194.6 108.7L176.2 98.1Z"/>
                <path d="M213.0 76.8L194.6 87.4L213.1 98.1L231.5 87.4L213.0 76.8Z"/>
                <path d="M139.3 140.7L120.9 151.3L139.3 162.0L157.8 151.3L139.3 140.7Z"/>
                <path d="M157.7 130.0L139.3 140.7L157.8 151.3L176.2 140.7L157.7 130.0Z"/>
                <path d="M176.2 119.4L157.7 130.0L176.2 140.7L194.6 130.0L176.2 119.4Z"/>
                <path d="M194.6 108.7L176.2 119.4L194.6 130.0L213.1 119.4L194.6 108.7Z"/>
                <path d="M213.1 98.1L194.6 108.7L213.1 119.4L231.5 108.7L213.1 98.1Z"/>
                <path d="M231.5 87.4L213.1 98.1L231.5 108.7L250.0 98.1L231.5 87.4Z"/>
                <path d="M250.0 76.8L231.5 87.4L250.0 98.1L268.4 87.4L250.0 76.8Z"/>
                <path d="M120.9 172.6L102.4 183.3L120.9 193.9L139.3 183.3L120.9 172.6Z"/>
                <path d="M139.3 162.0L120.9 172.6L139.3 183.3L157.8 172.6L139.3 162.0Z"/>
                <path d="M157.8 151.3L139.3 162.0L157.8 172.6L176.2 162.0L157.8 151.3Z"/>
                <path d="M213.1 119.4L194.6 130.0L213.1 140.7L231.6 130.1L213.1 119.4Z"/>
                <path d="M268.4 87.4L250.0 98.1L268.4 108.8L286.9 98.1L268.4 87.4Z"/>
                <path d="M139.3 183.3L120.9 193.9L139.4 204.6L157.8 193.9L139.3 183.3Z"/>
                <path d="M157.8 172.6L139.3 183.3L157.8 193.9L176.2 183.3L157.8 172.6Z"/>
                <path d="M213.1 140.7L194.7 151.3L213.1 162.0L231.6 151.4L213.1 140.7Z"/>
                <path d="M250.0 119.4L231.6 130.1L250.0 140.7L268.5 130.1L250.0 119.4Z"/>
                <path d="M157.8 193.9L139.4 204.6L157.8 215.3L176.3 204.6L157.8 193.9Z"/>
                <path d="M194.7 172.7L176.2 183.3L194.7 194.0L213.2 183.3L194.7 172.7Z"/>
                <path d="M305.4 108.8L286.9 119.4L305.4 130.1L323.8 119.4L305.4 108.8Z"/>
                <path d="M176.3 204.6L157.8 215.3L176.3 225.9L194.7 215.3L176.3 204.6Z"/>
                <path d="M194.7 194.0L176.3 204.6L194.7 215.3L213.2 204.6L194.7 194.0Z"/>
                <path d="M250.0 162.0L231.6 172.7L250.1 183.3L268.5 172.7L250.0 162.0Z"/>
                <path d="M305.4 130.1L286.9 140.7L305.4 151.4L323.8 140.7L305.4 130.1Z"/>
                <path d="M323.8 119.4L305.4 130.1L323.8 140.7L342.3 130.1L323.8 119.4Z"/>
                <path d="M194.7 215.3L176.3 225.9L194.8 236.6L213.2 225.9L194.7 215.3Z"/>
                <path d="M231.6 194.0L213.2 204.6L231.6 215.3L250.1 204.6L231.6 194.0Z"/>
                <path d="M268.5 172.7L250.1 183.3L268.5 194.0L287.0 183.3L268.5 172.7Z"/>
                <path d="M305.4 151.4L286.9 162.0L305.4 172.7L323.9 162.0L305.4 151.4Z"/>
                <path d="M342.3 130.1L323.8 140.7L342.3 151.4L360.7 140.7L342.3 130.1Z"/>
                <path d="M213.2 225.9L194.8 236.6L213.2 247.2L231.7 236.6L213.2 225.9Z"/>
                <path d="M287.0 183.3L268.5 194.0L287.0 204.6L305.4 194.0L287.0 183.3Z"/>
                <path d="M323.9 162.0L305.4 172.7L323.9 183.4L342.3 172.7L323.9 162.0Z"/>
                <path d="M342.3 151.4L323.9 162.0L342.3 172.7L360.8 162.1L342.3 151.4Z"/>
                <path d="M360.7 140.7L342.3 151.4L360.8 162.1L379.2 151.4L360.7 140.7Z"/>
                <path d="M84.1 321.8L65.7 332.4L84.1 343.1L102.6 332.4L84.1 321.8Z"/>
                <path d="M121.0 300.5L102.6 311.1L121.0 321.8L139.5 311.1L121.0 300.5Z"/>
                <path d="M139.4 289.8L121.0 300.5L139.5 311.1L157.9 300.5L139.4 289.8Z"/>
                <path d="M157.9 279.2L139.4 289.8L157.9 300.5L176.4 289.8L157.9 279.2Z"/>
                <path d="M176.3 268.5L157.9 279.2L176.4 289.8L194.8 279.2L176.3 268.5Z"/>
                <path d="M194.8 257.9L176.3 268.5L194.8 279.2L213.2 268.5L194.8 257.9Z"/>
                <path d="M250.1 225.9L231.7 236.6L250.1 247.2L268.6 236.6L250.1 225.9Z"/>
                <path d="M268.5 215.3L250.1 225.9L268.6 236.6L287.0 226.0L268.5 215.3Z"/>
                <path d="M287.0 204.6L268.5 215.3L287.0 226.0L305.5 215.3L287.0 204.6Z"/>
                <path d="M305.4 194.0L287.0 204.6L305.5 215.3L323.9 204.7L305.4 194.0Z"/>
                <path d="M416.1 130.1L397.7 140.8L416.1 151.4L434.6 140.8L416.1 130.1Z"/>
                <path d="M434.5 119.5L416.1 130.1L434.6 140.8L453.0 130.1L434.5 119.5Z"/>
                <path d="M453.0 108.8L434.5 119.5L453.0 130.1L471.4 119.5L453.0 108.8Z"/>
                <path d="M471.4 98.2L453.0 108.8L471.4 119.5L489.9 108.8L471.4 98.2Z"/>
                <path d="M489.9 87.5L471.4 98.2L489.9 108.8L508.3 98.2L489.9 87.5Z"/>
                <path d="M102.6 332.4L84.1 343.1L102.6 353.7L121.0 343.1L102.6 332.4Z"/>
                <path d="M121.0 321.8L102.6 332.4L121.0 343.1L139.5 332.4L121.0 321.8Z"/>
                <path d="M139.5 311.1L121.0 321.8L139.5 332.4L157.9 321.8L139.5 311.1Z"/>
                <path d="M157.9 300.5L139.5 311.1L157.9 321.8L176.4 311.1L157.9 300.5Z"/>
                <path d="M268.6 236.6L250.1 247.2L268.6 257.9L287.0 247.3L268.6 236.6Z"/>
                <path d="M323.9 204.7L305.5 215.3L323.9 226.0L342.4 215.3L323.9 204.7Z"/>
                <path d="M342.3 194.0L323.9 204.7L342.4 215.3L360.8 204.7L342.3 194.0Z"/>
                <path d="M360.8 183.4L342.3 194.0L360.8 204.7L379.3 194.0L360.8 183.4Z"/>
                <path d="M397.7 162.1L379.2 172.7L397.7 183.4L416.1 172.7L397.7 162.1Z"/>
                <path d="M416.1 151.4L397.7 162.1L416.1 172.7L434.6 162.1L416.1 151.4Z"/>
                <path d="M453.0 130.1L434.6 140.8L453.0 151.4L471.5 140.8L453.0 130.1Z"/>
                <path d="M526.8 87.5L508.3 98.2L526.8 108.8L545.2 98.2L526.8 87.5Z"/>
                <path d="M139.5 332.4L121.0 343.1L139.5 353.7L158 343.1L139.5 332.4Z"/>
                <path d="M176.4 311.1L157.9 321.8L176.4 332.5L194.8 321.8L176.4 311.1Z"/>
                <path d="M213.3 289.9L194.8 300.5L213.3 311.2L231.7 300.5L213.3 289.9Z"/>
                <path d="M231.7 279.2L213.3 289.9L231.7 300.5L250.2 289.9L231.7 279.2Z"/>
                <path d="M268.6 257.9L250.1 268.6L268.6 279.2L287.1 268.6L268.6 257.9Z"/>
                <path d="M287.0 247.3L268.6 257.9L287.1 268.6L305.5 257.9L287.0 247.3Z"/>
                <path d="M323.9 226.0L305.5 236.6L323.9 247.3L342.4 236.6L323.9 226.0Z"/>
                <path d="M342.4 215.3L323.9 226.0L342.4 236.6L360.8 226.0L342.4 215.3Z"/>
                <path d="M360.8 204.7L342.4 215.3L360.8 226.0L379.3 215.3L360.8 204.7Z"/>
                <path d="M379.3 194.0L360.8 204.7L379.3 215.3L397.7 204.7L379.3 194.0Z"/>
                <path d="M397.7 183.4L379.3 194.0L397.7 204.7L416.2 194.0L397.7 183.4Z"/>
                <path d="M416.1 172.7L397.7 183.4L416.2 194.0L434.6 183.4L416.1 172.7Z"/>
                <path d="M434.6 162.1L416.1 172.7L434.6 183.4L453.0 172.7L434.6 162.1Z"/>
                <path d="M453.0 151.4L434.6 162.1L453.0 172.7L471.5 162.1L453.0 151.4Z"/>
                <path d="M508.4 119.5L489.9 130.1L508.4 140.8L526.8 130.2L508.4 119.5Z"/>
                <path d="M545.2 98.2L526.8 108.8L545.3 119.5L563.7 108.9L545.2 98.2Z"/>
                <path d="M563.7 87.5L545.2 98.2L563.7 108.9L582.2 98.2L563.7 87.5Z"/>
                <path d="M176.4 332.5L158 343.1L176.4 353.8L194.9 343.1L176.4 332.5Z"/>
                <path d="M268.6 279.2L250.2 289.9L268.6 300.5L287.1 289.9L268.6 279.2Z"/>
                <path d="M323.9 247.3L305.5 257.9L324.0 268.6L342.4 257.9L323.9 247.3Z"/>
                <path d="M379.3 215.3L360.8 226.0L379.3 236.6L397.7 226.0L379.3 215.3Z"/>
                <path d="M434.6 183.4L416.2 194.0L434.6 204.7L453.1 194.0L434.6 183.4Z"/>
                <path d="M489.9 151.4L471.5 162.1L490.0 172.8L508.4 162.1L489.9 151.4Z"/>
                <path d="M582.2 98.2L563.7 108.9L582.2 119.5L600.6 108.9L582.2 98.2Z"/>
                <path d="M158.0 364.4L139.5 375.1L158.0 385.7L176.4 375.1L158.0 364.4Z"/>
                <path d="M176.4 353.8L158.0 364.4L176.4 375.1L194.9 364.4L176.4 353.8Z"/>
                <path d="M194.9 343.1L176.4 353.8L194.9 364.4L213.3 353.8L194.9 343.1Z"/>
                <path d="M213.3 332.5L194.9 343.1L213.3 353.8L231.8 343.1L213.3 332.5Z"/>
                <path d="M268.6 300.5L250.2 311.2L268.7 321.8L287.1 311.2L268.6 300.5Z"/>
                <path d="M287.1 289.9L268.6 300.5L287.1 311.2L305.5 300.5L287.1 289.9Z"/>
                <path d="M305.5 279.2L287.1 289.9L305.5 300.5L324.0 289.9L305.5 279.2Z"/>
                <path d="M342.4 257.9L324.0 268.6L342.4 279.2L360.9 268.6L342.4 257.9Z"/>
                <path d="M379.3 236.6L360.9 247.3L379.3 257.9L397.8 247.3L379.3 236.6Z"/>
                <path d="M416.2 215.3L397.7 226.0L416.2 236.6L434.6 226.0L416.2 215.3Z"/>
                <path d="M471.5 183.4L453.1 194.0L471.5 204.7L490.0 194.1L471.5 183.4Z"/>
                <path d="M490.0 172.8L471.5 183.4L490.0 194.1L508.4 183.4L490.0 172.8Z"/>
                <path d="M508.4 162.1L490.0 172.8L508.4 183.4L526.9 172.8L508.4 162.1Z"/>
                <path d="M526.8 151.5L508.4 162.1L526.9 172.8L545.3 162.1L526.8 151.5Z"/>
                <path d="M563.7 130.2L545.3 140.8L563.8 151.5L582.2 140.8L563.7 130.2Z"/>
                <path d="M582.2 119.5L563.7 130.2L582.2 140.8L600.6 130.2L582.2 119.5Z"/>
                <path d="M600.6 108.9L582.2 119.5L600.6 130.2L619.1 119.5L600.6 108.9Z"/>
                <path d="M176.4 375.1L158.0 385.7L176.5 396.4L194.9 385.7L176.4 375.1Z"/>
                <path d="M213.3 353.8L194.9 364.4L213.3 375.1L231.8 364.4L213.3 353.8Z"/>
                <path d="M231.8 343.1L213.3 353.8L231.8 364.4L250.2 353.8L231.8 343.1Z"/>
                <path d="M250.2 332.5L231.8 343.1L250.2 353.8L268.7 343.1L250.2 332.5Z"/>
                <path d="M342.4 279.2L324.0 289.9L342.5 300.5L360.9 289.9L342.4 279.2Z"/>
                <path d="M471.5 204.7L453.1 215.4L471.6 226.0L490.0 215.4L471.5 204.7Z"/>
                <path d="M490.0 194.1L471.5 204.7L490.0 215.4L508.4 204.7L490.0 194.1Z"/>
                <path d="M526.9 172.8L508.4 183.4L526.9 194.1L545.3 183.4L526.9 172.8Z"/>
                <path d="M563.8 151.5L545.3 162.1L563.8 172.8L582.2 162.1L563.8 151.5Z"/>
                <path d="M600.6 130.2L582.2 140.8L600.7 151.5L619.1 140.8L600.6 130.2Z"/>
                <path d="M194.9 385.7L176.5 396.4L194.9 407.0L213.4 396.4L194.9 385.7Z"/>
                <path d="M250.2 353.8L231.8 364.4L250.3 375.1L268.7 364.4L250.2 353.8Z"/>
                <path d="M268.7 343.1L250.2 353.8L268.7 364.4L287.1 353.8L268.7 343.1Z"/>
                <path d="M287.1 332.5L268.7 343.1L287.1 353.8L305.6 343.1L287.1 332.5Z"/>
                <path d="M305.6 321.8L287.1 332.5L305.6 343.1L324.0 332.5L305.6 321.8Z"/>
                <path d="M397.8 268.6L379.3 279.3L397.8 289.9L416.2 279.3L397.8 268.6Z"/>
                <path d="M416.2 258.0L397.8 268.6L416.2 279.3L434.7 268.6L416.2 258.0Z"/>
                <path d="M471.6 226.0L453.1 236.7L471.6 247.3L490.0 236.7L471.6 226.0Z"/>
                <path d="M526.9 194.1L508.4 204.7L526.9 215.4L545.4 204.7L526.9 194.1Z"/>
                <path d="M545.3 183.4L526.9 194.1L545.4 204.7L563.8 194.1L545.3 183.4Z"/>
                <path d="M563.8 172.8L545.3 183.4L563.8 194.1L582.2 183.4L563.8 172.8Z"/>
                <path d="M582.2 162.1L563.8 172.8L582.2 183.4L600.7 172.8L582.2 162.1Z"/>
                <path d="M619.1 140.8L600.7 151.5L619.1 162.1L637.6 151.5L619.1 140.8Z"/>
                <path d="M637.5 130.2L619.1 140.8L637.6 151.5L656.0 140.8L637.5 130.2Z"/>
                <path d="M213.4 396.4L194.9 407.0L213.4 417.7L231.8 407.0L213.4 396.4Z"/>
                <path d="M268.7 364.4L250.3 375.1L268.7 385.8L287.2 375.1L268.7 364.4Z"/>
                <path d="M287.1 353.8L268.7 364.4L287.2 375.1L305.6 364.5L287.1 353.8Z"/>
                <path d="M342.5 321.9L324.0 332.5L342.5 343.2L360.9 332.5L342.5 321.9Z"/>
                <path d="M360.9 311.2L342.5 321.9L360.9 332.5L379.4 321.9L360.9 311.2Z"/>
                <path d="M416.2 279.3L397.8 289.9L416.3 300.6L434.7 289.9L416.2 279.3Z"/>
                <path d="M471.6 247.3L453.1 258.0L471.6 268.6L490.0 258.0L471.6 247.3Z"/>
                <path d="M526.9 215.4L508.5 226.0L526.9 236.7L545.4 226.0L526.9 215.4Z"/>
                <path d="M563.8 194.1L545.4 204.7L563.8 215.4L582.3 204.7L563.8 194.1Z"/>
                <path d="M582.2 183.4L563.8 194.1L582.3 204.7L600.7 194.1L582.2 183.4Z"/>
                <path d="M656.0 140.8L637.6 151.5L656.0 162.2L674.5 151.5L656.0 140.8Z"/>
                <path d="M231.8 407.0L213.4 417.7L231.9 428.4L250.3 417.7L231.8 407.0Z"/>
                <path d="M287.2 375.1L268.7 385.8L287.2 396.4L305.6 385.8L287.2 375.1Z"/>
                <path d="M342.5 343.2L324.0 353.8L342.5 364.5L361.0 353.8L342.5 343.2Z"/>
                <path d="M416.3 300.6L397.8 311.2L416.3 321.9L434.7 311.2L416.3 300.6Z"/>
                <path d="M434.7 289.9L416.3 300.6L434.7 311.2L453.2 300.6L434.7 289.9Z"/>
                <path d="M508.5 247.3L490.0 258.0L508.5 268.6L527 258.0L508.5 247.3Z"/>
                <path d="M526.9 236.7L508.5 247.3L527 258.0L545.4 247.3L526.9 236.7Z"/>
                <path d="M545.4 226.0L526.9 236.7L545.4 247.3L563.8 236.7L545.4 226.0Z"/>
                <path d="M563.8 215.4L545.4 226.0L563.8 236.7L582.3 226.1L563.8 215.4Z"/>
                <path d="M582.3 204.7L563.8 215.4L582.3 226.1L600.7 215.4L582.3 204.7Z"/>
                <path d="M600.7 194.1L582.3 204.7L600.7 215.4L619.2 204.8L600.7 194.1Z"/>
                <path d="M637.6 172.8L619.1 183.4L637.6 194.1L656.1 183.5L637.6 172.8Z"/>
                <path d="M397.8 332.5L379.4 343.2L397.9 353.8L416.3 343.2L397.8 332.5Z"/>
                <path d="M416.3 321.9L397.8 332.5L416.3 343.2L434.8 332.5L416.3 321.9Z"/>
                <path d="M471.6 289.9L453.2 300.6L471.6 311.2L490.1 300.6L471.6 289.9Z"/>
                <path d="M490.1 279.3L471.6 289.9L490.1 300.6L508.5 289.9L490.1 279.3Z"/>
                <path d="M508.5 268.6L490.1 279.3L508.5 289.9L527.0 279.3L508.5 268.6Z"/>
                <path d="M545.4 247.3L527 258.0L545.4 268.7L563.9 258.0L545.4 247.3Z"/>
                <path d="M619.2 204.8L600.7 215.4L619.2 226.1L637.6 215.4L619.2 204.8Z"/>
                <path d="M637.6 194.1L619.2 204.8L637.6 215.4L656.1 204.8L637.6 194.1Z"/>
                <path d="M434.8 332.5L416.3 343.2L434.8 353.8L453.2 343.2L434.8 332.5Z"/>
                <path d="M508.5 289.9L490.1 300.6L508.5 311.3L527.0 300.6L508.5 289.9Z"/>
                <path d="M527.0 279.3L508.5 289.9L527.0 300.6L545.4 290.0L527.0 279.3Z"/>
                <path d="M563.9 258.0L545.4 268.7L563.9 279.3L582.3 268.7L563.9 258.0Z"/>
                <path d="M600.7 236.7L582.3 247.4L600.8 258.0L619.2 247.4L600.7 236.7Z"/>
                <path d="M637.6 215.4L619.2 226.1L637.7 236.7L656.1 226.1L637.6 215.4Z"/>
                <path d="M674.5 194.1L656.1 204.8L674.5 215.4L693.0 204.8L674.5 194.1Z"/>
                <path d="M693.0 183.5L674.5 194.1L693.0 204.8L711.4 194.1L693.0 183.5Z"/>
                <path d="M711.4 172.8L693.0 183.5L711.4 194.1L729.9 183.5L711.4 172.8Z"/>
                <path d="M434.8 353.8L416.3 364.5L434.8 375.2L453.2 364.5L434.8 353.8Z"/>
                <path d="M453.2 343.2L434.8 353.8L453.2 364.5L471.7 353.9L453.2 343.2Z"/>
                <path d="M471.7 332.6L453.2 343.2L471.7 353.9L490.1 343.2L471.7 332.6Z"/>
                <path d="M508.5 311.3L490.1 321.9L508.6 332.6L527.0 321.9L508.5 311.3Z"/>
                <path d="M527.0 300.6L508.5 311.3L527.0 321.9L545.5 311.3L527.0 300.6Z"/>
                <path d="M582.3 268.7L563.9 279.3L582.3 290.0L600.8 279.3L582.3 268.7Z"/>
                <path d="M656.1 226.1L637.7 236.7L656.1 247.4L674.6 236.7L656.1 226.1Z"/>
                <path d="M674.5 215.4L656.1 226.1L674.6 236.7L693.0 226.1L674.5 215.4Z"/>
                <path d="M711.4 194.1L693.0 204.8L711.4 215.4L729.9 204.8L711.4 194.1Z"/>
                <path d="M729.9 183.5L711.4 194.1L729.9 204.8L748.3 194.1L729.9 183.5Z"/>
                <path d="M453.2 364.5L434.8 375.2L453.3 385.8L471.7 375.2L453.2 364.5Z"/>
                <path d="M471.7 353.9L453.2 364.5L471.7 375.2L490.1 364.5L471.7 353.9Z"/>
                <path d="M527.0 321.9L508.6 332.6L527.0 343.2L545.5 332.6L527.0 321.9Z"/>
                <path d="M563.9 300.6L545.5 311.3L563.9 321.9L582.4 311.3L563.9 300.6Z"/>
                <path d="M582.3 290.0L563.9 300.6L582.4 311.3L600.8 300.6L582.3 290.0Z"/>
                <path d="M600.8 279.3L582.3 290.0L600.8 300.6L619.3 290.0L600.8 279.3Z"/>
                <path d="M619.2 268.7L600.8 279.3L619.3 290.0L637.7 279.3L619.2 268.7Z"/>
                <path d="M637.7 258.0L619.2 268.7L637.7 279.3L656.1 268.7L637.7 258.0Z"/>
                <path d="M656.1 247.4L637.7 258.0L656.1 268.7L674.6 258.0L656.1 247.4Z"/>
                <path d="M674.6 236.7L656.1 247.4L674.6 258.0L693.0 247.4L674.6 236.7Z"/>
                <path d="M711.4 215.4L693.0 226.1L711.5 236.7L729.9 226.1L711.4 215.4Z"/>
                <path d="M748.3 194.1L729.9 204.8L748.4 215.5L766.8 204.8L748.3 194.1Z"/>
                <path d="M471.7 375.2L453.3 385.8L471.7 396.5L490.2 385.8L471.7 375.2Z"/>
                <path d="M490.1 364.5L471.7 375.2L490.2 385.8L508.6 375.2L490.1 364.5Z"/>
                <path d="M508.6 353.9L490.1 364.5L508.6 375.2L527.1 364.5L508.6 353.9Z"/>
                <path d="M600.8 300.6L582.4 311.3L600.8 321.9L619.3 311.3L600.8 300.6Z"/>
                <path d="M656.1 268.7L637.7 279.3L656.2 290.0L674.6 279.3L656.1 268.7Z"/>
                <path d="M693.0 247.4L674.6 258.0L693.0 268.7L711.5 258.1L693.0 247.4Z"/>
                <path d="M711.5 236.7L693.0 247.4L711.5 258.1L729.9 247.4L711.5 236.7Z"/>
                <path d="M729.9 226.1L711.5 236.7L729.9 247.4L748.4 236.8L729.9 226.1Z"/>
                <path d="M748.4 215.5L729.9 226.1L748.4 236.8L766.8 226.1L748.4 215.5Z"/>
                <path d="M766.8 204.8L748.4 215.5L766.8 226.1L785.3 215.5L766.8 204.8Z"/>
                <path d="M490.2 385.8L471.7 396.5L490.2 407.1L508.6 396.5L490.2 385.8Z"/>
                <path d="M508.6 375.2L490.2 385.8L508.6 396.5L527.1 385.8L508.6 375.2Z"/>
                <path d="M545.5 353.9L527.1 364.5L545.5 375.2L564.0 364.5L545.5 353.9Z"/>
                <path d="M563.9 343.2L545.5 353.9L564.0 364.5L582.4 353.9L563.9 343.2Z"/>
                <path d="M619.3 311.3L600.8 321.9L619.3 332.6L637.7 322.0L619.3 311.3Z"/>
                <path d="M729.9 247.4L711.5 258.1L730.0 268.7L748.4 258.1L729.9 247.4Z"/>
                <path d="M748.4 236.8L729.9 247.4L748.4 258.1L766.8 247.4L748.4 236.8Z"/>
                <path d="M785.3 215.5L766.8 226.1L785.3 236.8L803.7 226.1L785.3 215.5Z"/>
                <path d="M527.1 385.8L508.6 396.5L527.1 407.1L545.5 396.5L527.1 385.8Z"/>
                <path d="M564.0 364.5L545.5 375.2L564.0 385.8L582.4 375.2L564.0 364.5Z"/>
                <path d="M619.3 332.6L600.9 343.2L619.3 353.9L637.8 343.3L619.3 332.6Z"/>
                <path d="M656.2 311.3L637.7 322.0L656.2 332.6L674.6 322.0L656.2 311.3Z"/>
                <path d="M674.6 300.7L656.2 311.3L674.6 322.0L693.1 311.3L674.6 300.7Z"/>
                <path d="M711.5 279.4L693.1 290.0L711.5 300.7L730.0 290.0L711.5 279.4Z"/>
                <path d="M730.0 268.7L711.5 279.4L730.0 290.0L748.4 279.4L730.0 268.7Z"/>
                <path d="M748.4 258.1L730.0 268.7L748.4 279.4L766.9 268.7L748.4 258.1Z"/>
                <path d="M803.7 226.1L785.3 236.8L803.8 247.4L822.2 236.8L803.7 226.1Z"/>
                <path d="M527.1 407.1L508.7 417.8L527.1 428.5L545.6 417.8L527.1 407.1Z"/>
                <path d="M545.5 396.5L527.1 407.1L545.6 417.8L564.0 407.2L545.5 396.5Z"/>
                <path d="M582.4 375.2L564.0 385.8L582.5 396.5L600.9 385.9L582.4 375.2Z"/>
                <path d="M730.0 290.0L711.5 300.7L730.0 311.3L748.4 300.7L730.0 290.0Z"/>
                <path d="M748.4 279.4L730.0 290.0L748.4 300.7L766.9 290.0L748.4 279.4Z"/>
                <path d="M766.9 268.7L748.4 279.4L766.9 290.0L785.3 279.4L766.9 268.7Z"/>
                <path d="M785.3 258.1L766.9 268.7L785.3 279.4L803.8 268.7L785.3 258.1Z"/>
                <path d="M803.8 247.4L785.3 258.1L803.8 268.7L822.2 258.1L803.8 247.4Z"/>
                <path d="M822.2 236.8L803.8 247.4L822.2 258.1L840.7 247.4L822.2 236.8Z"/>
                <path d="M-82 247.1L47.2 321.8L176.3 247.2L47.1 172.6L-82 247.1ZM137.6 247.2L47.2 299.4L-43.2 247.2L47.1 195.0L137.6 247.2Z"/>
                <path d="M249.9 55.5L379.2 130.1L508.3 55.6L379.0 -18.9L249.9 55.5ZM469.5 55.6L379.2 107.7L288.7 55.5L379.1 3.3L469.5 55.6Z"/>
                <path d="M250.3 439.0L379.6 513.6L508.7 439.1L379.4 364.5L250.3 439.0ZM469.9 439.1L379.5 491.3L289.1 439.0L379.4 386.9L469.9 439.1Z"/>
                <path d="M47.1 215.2L-8.1 247.2L47.2 279.1L102.5 247.2L47.1 215.2Z"/>
                <path d="M379.1 23.6L323.8 55.5L379.1 87.5L434.5 55.5L379.1 23.6Z"/>
                <path d="M379.5 407.1L324.1 439.0L379.5 471.0L434.9 439.1L379.5 407.1Z"/>
            </g>
            <g fill="#00A8FF">
                <path d="M256.1 428.7L262.1 432.1L260.9 432.9L246.1 424.3L251.1 421.4C252.7 420.5 254.3 420.1 255.9 420.0C257.6 420.0 259.1 420.3 260.5 421.1C261.9 421.9 262.5 422.8 262.4 423.7C262.3 424.6 261.5 425.6 259.9 426.5L256.1 428.7ZM255.0 428.1L258.8 425.9C260.0 425.2 260.7 424.5 260.7 423.8C260.8 423.1 260.3 422.4 259.3 421.8C258.3 421.3 257.1 421.0 255.9 421.0C254.7 421.0 253.5 421.3 252.3 422.0L248.4 424.2L255.0 428.1Z"/>
                <path d="M280.2 421.7C279.7 421.6 279.1 421.3 278.4 421.0C278.6 421.5 278.5 422.1 278.1 422.6C277.7 423.2 277.2 423.7 276.4 424.1C275.4 424.7 274.2 425.0 273.0 425.1C271.8 425.1 270.7 424.9 269.8 424.4C268.7 423.7 268.3 423.0 268.6 422.1C268.9 421.2 269.8 420.4 271.4 419.4L273.6 418.2L272.3 417.4C271.5 417.0 270.7 416.8 269.8 416.8C268.8 416.8 267.9 417.1 267.0 417.6C266.1 418.1 265.6 418.6 265.5 419.2C265.4 419.8 265.6 420.2 266.2 420.6L265.0 421.3C264.1 420.8 263.8 420.1 264.0 419.3C264.1 418.4 264.8 417.7 266.0 417.0C267.2 416.3 268.5 415.9 269.8 415.9C271.1 415.8 272.3 416.1 273.5 416.7L278.7 419.7C279.7 420.3 280.6 420.7 281.4 420.9L281.5 421.0L280.2 421.7ZM275.5 423.4C276.3 422.9 276.9 422.4 277.1 421.8C277.3 421.2 277.3 420.6 276.9 420.1L274.5 418.7L272.3 420.0C271.1 420.7 270.4 421.3 270.2 422.0C269.9 422.6 270.2 423.2 270.9 423.6C271.5 424 272.3 424.1 273.1 424.1C274.0 424.1 274.8 423.8 275.5 423.4Z"/>
                <path d="M278.3 416.3C276.6 415.3 275.6 414.3 275.4 413.3C275.2 412.3 275.7 411.4 277.0 410.7C278.5 409.8 280.2 409.5 282.2 409.7L280.7 408.8L281.8 408.1L292.6 414.3C294.0 415.1 294.7 416.0 294.7 416.9C294.8 417.9 294.1 418.7 292.7 419.5C292.0 420.0 291.1 420.3 290.0 420.5C289.0 420.7 288.0 420.7 287.0 420.6L287.0 419.8C288.9 419.9 290.5 419.7 291.6 419.0C292.7 418.4 293.1 417.8 293.1 417.1C293.1 416.4 292.6 415.8 291.5 415.1L290.1 414.3C290.4 415.4 289.8 416.4 288.4 417.2C287.1 418.0 285.6 418.3 283.8 418.1C282.0 418.0 280.2 417.4 278.5 416.4L278.3 416.3ZM279.8 415.7C281.2 416.6 282.6 417.0 283.9 417.2C285.3 417.3 286.5 417.1 287.5 416.5C289.0 415.6 289.4 414.6 288.7 413.5L283.6 410.6C282.6 410.3 281.7 410.2 280.8 410.3C279.8 410.4 279.0 410.7 278.3 411.1C277.2 411.7 276.9 412.4 277.1 413.2C277.3 414.0 278.2 414.8 279.8 415.7Z"/>
                <path d="M300.4 410.3C299.5 410.8 298.4 411.2 297.2 411.3C296.0 411.5 294.7 411.5 293.5 411.3C292.3 411.0 291.1 410.6 290.1 410.0L289.7 409.8C288.6 409.2 287.9 408.5 287.4 407.8C287.0 407.0 286.9 406.3 287.1 405.6C287.4 404.9 287.9 404.3 288.8 403.8C290.1 403.1 291.6 402.7 293.3 402.8C295.0 402.9 296.7 403.4 298.2 404.3L298.9 404.7L291.1 409.2L291.3 409.3C292.6 410.0 293.9 410.4 295.5 410.5C297.0 410.6 298.3 410.3 299.4 409.7C300.1 409.3 300.5 408.9 300.8 408.5C301.1 408.0 301.1 407.6 301.0 407.0L302.3 406.9C302.7 408.2 302.1 409.3 300.4 410.3ZM289.8 404.4C288.9 405.0 288.5 405.6 288.5 406.4C288.6 407.1 289.1 407.8 290.1 408.6L296.7 404.8L296.5 404.7C295.4 404.1 294.3 403.8 293.1 403.7C291.8 403.7 290.7 403.9 289.8 404.4Z"/>
                <path d="M513.8 260.0L515.7 261.1C515.4 260.5 515.4 259.9 515.7 259.3C515.9 258.7 516.4 258.2 517.2 257.8C518.4 257.1 519.6 256.8 520.8 256.9C522.0 256.9 523.3 257.3 524.7 258.1L531.9 262.3L530.7 262.9L523.4 258.8C522.5 258.2 521.5 257.9 520.6 257.9C519.7 257.8 518.8 258.1 517.9 258.6C517.2 259.0 516.8 259.6 516.7 260.2C516.5 260.8 516.8 261.4 517.3 262.0L524.8 266.3L523.6 267.0L512.6 260.7L513.8 260.0Z"/>
                <path d="M528.7 257.6C527.6 256.9 526.9 256.3 526.4 255.6C526.0 254.8 525.9 254.1 526.2 253.4C526.5 252.7 527.1 252.1 528.1 251.5C529.5 250.7 531.2 250.3 533.1 250.4C535.1 250.4 536.9 251.0 538.6 251.9L538.8 252.1C539.9 252.7 540.6 253.4 541.1 254.1C541.5 254.8 541.6 255.5 541.3 256.2C541.0 256.9 540.4 257.6 539.5 258.1C538.0 258.9 536.3 259.3 534.4 259.3C532.4 259.2 530.6 258.7 528.9 257.7L528.7 257.6ZM530.2 257.0C531.6 257.8 533.0 258.2 534.5 258.3C536.0 258.4 537.3 258.1 538.4 257.5C539.5 256.9 540.0 256.1 539.8 255.2C539.7 254.4 538.9 253.5 537.5 252.7L537.3 252.6C536.4 252.1 535.5 251.8 534.5 251.5C533.5 251.3 532.5 251.3 531.6 251.4C530.6 251.5 529.8 251.7 529.1 252.1C528.0 252.8 527.5 253.5 527.7 254.4C527.9 255.3 528.6 256.1 530.0 256.9L530.2 257.0Z"/>
                <path d="M534.8 244.7L537.6 246.3L539.8 245.0L540.8 245.5L538.6 246.9L545.9 251.1C546.5 251.4 547.1 251.6 547.6 251.7C548.1 251.7 548.6 251.6 549.1 251.3C549.3 251.2 549.6 251.0 550.0 250.7L551.0 251.2C550.8 251.5 550.5 251.7 549.9 252.1C549.1 252.5 548.2 252.8 547.4 252.7C546.5 252.6 545.6 252.3 544.7 251.8L537.3 247.6L535.3 248.7L534.3 248.1L536.4 247.0L533.5 245.4L534.8 244.7Z"/>
                <path d="M559.3 246.4L549.3 240.7L547.5 241.7L546.5 241.1L548.3 240.1L547.0 239.3C545.8 238.7 545.3 238.0 545.2 237.3C545.2 236.7 545.7 236.0 546.7 235.4C547.2 235.2 547.7 235.0 548.2 234.8L549.1 235.4C548.7 235.6 548.3 235.8 547.9 236.0C547.2 236.4 546.9 236.8 546.9 237.3C547.0 237.7 547.4 238.2 548.1 238.6L549.5 239.4L552.1 237.9L553.1 238.5L550.5 240.0L560.5 245.7L559.3 246.4Z"/>
                <path d="M559.0 240.1C557.9 239.5 557.2 238.8 556.7 238.1C556.3 237.4 556.2 236.6 556.5 236C556.8 235.2 557.4 234.6 558.3 234.1C559.8 233.2 561.5 232.8 563.4 232.9C565.3 233.0 567.1 233.5 568.8 234.5L569.1 234.6C570.2 235.2 570.9 235.9 571.3 236.6C571.8 237.4 571.9 238.1 571.6 238.8C571.3 239.5 570.7 240.1 569.7 240.6C568.3 241.5 566.6 241.9 564.6 241.8C562.7 241.7 560.9 241.2 559.2 240.2L559.0 240.1ZM560.5 239.6C561.8 240.3 563.2 240.8 564.8 240.9C566.3 241 567.6 240.7 568.7 240.0C569.8 239.4 570.3 238.7 570.1 237.8C569.9 236.9 569.2 236.1 567.8 235.3L567.6 235.1C566.7 234.6 565.8 234.3 564.8 234.1C563.8 233.8 562.8 233.8 561.9 233.9C560.9 234.0 560.1 234.2 559.4 234.7C558.3 235.3 557.8 236.0 558.0 236.9C558.1 237.8 558.9 238.7 560.3 239.4L560.5 239.6Z"/>
                <path d="M582.6 231.4C582.9 232.4 582.3 233.4 580.7 234.3C579.5 235.0 578.3 235.3 577.0 235.3C575.7 235.2 574.4 234.8 573.1 234.0L565.9 229.9L567.1 229.2L574.1 233.3C576.2 234.5 578.1 234.6 579.8 233.6C581.5 232.6 581.9 231.5 580.9 230.4L573.0 225.8L574.2 225.1L585.2 231.5L584.0 232.1L582.6 231.4Z"/>
                <path d="M578.5 222.6L580.5 223.7C580.2 223.1 580.2 222.5 580.5 221.9C580.7 221.3 581.2 220.9 582.0 220.4C583.1 219.7 584.3 219.4 585.6 219.5C586.8 219.5 588.1 219.9 589.4 220.7L596.6 224.9L595.4 225.6L588.2 221.4C587.2 220.8 586.3 220.5 585.4 220.5C584.5 220.4 583.6 220.7 582.7 221.2C582.0 221.6 581.5 222.2 581.4 222.8C581.3 223.4 581.5 224.0 582.0 224.6L589.6 228.9L588.4 229.6L577.4 223.3L578.5 222.6Z"/>
                <path d="M593.7 220.1C592.0 219.1 591.0 218.1 590.8 217.1C590.5 216.1 591.1 215.2 592.4 214.4C593.8 213.6 595.6 213.2 597.5 213.5L591.3 209.9L592.5 209.2L608.1 218.2L607.0 218.9L605.5 218.1C605.8 219.2 605.2 220.1 603.7 221.0C602.5 221.7 600.9 222.0 599.1 221.9C597.3 221.8 595.6 221.2 593.8 220.2L593.7 220.1ZM595.1 219.5C596.5 220.3 597.9 220.8 599.3 220.9C600.7 221.1 601.9 220.9 602.9 220.3C604.4 219.4 604.8 218.4 604.1 217.3L598.9 214.3C596.9 213.8 595.1 214.0 593.6 214.9C592.6 215.5 592.2 216.2 592.5 217.0C592.7 217.8 593.6 218.6 595.1 219.5Z"/>
            </g>
        </svg>
    )

}


