import React from 'react';
import styled from 'styled-components';

import { TextBasicSpan } from 'Elements/TextBasic';


const TitleSpan = styled(TextBasicSpan)`
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
`;

const Title = (props) => {

    return (
        <TitleSpan className={props.className}>
            {props.children}
        </TitleSpan>
    );

}

export { Title, TitleSpan }
