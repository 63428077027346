import React from 'react';

import { ConfigStore } from 'DataSource/Stores/ConfigStore.js';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';

import { Title } from 'Elements/Title';
import { ButtonClose } from 'Elements/ButtonClose';
import {TextBasic} from "Elements/TextBasic";
import { Link } from 'Elements/Link';


const BlockNewCounterCreated = (props) => {

    const {
        countersData,
        close,
    } = props;

    const baseUrl = ConfigStore.config.BASE_LINK_URL;

    /**
     *
     */
    return (

        <Block>

            <Filled>
                <Title>
                    New counters was created
                </Title>
                <ButtonClose onClick={close} />
            </Filled>

            {
                countersData?.map((counter, index) => {
                    const fullLinkUrl = `${baseUrl}/c/${counter.tail}?r=[timestamp]`;
                    return (
                        <>
                            <Filled key={`counter-${index}`}>
                                <TextBasic>
                                    {counter.title}
                                </TextBasic>
                            </Filled>
                            <Filled>
                                <Link to={fullLinkUrl}>
                                    {fullLinkUrl}
                                </Link>
                            </Filled>
                        </>
                    )
                })
            }

            <Filled>
                <TextBasic>
                    If your system does not support "[timestamp]" tag, replace "[timestamp]" with your system's random number generator tag to protect against caching
                </TextBasic>
            </Filled>

        </Block>

    );
};

export { BlockNewCounterCreated }
