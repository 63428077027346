import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import 'css/general.sass';
import 'css/fonts.sass';
import 'mobx-react-lite/batchingForReactDom'
import { configure as configureMobx } from 'mobx';
import { AuthStore } from 'DataSource/Stores/AuthStore.js';
import { ConfigStore } from 'DataSource/Stores/ConfigStore.js';

configureMobx({
    enforceActions: 'always'
});

ConfigStore
    .loadConfig()
    .then( () => {
        AuthStore.tokenRestore();
    });

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
