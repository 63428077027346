import React from 'react';
import { ButtonBasicIcon } from 'Elements/ButtonBasicIcon';

const ButtonEdit = (props) => {

    return (
        <ButtonBasicIcon {...props}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 20H19.6348V18.0421H0V20ZM6.87218 13.1473V10.514L12.471 5.3441L14.4257 7.49388L9.43157 13.1473H6.87218ZM15.4712 2.64513L17.1991 4.36319L15.8531 5.78757L14.0536 3.99315L15.4712 2.64513ZM20 4.64513L15.3642 0L4.9087 9.68771V15.1052H10.2729L20 4.64513Z" />
            </svg>
        </ButtonBasicIcon>
    );
}

export { ButtonEdit }


