import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom'
import { Scrollbars } from "react-custom-scrollbars"
import FadeIn from 'react-fade-in';

import { Exceptor } from './Exceptor';
import { Everything as EverythingPage } from 'Pages/Everything';
import { LoginPage } from 'Pages/Login';
import { LoadingPage } from 'Pages/Loading';
import { AboutPage } from 'Pages/About';
import { CampaignsPage } from 'Pages/Campaigns';
import { LinksPage } from 'Pages/Links';
import { LinkDetailsPage } from 'Pages/LinkDetails';
import { CounterDetailsPage } from 'Pages/CounterDetails';
import { OuchPage } from 'Pages/Ouch';
import { NotFoundPage } from 'Pages/NotFound';
import { TestPage } from 'Pages/Test';

import { BottomLinks } from 'Blocks/BottomLinks';

import { Link } from 'Elements/Link';
import { AuthStore } from 'DataSource/Stores/AuthStore.js';
import { ConfigStore } from 'DataSource/Stores/ConfigStore.js';

const ScrollbarsCustom = styled(Scrollbars)`
    & > div:nth-child(2) > div {
        background-color: rgba(255, 255, 255, 0.2) !important;
    }
    & > div:nth-child(3) > div {
        background-color: rgba(255, 255, 255, 0.2) !important;
    }
`;

const Content = styled.div`
    max-width: 920px;
    margin: 0 auto;
`;

const App = observer((props) => {

    const routerContent = () => {

        if ( ! AuthStore.token || AuthStore.tokenInvalid) {
            return <LoginPage />;
        }

        return (
            <Switch>
                <Route exact path='/' component={CampaignsPage}/>
                <Route path='/about' component={AboutPage}/>
                <Route path='/everything' component={EverythingPage}/>
                <Route exact path='/campaigns' component={CampaignsPage}/>
                <Route path='/campaigns/:id' component={LinksPage}/>
                <Route path='/links/:id' component={LinkDetailsPage}/>
                <Route path='/counters/:id' component={CounterDetailsPage}/>
                <Route path='/login' component={LoginPage}/>
                <Route path='/ouch' component={OuchPage}/>
                <Route path='/test' component={TestPage}/>
                <Route component={NotFoundPage}/>
            </Switch>
        )
    }

    if ( ! ConfigStore.config) {
        return <LoadingPage />;
    }

    return (
        <ScrollbarsCustom style={{ width: '100%', height: '100%' }}>

            <Content>
                <Exceptor>

                    { routerContent() }

                    { /*
                    <FadeIn>
                        <BottomLinks />
                    </FadeIn>
                    */ }

                </Exceptor>
            </Content>

        </ScrollbarsCustom>
    );
})

export { App };
