import React from 'react';
import { ButtonBasicIcon } from 'Elements/ButtonBasicIcon';

const ButtonAdd = (props) => {

    return (
        <ButtonBasicIcon {...props}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 9V11H11V20H9V11H0V9H9V0H11V9H20Z" />
            </svg>
        </ButtonBasicIcon>
    );
}

export { ButtonAdd }


