import React from 'react';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';

import { Title } from 'Elements/Title';
import { ButtonClose } from 'Elements/ButtonClose';
import { TextField } from 'Elements/TextField';
import { ButtonBasicText } from 'Elements/ButtonBasicText';

const Search = (props) => {

    const {
        searchText,
        placeholder = 'Enter search text',
        setSearchMode,
        setSearchText,
    } = props;

    /**
     *
     */
    return (
        <Block>
            <Filled>
                <Title>
                    Searching
                </Title>
                <ButtonClose onClick={ () => setSearchMode(false) } />
            </Filled>
            <Filled>
                <TextField placeholder={placeholder} value={searchText} onChange={ e => setSearchText(e.target.value) } />
            </Filled>
            <Filled>
                <ButtonBasicText>
                    Search
                </ButtonBasicText>
            </Filled>
        </Block>
    );
}

export { Search }
