import React from 'react';
import styled from 'styled-components';
import { ButtonBasic } from 'Elements/ButtonBasic';

const ButtonBasicIconDiv = styled(ButtonBasic)`
    width: 32px;
    max-width: 32px; /* for flex containers */

    & > svg {
        width: 15px;
        height: 15px;
        fill: ${props => props.disabled ? 'rgba(37, 37, 37, 0.4)' : '#252525'};
    }
`;

const ButtonBasicIcon = (props) => {

    return (
        <ButtonBasicIconDiv {...props}>
            {props.children}
        </ButtonBasicIconDiv>
    );
}

export { ButtonBasicIconDiv, ButtonBasicIcon }
