import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { observer } from 'mobx-react';
import { format, subDays } from 'date-fns';

import { LinkStore } from 'DataSource/Stores/LinkStore';

import { Header } from 'Components/Header';
import { DateFilter } from 'Components/DateFilter';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';
import { Row } from 'Wrappers/Row';

import { Title } from 'Elements/Title';
import { ButtonEdit } from 'Elements/ButtonEdit';
import { ButtonBack } from 'Elements/ButtonBack';
import { ButtonRefresh } from 'Elements/ButtonRefresh';
import { Loader } from 'Elements/Loader';

import { LinkInfo } from 'Blocks/LinkInfo';
import { BlockEditLink } from './BlockEditLink';
import { BlockStat } from 'Blocks/BlockStat';

import { StatStoreClass } from 'DataSource/Stores/StatStore';

const LinkDetailsPage = (props) => {

    const [ editLinkMode, stateSetEditLinkMode ] = useState(false);
    const [ startDate, stateSetStartDate ] = useState( subDays(new Date(), 7) );
    const [ endDate, stateSetEndDate ] = useState( new Date() );
    const [ StatStoreDate ] = useState(new StatStoreClass());
    const [ StatStoreOs ] = useState(new StatStoreClass());
    const [ StatStoreIsp ] = useState(new StatStoreClass());
    const [ StatStoreRegion ] = useState(new StatStoreClass());

    const linkId = useParams().id;

    const onRefresh = (withStatistics) => {
        LinkStore.forLink(linkId);
        withStatistics && loadStatistics();
        return true;
    }

    var statParams = {
        type: 'link',
        id: linkId,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
    }

    const loadStatistics = () => {

        StatStoreDate.load('/api/statistics/daily', statParams);
        StatStoreOs.load('/api/statistics/os', statParams);
        StatStoreIsp.load('/api/statistics/isp', statParams);
        StatStoreRegion.load('/api/statistics/region', statParams);

    }

    /**
     *
     */
    useEffect(
        () => {
            console.log('link details page init');
            onRefresh();
        },
        []
    );

    /**
     *
     */
    useEffect(
        () => {
            console.log('reload statistics');
            loadStatistics();
        },
        [startDate, endDate]
    );

    const pageContent = () => {

        if ( ! LinkStore.data || ! LinkStore.data.length ) {
            return <Loader height="40px" />;
        }

        const link = LinkStore.data[0];

        return (
            <>
                <FadeIn>
                    <Block>
                        <Filled>
                            <Title>
                                { link.title }
                            </Title>
                            <ButtonEdit onClick={stateSetEditLinkMode} title="Edit link" />
                            <ButtonRefresh onClick={() => onRefresh(true)} title="Refresh" />
                            <ButtonBack link={"/campaigns/" + link['campaign_id']} title="Back to links" />
                        </Filled>
                    </Block>
                </FadeIn>

                <FadeIn>
                    <Block>
                        <Filled>
                            <Title>
                                Information
                            </Title>

                        </Filled>

                        <LinkInfo title={link.title} tail={link.tail} />

                    </Block>
                </FadeIn>

                {editLinkMode &&
                    <FadeIn>
                        <BlockEditLink
                            setEditMode={stateSetEditLinkMode}
                            linkData={link}
                            refresh={onRefresh}
                        />
                    </FadeIn>
                }

                <FadeIn>
                    <Block>
                        <Row>
                            <DateFilter
                                title='Statistics'
                                dateFrom={startDate}
                                setDateFrom={stateSetStartDate}
                                dateTo={endDate}
                                setDateTo={stateSetEndDate}
                            />
                        </Row>
                    </Block>
                </FadeIn>

                <FadeIn>
                    <BlockStat
                        firstColumnTitle='Date'
                        store={StatStoreDate}
                    />
                </FadeIn>

                <FadeIn>
                    <BlockStat
                        firstColumnTitle='OS'
                        store={StatStoreOs}
                    />
                </FadeIn>

                <FadeIn>
                    <BlockStat
                        firstColumnTitle='ISP'
                        store={StatStoreIsp}
                    />
                </FadeIn>

                <FadeIn>
                    <BlockStat
                        firstColumnTitle='Region'
                        store={StatStoreRegion}
                    />
                </FadeIn>

            </>
        );
    }

    /**
     *
     */
    return (
        <>
            <FadeIn>
                <Block>
                    <Header />
                </Block>
            </FadeIn>

            { pageContent() }

        </>
    );
}

const LinkDetailsPageObserver = observer(LinkDetailsPage);

export { LinkDetailsPageObserver as LinkDetailsPage }
