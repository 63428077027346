import React from 'react';
import styled from 'styled-components';

import { Link } from 'Elements/Link';
import { Centered } from 'Wrappers/Centered';

const BottomLinksContainer = styled(Centered)`

    margin: 20px auto;

    & > * {
        margin-left: 16px;
        margin-right: 16px;
    }
`;

const BottomLinks = (props) => {

    return (
        <BottomLinksContainer>
            <Link to="/about">About</Link>
            { /* <Link to="/contacts">Contacts</Link> */ }
        </BottomLinksContainer>
    );
}

export { BottomLinksContainer, BottomLinks };
