import React from 'react';

export const IconWhatsapp = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.313037 63.389781" version="1.1">
            <path
                transform="translate(34.759667,-111.19967)"
                d="m -34.759667,174.38359 4.291372,-15.95597 c -12.634962,-31.29355 8.801543,-47.93143 28.9138367,-47.20514 27.1759393,0.98137 35.9144123,29.47165 26.3790163,45.78987 -9.899322,16.94102 -29.6290009,19.87883 -43.207453,13.32548 l -16.046043,4.22901 c -0.181901,0.0657 -0.330729,-0.0167 -0.330729,-0.18325 z m 57.142629,-31.97804 c 0.121589,-27.85804 -35.704987,-32.47326 -47.242761,-14.18814 -5.761022,9.1301 -3.93903,23.41325 0.194149,29.40054 l -2.34883,9.23475 9.50644,-2.30816 C 6.8177703,174.94839 22.322873,156.1728 22.382962,142.40555 Z M 0.95908333,156.94587 c -3.09427293,-1.04686 -4.94876133,-1.91243 -7.15443383,-3.33927 -3.9206915,-2.53628 -9.7410435,-9.01582 -11.6028975,-12.91698 -1.521308,-3.18762 -1.723871,-6.21438 -0.587773,-8.78272 0.601295,-1.35933 1.896569,-2.98989 2.783275,-3.50373 0.730535,-0.42335 3.379692,-0.42967 3.925981,-0.009 0.236431,0.1819 1.108491,1.98081 1.9379105,3.99758 1.4906839,3.62465 1.5031775,3.67704 1.0857394,4.55241 -0.2322632,0.48706 -0.8434417,1.35173 -1.3581739,1.92147 -0.514733,0.56975 -0.935878,1.25964 -0.935878,1.5331 0,0.27345 0.772895,1.52701 1.7175449,2.78568 1.6004098,2.13242 3.0323904,3.52656 5.078617,4.94441 1.1437461,0.79251 4.47547472,2.42421 4.94994408,2.42421 0.21103162,0 1.07753682,-0.88526 1.92556602,-1.96724 0.8480293,-1.08198 1.765554,-2.04737 2.038948,-2.1453 0.3359044,-0.12034 1.7299649,0.42899 4.2994792,1.69421 3.7643198,1.85353 3.8024008,1.88116 3.8024008,2.75874 0,1.63512 -0.724312,3.45406 -1.777915,4.4648 -1.6581279,1.59067 -3.0677273,2.15617 -5.630418,2.25881 -1.9272118,0.0772 -2.5707049,-0.0188 -4.49791667,-0.6708 z"
            />
        </svg>
    )
}


