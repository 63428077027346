import React from 'react';
import styled from 'styled-components';
import { Link as ReactLink } from 'react-router-dom'

import { TextBasicSpan } from 'Elements/TextBasic';


const LinkSpan = styled(TextBasicSpan)`
    color: #00a8ff;
`;

const LinkReact = styled(ReactLink)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;
const LinkA = styled.a`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

const Link = (props) => {

    if (props.to && (props.to.substring(0, 7) === 'http://' || props.to.substring(0, 8) === 'https://' || props.to === '#')) {
        return (
            <LinkA {...props} href={props.to} target={props.newWindow ? "_blank" : null }>
                <LinkSpan>
                    {props.children}
                </LinkSpan>
            </LinkA>
        );
    }

    return (
        <LinkReact {...props} to={props.to || '#'}>
            <LinkSpan>
                {props.children}
            </LinkSpan>
        </LinkReact>
    );

}

export { Link, LinkSpan }