import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { LinkStore } from 'DataSource/Stores/LinkStore';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';
import { Collapsible } from "Wrappers/Collapsible";

import { Title } from 'Elements/Title';
import { ButtonDel } from 'Elements/ButtonDel';
import { ButtonClose } from 'Elements/ButtonClose';
import { TextField } from 'Elements/TextField';
import { ErrorMessage } from 'Elements/ErrorMessage';
import { ButtonBasicText } from 'Elements/ButtonBasicText';
import { ButtonNotRubberText } from "Elements/ButtonNotRubberText";

const BlockEditLink = (props) => {

    const {
        setEditMode,
        refresh,
        linkData,
        resultCallback,
    } = props;

    const [ linkTitle, stateSetLinkTitle ] = useState(linkData.title);
    const [ linkTail, stateSetLinkTail ] = useState(linkData.tail);
    const [ linkWeb, stateSetLinkWeb ] = useState(linkData.link_web);
    const [ linkIos, stateSetLinkIos ] = useState(linkData.link_ios);
    const [ linkAndroid, stateSetLinkAndroid ] = useState(linkData.link_android);
    const [ linkTrackPixel, stateSetLinkTrackPixel ] = useState(linkData.track_pixel);
    const [ errorMessage, stateSetErrorMessage ] = useState('');
    const [ redirectTo, stateSetRedirectTo ] = useState('');
    const [ blockExpanded, stateSetBlockExpanded ] = useState(true);


    const validate = () => {
        if ( ! linkTitle) {
            stateSetErrorMessage('link name is required');
            return false;
        }

        if ( ! linkWeb) {
            stateSetErrorMessage('web link is required');
            return false;
        }

        return true;
    }

    /**
     *
     */
    const onSubmitLinkUpdate = () => {

        stateSetErrorMessage('');

        if ( ! validate()) {
            return;
        }

        var data = {
            id: linkData.id,
            title: linkTitle,
            link_web: linkWeb,
        }

        if (linkTail && linkTail.trim()) {
            data.tail = linkTail;
        }

        if (linkIos && linkIos.trim()) {
            data.link_ios = linkIos;
        }

        if (linkAndroid && linkAndroid.trim()) {
            data.link_android = linkAndroid;
        }

        if (linkTrackPixel && linkTrackPixel.trim()) {
            data.track_pixel = linkTrackPixel;
        }

        LinkStore
            .update(data)
            .then( (response) => {
                refresh();

                if (resultCallback) {
                    resultCallback(response.data);
                }

                stateSetLinkTitle('');
                stateSetLinkTail('');
                stateSetLinkWeb('');
                stateSetLinkIos('');
                stateSetLinkAndroid('');
                stateSetLinkTrackPixel('');

                setEditMode(false);
            })
            .catch( (error, data) => {
                stateSetErrorMessage(error.response?.data?.message);
            });
    }

    /**
     *
     */
    const onLinkDelete = () => {

        if ( ! window.confirm('Delete this link?')) {
            return;
        }

        LinkStore
            .drop(linkData.id)
            .then( () => {
                stateSetRedirectTo(`/campaigns/${linkData.campaign_id}`);
            });
    }

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    const onExpandBlockStateChange = () => {
        stateSetBlockExpanded(!blockExpanded);
    };

    /**
     *
     */
    return (
        <Block>
            <Filled>
                <Title>
                    Change link params
                </Title>
                <ButtonNotRubberText onClick={ onExpandBlockStateChange } _width={'65px'}>
                    {blockExpanded ? 'More' : 'Less'}
                </ButtonNotRubberText>
                <ButtonDel onClick={ onLinkDelete } title="Delete link" />
                <ButtonClose onClick={() => setEditMode(false)} />
            </Filled>

            <Filled>
                <TextField placeholder="Enter link name" value={linkTitle} onChange={ e => stateSetLinkTitle(e.target.value) } />
            </Filled>

            <Filled>
                <TextField placeholder="Enter web link" value={linkWeb} onChange={ e => stateSetLinkWeb(e.target.value) } />
            </Filled>

            <Collapsible collapsed={blockExpanded}>
                <Filled>
                    <TextField placeholder="Enter custom link tail (optional)" value={linkTail} onChange={ e => stateSetLinkTail(e.target.value) } />
                    <ButtonDel onClick={ () =>  stateSetLinkTail('') } title="Delete custom link tail" />
                </Filled>

                <Filled>
                    <TextField placeholder="Enter iOS link (optional)" value={linkIos} onChange={ e => stateSetLinkIos(e.target.value) } />
                    <ButtonDel onClick={ () => stateSetLinkIos('') } title="Delete iOS link" />
                </Filled>

                <Filled>
                    <TextField placeholder="Enter Android link (optional)" value={linkAndroid} onChange={ e => stateSetLinkAndroid(e.target.value) } />
                    <ButtonDel onClick={ () => stateSetLinkAndroid('') } title="Delete Android link" />
                </Filled>

                <Filled>
                    <TextField placeholder="Enter track pixel (optional)" value={linkTrackPixel} onChange={ e => stateSetLinkTrackPixel(e.target.value) } />
                    <ButtonDel onClick={ () =>  stateSetLinkTrackPixel('')} title="Delete track pixel" />
                </Filled>

            </Collapsible>

            <Filled>
                { errorMessage &&
                    <ErrorMessage>
                        { errorMessage }
                    </ErrorMessage>
                }
            </Filled>

            <Filled>
                <ButtonBasicText onClick={onSubmitLinkUpdate}>
                    Update link
                </ButtonBasicText>
            </Filled>
        </Block>
    );
}

export { BlockEditLink }
