import React from 'react';

class TestPage extends React.Component {

    render() {
        /**
         *
         */
        return (
            <div style={{ padding: '10px' }}>
                <div style={{ border: '1px red dashed', padding: '10px' }}>
                    That content
                </div>
            </div>
        );
    }

}
export { TestPage }
