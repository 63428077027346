import styled from 'styled-components';

const FillAndCenter = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export { FillAndCenter }
