import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Block } from 'Wrappers/Block';
import { Table } from 'Elements/Table';
import { Link } from 'Elements/Link';
import { dateFromISO } from 'Utils/dateFromISO';


const BlockTable = (props) => {

    const [ orderBy, setOrderBy ] = useState({
        key: 'created_at',
        asc: false,
    });

    /**
     *
     */
    const setSortOrder = (key) => {

        setOrderBy({
            key: key,
            asc: orderBy.key === key ? ! orderBy.asc : true,
        });
    }

    /**
     * here we are compilind data for the campaign list
     */
    const makeTableContent = () => {

        var data = {
            header: {
                'created' : <Link onClick={() => setSortOrder('created_at')} to="#">Created</Link>,
                'name'    : <Link onClick={() => setSortOrder('name_text')} to="#">Campaign name</Link>,
                'today'   : 'Today',
                'total'   : 'Total',
            },
            content: []
        };

        var contentData = [];

        props.store.data.forEach(elem => {

            if (props.filterText) {
                if (elem['title'].toUpperCase().indexOf(props.filterText.toUpperCase()) < 0) {
                    return;
                }
            }

            contentData.push({
                'created'    : dateFromISO(elem['created_at']),
                'created_at' : elem['created_at'],
                'name_text'  : elem['title'],
                'name'       : <Link to={`/campaigns/${elem['id']}`}>{elem['title']}</Link>,
                'today'      : (elem['count_today_links'] ?? 0) + (elem['count_today_counters'] ?? 0),
                'total'      : (elem['count_total_links'] ?? 0) + (elem['count_total_counters'] ?? 0),
            });
        });

        contentData.sort((a, b) => {

            var valueA;
            var valueB;

            var orderKey = orderBy.key in a ? orderBy.key : Object.keys(a)[0];

            switch (orderKey) {
//                case 'created_at':
//                    valueA = a['created_at'].substr(6, 4) + a['created_at'].substr(3, 2) + a['created_at'].substr(0, 2);
//                    valueB = b['created_at'].substr(6, 4) + b['created_at'].substr(3, 2) + b['created_at'].substr(0, 2);
//                    break;

                default:
                    valueA = a[orderKey].toString().toUpperCase();
                    valueB = b[orderKey].toString().toUpperCase();
            }

            if (valueA > valueB) return orderBy.asc ? 1 : -1;
            if (valueA < valueB) return orderBy.asc ? -1 : 1;
            return 0;
        });

        data.content = contentData;

        return data;
    }

    /**
     *
     */
    return (
        <Block>
            <Table
                loading={props.store.loading}
                noDataText={ props.filterText ? 'Nothing found' : 'No campaigns yet' }
                data={makeTableContent()}
            />
        </Block>
    );
}

const BlockTableObserver = observer(BlockTable);

export { BlockTableObserver as BlockTable }
