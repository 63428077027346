/**
 *
 */

import { observable, action } from 'mobx';
import { requestPost } from 'DataSource/requestPost';

class CampaignStoreClass {

    baseUrl = '/api/campaigns';

    /**
     * индикатор состояния
     */
    @observable
    loading = false;


    /**
     * массив с загруженными элементами
     */
    @observable
    data = [];

    /**
     *
     */
    @action
    setLoadingState = (newValue) => {
        this.loading = !! newValue;
    }

    @action
    setData = (data) => {
        this.data = data;
    }

    /**
     *
     */
    load = (url, postData = {}) => {

        this.setLoadingState(true);
        this.setData([]);

        return requestPost(url, postData)
            .then(response => {

                if (Array.isArray(response.data)) {
                    this.setData(response.data);
                } else {
                    this.setData([response.data]);
                }
            })
            .finally(data => {
                this.setLoadingState(false);
                return data;
            })
            ;
    }

    all = () => {
        return this.load(`${this.baseUrl}/index`);

    }

    find = (id) => {
        return this.load(`${this.baseUrl}/get`, { id: id });
    }

    create = (data) => {

        const requestUrl = `${this.baseUrl}/create`;
        return requestPost(requestUrl, data);
    }

    update = (data) => {

        const requestUrl = `${this.baseUrl}/update`;
        return requestPost(requestUrl, data);
    }

    drop = (id) => {

        const requestUrl = `${this.baseUrl}/drop`;
        return requestPost(requestUrl, { id: id });
    }
    
}

const CampaignStore = new CampaignStoreClass();

export { CampaignStore }

