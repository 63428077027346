import React from 'react';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';

import { LinkInfo } from 'Blocks/LinkInfo';

import { Title } from 'Elements/Title';
import { ButtonClose } from 'Elements/ButtonClose';

const BlockNewLinkCreated = (props) => {

    const {
        linkData,
        close,
    } = props;

    /**
     *
     */
    return (

        <Block>

            <Filled>
                <Title>
                    New link was created
                </Title>
                <ButtonClose onClick={close} />
            </Filled>

            <LinkInfo title={linkData.title} tail={linkData.tail} />

        </Block>

    );
}

export { BlockNewLinkCreated }
