import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { observer } from 'mobx-react';

import { CampaignStore } from 'DataSource/Stores/CampaignStore';
import { LinkStore } from 'DataSource/Stores/LinkStore';
import { CounterStore } from "DataSource/Stores/CounterStore";

import { Header } from 'Components/Header';
import { DateFilter } from 'Components/DateFilter';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';
import { Row } from 'Wrappers/Row';

import { Title } from 'Elements/Title';
import { ButtonAdd } from 'Elements/ButtonAdd';
import { ButtonEdit } from 'Elements/ButtonEdit';
import { ButtonSearch } from 'Elements/ButtonSearch';
import { ButtonRefresh } from 'Elements/ButtonRefresh';
import { ButtonBack } from 'Elements/ButtonBack';
import { Loader } from 'Elements/Loader';

import { Search } from 'Blocks/Search';
import { BlockTable } from './BlockTable';
import { BlockEditCampaign } from './BlockEditCampaign';
import { BlockCreateLink } from './BlockCreateLink';
import { BlockNewLinkCreated } from './BlockNewLinkCreated';
import { BlockCreateTypeSelector } from './BlockCreateTypeSelector';
import { BlockCreateCounters } from "./BlockCreateCounters";
import { BlockNewCounterCreated } from "./BlockNewCounterCreated";
import {subDays} from "date-fns";


const LinksPage = (props) => {

    const [ editCampaignMode, stateSetEditCampaignMode ] = useState(false);

    const [ searchMode, stateSetSearchMode ] = useState(false);
    const [ searchText, stateSetSearchText ] = useState('');
    const [ createMode, stateSetCreateMode ] = useState(false);
    const [ newLinkData, stateSetNewLinkData ] = useState(null);
    const [ createLinkMode, stateSetCreateLinkMode ] = useState(false);
    const [ createCountersMode, stateSetCreateCountersMode ] = useState(false);
    const [ newCountersData, stateSetNewCountersData ] = useState(null);
    const [ startDate, stateSetStartDate ] = useState( subDays(new Date(), 7) );
    const [ endDate, stateSetEndDate ] = useState( new Date() );

    const campaignId = useParams().id;

    /**
     *  Установка режима поиска
     */
    const setSearchMode = (mode) => {

        const newMode = typeof mode !== 'undefined' ? !! mode : ! searchMode;
        stateSetSearchMode(newMode);

        if (!newMode) {
            stateSetSearchText('');
        }
    };

    /**
     *  Установка режима редактирования
     */
    const setEditMode = (mode) => {
        stateSetEditCampaignMode(typeof mode != 'undefined' ? !! mode : ! editCampaignMode);
    };

    /**
     *  Установка режима создания
     */
    const setCreateMode = (mode) => {
        stateSetCreateMode(typeof mode != 'undefined' ? !! mode : ! createMode);
    };

    /**
     *  Обновление данных для конкретной компании
     */
    const onRefresh = () => {
        CampaignStore.find(campaignId);
        return true;
    };

    const onRefreshStat = () => {
        onRefreshLinks();
        onRefreshCounters()
    }

    const onRefreshLinks = () => {
        LinkStore.forCampaignWithStatFilters(campaignId, startDate, endDate);
    }

    const onRefreshCounters = () => {
        CounterStore.forCampaignWithStatFilters(campaignId, startDate, endDate);
    }

    /**
     *  Коллбек с ответом от сервера, после создания линка
     */
    const onLinkCreated = (response) => {
        stateSetNewLinkData({
            title: response.title,
            tail: response.tail,
        });
    };

    /**
     *  Коллбек с ответом от сервера, после создания каунтеров
     */
    const onCountersCreated = (response) => {
        stateSetNewCountersData([...response]);
    };

    /**
     *  Установка модификации создания линка
     */
    const setCreateLinkMode = (mode) => {
        stateSetCreateLinkMode(typeof mode != 'undefined' ? !! mode : ! createLinkMode);
        stateSetCreateCountersMode(false);
    };

    /**
     *  Установка модификации создания каунтера
     */
    const setCreateCounterMode = (mode) => {
        stateSetCreateCountersMode(typeof mode != 'undefined' ? !! mode : ! createCountersMode);
        stateSetCreateLinkMode(false);
    };

    /**
     *
     */
    useEffect(
        () => {
            console.log('links page init');
            onRefresh();
        },
        []
    );

    /**
     * Еффект при обновлении фильтрации
     */
    useEffect(
        () => {
            onRefreshStat();
        },
        [startDate, endDate]
    );

    /**
     *  Проверка текущего мода, и отключение мода редактирования если не один не активен
     */
    useEffect(
        () => {
            !createLinkMode && !createCountersMode && setCreateMode(false);
        },
        [createLinkMode, createCountersMode]
    );

    /**
     *
     */
    return (
        <>

            <FadeIn>
                <Block>
                    <Header />
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Filled>
                        <Title>
                            { CampaignStore.data && CampaignStore.data.length ? CampaignStore.data[0].title : <Loader height="40px" /> }
                        </Title>
                        <ButtonAdd onClick={setCreateMode} title="Add" />
                        <ButtonEdit onClick={setEditMode} title="Edit campaign" />
                        <ButtonSearch onClick={ () => setSearchMode(!searchMode) } title="Quick filter" />
                        <ButtonRefresh onClick={() => {
                            onRefresh();
                            onRefreshStat();
                        }} title="Refresh" />
                        <ButtonBack link="/campaigns" title="Back to campaigns" />
                    </Filled>
                </Block>
            </FadeIn>

            { editCampaignMode &&
                <FadeIn>
                    <BlockEditCampaign
                        CampaignStore={CampaignStore}
                        refresh={ () => CampaignStore.find(campaignId) }
                        setEditMode={ stateSetEditCampaignMode }
                        campaignId={campaignId}
                    />
                </FadeIn>
            }

            { createMode &&
                <FadeIn>
                    <BlockCreateTypeSelector
                        onLinkClick={setCreateLinkMode}
                        onCounterClick={setCreateCounterMode}
                        onRichmediaClick={ () => { } } // todo Раздизеблить кнопки и добавить обработчик, после уточнения функционала
                        onSurveyClick={ () => { } }
                    />
                </FadeIn>
            }

            { createLinkMode &&
                <FadeIn>
                    <BlockCreateLink
                        setCreateLinkMode={setCreateLinkMode}
                        campaignId={campaignId}
                        refresh={() => {
                            onRefresh();
                            onRefreshLinks();
                        }}
                        resultCallback={onLinkCreated}
                    />
                </FadeIn>
            }

            { createCountersMode &&
                <FadeIn>
                    <BlockCreateCounters
                        setCreateCountersMode={setCreateCounterMode}
                        campaignId={campaignId}
                        refresh={() => {
                            onRefresh();
                            onRefreshCounters();
                        }}
                        resultCallback={onCountersCreated}
                    />
                </FadeIn>
            }

            { searchMode &&
                <FadeIn>
                    <Search
                        searchText={searchText}
                        placeholder="Enter name"
                        setSearchMode={setSearchMode}
                        setSearchText={text => stateSetSearchText(text)}
                    />
                </FadeIn>
            }

            { newLinkData &&
                <FadeIn>
                    <BlockNewLinkCreated
                        linkData={newLinkData}
                        close={() => stateSetNewLinkData(null)}
                    />
                </FadeIn>
            }

            { newCountersData &&
                <FadeIn>
                    <BlockNewCounterCreated
                        countersData={newCountersData}
                        close={() => stateSetNewCountersData(null)}
                    />
                </FadeIn>
            }

            <FadeIn>
                <Block>
                    <Row>
                        <DateFilter
                            title='Statistics'
                            dateFrom={startDate}
                            setDateFrom={stateSetStartDate}
                            dateTo={endDate}
                            setDateTo={stateSetEndDate}
                        />
                    </Row>
                </Block>
            </FadeIn>

            <FadeIn>
                <BlockTable
                    linkStore={LinkStore}
                    counterStore={CounterStore}
                    filterText={searchText}
                />
            </FadeIn>

        </>
    );
};

const LinksPageObserver = observer(LinksPage);

export { LinksPageObserver as LinksPage }
