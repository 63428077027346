import React from 'react';
import styled from 'styled-components';

import { Squared } from 'Wrappers/Squared';
import { Details } from 'Wrappers/Details';

import { Link } from 'Elements/Link';
import { TextBasic } from 'Elements/TextBasic';
import { ButtonBasicText } from 'Elements/ButtonBasicText';

const PartnerContainer = styled.div`

    display: flex;
    flex-direction: row;

    .partners-logo {
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C4C4C4;
        padding: 32px;
        border-radius: 10px;
        margin-right: 32px;
    }

    .partners-logo svg {
        width: 100%;
    }

    .partners-text {
    }
        .partners-text > div {
            margin-bottom: 10px;
        }
`;

const Partner = (props) => {

    return (
        <PartnerContainer>
            <Squared>
                <div className="partners-logo">
                    {props.image}
                </div>
            </Squared>
            {/*<div className="partners-text">*/}
            <Details>
                <div>
                    <TextBasic>
                        {props.text}
                    </TextBasic>
                </div>
                <div>
                    <Link to={props.link} newWindow>
                        {props.linkText}
                    </Link>
                </div>
                <div style={{ marginLeft: '-6px' }}>
                    <ButtonBasicText link={props.link} newWindow>
                        Перейти на сайт
                    </ButtonBasicText>
                </div>
            </Details>
            {/*</div>*/}
        </PartnerContainer>
    );
}

export { Partner };
