import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { Logo } from 'Elements/Logo';
import { TextField } from 'Elements/TextField';
import { ButtonAdd } from 'Elements/ButtonAdd';
import { ButtonBasicText } from 'Elements/ButtonBasicText';
import { Block } from 'Wrappers/Block';

import { Filled } from 'Wrappers/Filled';
import { Centered } from 'Wrappers/Centered';
import { ErrorMessage } from 'Elements/ErrorMessage';

import { AuthStore } from 'DataSource/Stores/AuthStore.js';


const LoginPage = observer((props) => {

    const [ registerMode, setRegisterMode ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');

    const onSetEmail = (event) => {
        setEmail(event.target.value);
    }

    const onSetPassword = (event) => {
        setPassword(event.target.value);
    }

    const login = () => {
        AuthStore.login(email, password);
    }

    const useKeyboard = (event) => {
        if (event.key === 'Enter') {
            login();
        }
    }

    return (
        <>
            <Block _width="600px">

                <Centered>
                    <Logo _width="450px" _marginBottom="8px" />
                </Centered>

                <Filled>
                    <TextField placeholder="Enter email" value={email} onChange={onSetEmail} onKeyPress={useKeyboard} />
                </Filled>

                <Filled>
                    <TextField type="password" placeholder="Enter password" value={password} onChange={onSetPassword} onKeyPress={useKeyboard} />
                </Filled>

                { registerMode &&
                    <Filled>
                        <TextField placeholder="Enter code" />
                    </Filled>
                }

                <Filled>
                    <ErrorMessage>
                        { AuthStore.error }
                    </ErrorMessage>
                </Filled>

                <Filled>
                    { registerMode &&
                        <ButtonAdd />
                    }
                    <ButtonBasicText onClick={login}>
                        log in
                    </ButtonBasicText>
                </Filled>


            </Block>

        </>
    );
})

export { LoginPage }
