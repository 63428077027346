import React, { useState } from 'react';

import { LinkStore } from 'DataSource/Stores/LinkStore';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';
import { Collapsible } from "Wrappers/Collapsible";

import { Title } from 'Elements/Title';
import { ButtonClose } from 'Elements/ButtonClose';
import { ButtonDel } from 'Elements/ButtonDel';
import { TextField } from 'Elements/TextField';
import { ErrorMessage } from 'Elements/ErrorMessage';
import { ButtonBasicText } from 'Elements/ButtonBasicText';
import { ButtonNotRubberText } from "Elements/ButtonNotRubberText";

const BlockCreateLink = (props) => {

    const {
        setCreateLinkMode,
        campaignId,
        refresh,
        resultCallback,
    } = props;

    const [ linkTitle, stateSetLinkTitle ] = useState('');
    const [ linkTail, stateSetLinkTail ] = useState('');
    const [ linkWeb, stateSetLinkWeb ] = useState('');
    const [ linkIos, stateSetLinkIos ] = useState('');
    const [ linkAndroid, stateSetLinkAndroid ] = useState('');
    const [ linkTrackPixel, stateSetLinkTrackPixel ] = useState('');
    const [ errorMessage, stateSetErrorMessage ] = useState('');
    const [ blockExpanded, stateSetBlockExpanded ] = useState(true);


    const validate = () => {
        if ( ! linkTitle) {
            stateSetErrorMessage('link name is required');
            return false;
        }

        if ( ! linkWeb) {
            stateSetErrorMessage('web link is required');
            return false;
        }

        return true;
    }

    /**
     *
     */
    const onSubmitLinkCreate = () => {

        stateSetErrorMessage('');

        if ( ! validate()) {
            return;
        }

        var data = {
            campaign_id: campaignId,
            title: linkTitle,
            link_web: linkWeb,
        }

        if (linkTail.trim()) {
            data.tail = linkTail;
        }

        if (linkIos.trim()) {
            data.link_ios = linkIos;
        }

        if (linkAndroid.trim()) {
            data.link_android = linkAndroid;
        }

        if (linkTrackPixel.trim()) {
            data.track_pixel = linkTrackPixel;
        }

        LinkStore
            .create(data)
            .then( (response) => {
                refresh();

                if (resultCallback) {
                    resultCallback(response.data);
                }

                stateSetLinkTitle('');
                stateSetLinkTail('');
                stateSetLinkWeb('');
                stateSetLinkIos('');
                stateSetLinkAndroid('');
                stateSetLinkTrackPixel('');

                setCreateLinkMode(false);
            })
            .catch( (error, data) => {
                stateSetErrorMessage(error.response?.data?.message);
            });
    };

    const onExpandBlockStateChange = () => {
        stateSetBlockExpanded(!blockExpanded);
    };

    /**
     *
     */
    return (
        <Block>
            <Filled>
                <Title>
                    Create a new link
                </Title>
                <ButtonNotRubberText onClick={ onExpandBlockStateChange } _width={'65px'}>
                    {blockExpanded ? 'More' : 'Less'}
                </ButtonNotRubberText>
                <ButtonClose onClick={() => setCreateLinkMode(false)} />
            </Filled>

            <Filled>
                <TextField placeholder="Enter link name" value={linkTitle} onChange={ e => stateSetLinkTitle(e.target.value) } />
            </Filled>

            <Filled>
                <TextField placeholder="Enter web link" value={linkWeb} onChange={ e => stateSetLinkWeb(e.target.value) } />
            </Filled>

            <Collapsible collapsed={blockExpanded}>
                <Filled>
                    <TextField placeholder="Enter custom link tail (optional)" value={linkTail} onChange={ e => stateSetLinkTail(e.target.value) } />
                    <ButtonDel onClick={ () =>  stateSetLinkTail('') } title="Delete custom link tail" />
                </Filled>

                <Filled>
                    <TextField placeholder="Enter iOS link (optional)" value={linkIos} onChange={ e => stateSetLinkIos(e.target.value) } />
                    <ButtonDel onClick={ () => stateSetLinkIos('') } title="Delete iOS link" />
                </Filled>

                <Filled>
                    <TextField placeholder="Enter Android link (optional)" value={linkAndroid} onChange={ e => stateSetLinkAndroid(e.target.value) } />
                    <ButtonDel onClick={ () => stateSetLinkAndroid('') } title="Delete Android link" />
                </Filled>

                <Filled>
                    <TextField placeholder="Enter track pixel (optional)" value={linkTrackPixel} onChange={ e => stateSetLinkTrackPixel(e.target.value) } />
                    <ButtonDel onClick={ () =>  stateSetLinkTrackPixel('')} title="Delete track pixel" />
                </Filled>
            </Collapsible>

            <Filled>
                { errorMessage &&
                    <ErrorMessage>
                        { errorMessage }
                    </ErrorMessage>
                }
            </Filled>

            <Filled>
                <ButtonBasicText onClick={onSubmitLinkCreate}>
                    Create link
                </ButtonBasicText>
            </Filled>
        </Block>
    );
}

export { BlockCreateLink }
