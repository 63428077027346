import { observable, action } from 'mobx';
import { requestPost } from 'DataSource/requestPost';

class CounterStoreClass {

    baseUrl = '/api/counters';
    countersDefaultValue = [{title: ''}];

    /**
     * индикатор состояния
     */
    @observable
    loading = false;


    /**
     * массив с загруженными элементами
     */
    @observable
    data = [];

    /**
     * Массив каунтеров формирующийся во время создания
     */
    @observable
    counters = this.countersDefaultValue;

    /**
     *
     */
    @action
    setLoadingState = (newValue) => {
        this.loading = !! newValue;
    }

    @action
    setData = (data) => {
        this.data = data;
    }

    @action
    setCounters = (counters) => {
        this.counters = counters
    }

    @action
    clearCounters = () => {
        this.counters = this.countersDefaultValue;;
    }

    @action
    removeCounter = (index) => {
        this.counters.splice(index, 1);
    }

    @action
    addCounter = () => {
        this.counters.push({title: ''});
    }

    @action
    setCounterValue = (index, value) => {
        this.counters[index].title = value;
    }

    /**
     *
     */
    load = (url, postData = {}) => {

        this.setLoadingState(true);
        this.setData([]);

        return requestPost(url, postData)
            .then(response => {
                if (Array.isArray(response.data)) {
                    this.setData(response.data);
                } else {
                    this.setData([response.data]);
                }
            })
            .finally(data => {
                this.setLoadingState(false);
                return data;
            })
            ;
    };

    forCampaign = (campaignId) => {
        return this.load(`${this.baseUrl}/index`, { campaign_id: campaignId });

    }

    forCampaignWithStatFilters = (campaignId, statFrom, statTo) => {
        return this.load(`${this.baseUrl}/index`, {
            campaign_id: campaignId,
            stat_from: statFrom,
            stat_to: statTo
        });
    }

    forCounter = (counterId) => {
        return this.load(`${this.baseUrl}/get`, { id: counterId });
    }

    all = () => {
        return this.load(`${this.baseUrl}/index`);

    }

    find = (id) => {
        return this.load(`${this.baseUrl}/get`, { id: id });
    }

    create = (data) => {

        const requestUrl = `${this.baseUrl}/create`;
        return requestPost(requestUrl, data);
    }

    createMass = (data) => {

        const requestUrl = `${this.baseUrl}/create-mass`;
        return requestPost(requestUrl, data);
    }

    update = (data) => {

        const requestUrl = `${this.baseUrl}/update`;
        return requestPost(requestUrl, data);
    }

    drop = (id) => {

        const requestUrl = `${this.baseUrl}/drop`;
        return requestPost(requestUrl, { id: id });
    }

}

const CounterStore = new CounterStoreClass();

export { CounterStore }

