import { getAxios } from './axios';
import { AuthStore } from 'DataSource/Stores/AuthStore.js';

export async function requestPost(uri, data, config = {}) {

    var headers = {};

    const token = AuthStore.token;
    if (token) {
        headers['Authorization'] = 'Bearer ' + token;
    }

    const fullConfig = {
        ...config,
        headers: {
            ...headers,
            ...config.headers,
        },
    };

    return getAxios().post(uri, data, fullConfig);

}
