import React from 'react';
import styled from 'styled-components';

import { TextBasicSpan } from 'Elements/TextBasic';

const ErrorMessageSpan = styled(TextBasicSpan)`
    display: block;
    text-align: center;
`;

const ErrorMessage = (props) => {

    return (
        <ErrorMessageSpan>
            {props.children}
        </ErrorMessageSpan>
    );

}

export { ErrorMessage }