import React from 'react';
import FadeIn from 'react-fade-in';

import { Header } from 'Components/Header';
import { Block } from 'Wrappers/Block';
import { Title } from 'Elements/Title';
import { TextBasic } from 'Elements/TextBasic';
import { ButtonBasicText } from 'Elements/ButtonBasicText';

const OuchPage = (props) => {

    /**
     *
     */
    return (
        <>
            <FadeIn>
                <Block>
                    <Header />
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Title>
                        Ouch!
                    </Title>
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <TextBasic>
                        <p>
                            Something wrong in a world today.
                        </p>
                        <p>
                            We're already working on it.
                        </p>
                        <p>
                            Please, try again and choose the right way!
                        </p>
                    </TextBasic>
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <ButtonBasicText link="/">
                        Start over!
                    </ButtonBasicText>
                </Block>
            </FadeIn>

        </>
    );
}

export { OuchPage }
