import React from 'react';
import styled from 'styled-components';

const DEFAULT_SIZE = '113px';

const SquaredDiv = styled.div`
    position: relative;
    width: ${ props => props.size ? props.size : DEFAULT_SIZE};
    height: ${ props => props.size ? props.size : DEFAULT_SIZE};
    flex-basis: ${ props => props.size ? props.size : DEFAULT_SIZE};
    flex-shrink: 0;
    flex-grow: 0;
`;

const Squared = (props) => {
    return (
        <SquaredDiv {...props}>
            {props.children}
        </SquaredDiv>
    );
}

export { Squared }
