import styled from 'styled-components';

const Filled = styled.div`
    width: 100%;
    display: flex;
    justify-content: stretch;
    align-items: center;

    & > * {
        flex-basis: 100%;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & > *:first-child {
        margin-left: 0;
    }

    & > *:last-child {
        margin-right: 0;
    }
`;

export { Filled }
