import React from 'react';
import { ButtonBasicIcon } from 'Elements/ButtonBasicIcon';

const ButtonSave = (props) => {

    return (
        <ButtonBasicIcon {...props}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.875 4C6.875 3.448 7.323 3 7.875 3C8.427 3 8.875 3.448 8.875 4C8.875 4.552 8.427 5 7.875 5C7.323 5 6.875 4.552 6.875 4ZM18 17C18 17.552 17.552 18 17 18H16V14C16 12.895 15.105 12 14 12H6C4.895 12 4 12.895 4 14V18H3C2.448 18 2 17.552 2 17V7.044C2 6.911 2.053 6.784 2.146 6.691L4 4.837V6C4 7.105 4.895 8 6 8H14C15.105 8 16 7.105 16 6V2H17C17.552 2 18 2.448 18 3V17ZM14 18H6V15C6 14.448 6.448 14 7 14H13C13.552 14 14 14.448 14 15V18ZM6 2.837L6.837 2H14V5C14 5.552 13.552 6 13 6H7C6.448 6 6 5.552 6 5V2.837ZM18 0H6.414C6.149 0 5.895 0.105 5.707 0.293L0.293 5.707C0.105 5.895 0 6.149 0 6.414V18C0 19.105 0.895 20 2 20H18C19.105 20 20 19.105 20 18V2C20 0.895 19.105 0 18 0Z" />
            </svg>
        </ButtonBasicIcon>
    );
}

export { ButtonSave }


