import React from 'react';

export const IconLinkedin = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.247749 41.776329" version="1.1">
          <path d="m 8.49675,12.67216 h -7.9375 v 29.10417 h 7.9375 z" />
          <path d="m 21.725916,33.88685 c 0,-8.6121 0.160441,-9.83811 1.495015,-11.42416 2.053236,-2.44013 6.033003,-2.43387 7.774781,0.0122 1.189141,1.67 1.313538,2.73765 1.313538,11.27367 v 8.02775 h 7.9375 v -9.75347 c 0,-5.44521 -0.117766,-10.37444 -0.266608,-11.15929 -0.633891,-3.3425 -2.434402,-5.81231 -5.246407,-7.19666 -1.617617,-0.79635 -1.944989,-0.86119 -4.271004,-0.84597 -3.345929,0.0219 -5.07441,0.68123 -7.1969,2.74529 l -1.539915,1.49752 v -4.39159 h -7.9375 v 29.10417 h 7.9375 z" />
          <path d="m 6.296713,8.95116 c 3.533246,-1.33495 3.705938,-6.76022 0.268158,-8.42442 -1.450812,-0.70232 -2.622931,-0.70232 -4.073742,0 -3.347881,1.62068 -3.315026,6.83564 0.0525,8.33234 0.966469,0.42955 2.744445,0.47317 3.753088,0.0921 z" />
        </svg>
    );
}


