import React from 'react';

const LogoIvi = (props) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 28">
            <g fill="#e0003e">
                <path d="m7.24138,22.96l0,-7.75158l-6.55172,3.86105l6.55172,3.89053z"/>
                <path d="m0.05747,27.94105l6.55173,-3.86105l-6.55173,-3.89053l0,7.75158z"/>
                <path d="m11.35057,10.90526l3.79311,6.72l3.76437,-6.72l-7.55748,0z"/>
                <path d="m16.2069,19.57053l3.7931,6.72l3.76437,-6.72l-7.55747,0z"/>
                <path d="m21.06322,26.93895l7.58621,0l-3.79311,-6.72l-3.7931,6.72z"/>
                <path d="m0.05747,17.94947l0,0.02948l6.55173,-3.89053l-6.55173,-3.89052l0,7.75157l0,0z"/>
                <path d="m0.05747,7.98737l0,0l6.55173,-3.86105l0,-0.02948l-6.55173,-3.86105l0,7.75159l0,0l0,-0.00001z"/>
                <path d="m42.81609,17.94947l0,0.02948l6.55173,-3.89053l-6.55173,-3.89052l0,7.75157l0,0z"/>
                <path d="m42.81609,7.98737l0,0l6.55173,-3.86105l0,-0.02948l-6.55173,-3.86105l0,7.75159l0,0l0,-0.00001z"/>
                <path d="m34.5977,17.62526l3.7931,-6.72l-7.5862,0l3.79311,6.72l-0.00001,0z"/>
                <path d="m29.71264,11.55368l-3.76436,6.72l7.55746,0l-3.79311,-6.72l0.00001,0z"/>
                <path d="m29.74138,26.29053l3.76437,-6.72l-7.55746,0l3.7931,6.72l-0.00001,0z"/>
                <path d="m43.44828,19.06947l6.55172,3.89053l0,-7.75158l-6.55172,3.86105z"/>
                <path d="m42.81609,27.94105l6.55173,-3.86105l-6.55173,-3.89053l0,7.75158z"/>
                <path d="m23.7931,18.27368l-3.7931,-6.72l-3.76437,6.72l7.55747,0z"/>
            </g>
        </svg>
    );
}

export { LogoIvi }

