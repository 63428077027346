import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { CounterStore } from 'DataSource/Stores/CounterStore';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';

import { Title } from 'Elements/Title';
import { ButtonDel } from 'Elements/ButtonDel';
import { ButtonClose } from 'Elements/ButtonClose';
import { TextField } from 'Elements/TextField';
import { ErrorMessage } from 'Elements/ErrorMessage';
import { ButtonBasicText } from 'Elements/ButtonBasicText';


const BlockEditCounter= (props) => {

    const {
        setEditMode,
        refresh,
        counterData,
        resultCallback,
    } = props;

    const [ counterTitle, stateSetCounterTitle ] = useState(counterData.title);
    const [ errorMessage, stateSetErrorMessage ] = useState('');
    const [ redirectTo, stateSetRedirectTo ] = useState('');


    const validate = () => {
        if ( ! counterTitle) {
            stateSetErrorMessage('counter name is required');
            return false;
        }

        return true;
    };

    /**
     *
     */
    const onSubmitCounterUpdate = () => {

        stateSetErrorMessage('');

        if ( ! validate()) {
            return;
        }

        var data = {
            id: counterData.id,
            title: counterTitle,
        };

        CounterStore
            .update(data)
            .then( (response) => {
                refresh();

                if (resultCallback) {
                    resultCallback(response.data);
                }

                stateSetCounterTitle('');

                setEditMode(false);
            })
            .catch( (error, data) => {
                stateSetErrorMessage(error.response?.data?.message);
            });
    };

    /**
     *
     */
    const onCounterDelete = () => {

        if ( ! window.confirm('Delete this counter?')) {
            return;
        }

        CounterStore
            .drop(counterData.id)
            .then( () => {
                stateSetRedirectTo(`/campaigns/${counterData.campaign_id}`);
            });
    };

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    /**
     *
     */
    return (
        <Block>
            <Filled>
                <Title>
                    Change counter params
                </Title>
                <ButtonDel onClick={ onCounterDelete } title="Delete link" />
                <ButtonClose onClick={() => setEditMode(false)} />
            </Filled>

            <Filled>
                <TextField placeholder="Enter new name of counter" value={counterTitle} onChange={ e => stateSetCounterTitle(e.target.value) } />
            </Filled>

            <Filled>
                { errorMessage &&
                <ErrorMessage>
                    { errorMessage }
                </ErrorMessage>
                }
            </Filled>

            <Filled>
                <ButtonBasicText onClick={onSubmitCounterUpdate}>
                    Update counter
                </ButtonBasicText>
            </Filled>
        </Block>
    );
};

export { BlockEditCounter }
