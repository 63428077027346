import React from 'react';
import styled from 'styled-components';

import { ButtonBasicText } from 'Elements/ButtonBasicText';
import { ButtonAdd } from 'Elements/ButtonAdd';
import { ButtonClose } from 'Elements/ButtonClose';
import { ButtonEdit } from 'Elements/ButtonEdit';
import { ButtonRefresh } from 'Elements/ButtonRefresh';
import { ButtonSearch } from 'Elements/ButtonSearch';
import { ButtonLogout } from 'Elements/ButtonLogout';
import { ButtonSave } from 'Elements/ButtonSave';
import { ButtonDel } from 'Elements/ButtonDel';
import { ButtonBack } from 'Elements/ButtonBack';
import { TextBasic } from 'Elements/TextBasic';
import { Link } from 'Elements/Link';
import { Title } from 'Elements/Title';
import { TextField } from 'Elements/TextField';
import { ErrorMessage } from 'Elements/ErrorMessage';
import { Logo } from 'Elements/Logo';
import { Loader } from 'Elements/Loader';


import { Block } from 'Wrappers/Block';
import { Centered } from 'Wrappers/Centered';
import { Filled } from 'Wrappers/Filled';
import { Row } from 'Wrappers/Row';

import { DateFilter } from 'Components/DateFilter';
import { BlockTitle } from 'Components/BlockTitle';
import { Header } from 'Components/Header';

const MarginContent = styled.div`
    & > * {
        margin-bottom: 24px;
    }
`;

const Everything = (props) => {

    return (
        <MarginContent>

            <Row>
                <Title>
                    Elements
                </Title>
            </Row>

            <Row>
                <Row>
                    <TextBasic>
                        &lt;Logo /&gt;. Accepts "_width" prop.
                    </TextBasic>
                </Row>
                <Block>
                    <Logo _width="400px" />
                </Block>
            </Row>

            <Row>
                <Row>
                    <TextBasic>
                        Icon buttons, they use &lt;ButtonBasic /&gt; as parent component:
                    </TextBasic>
                </Row>
                <Block>
                    <ButtonAdd />
                    <ButtonClose />
                    <ButtonEdit />
                    <ButtonRefresh />
                    <ButtonSearch />
                    <ButtonLogout />
                    <ButtonSave />
                    <ButtonDel />
                    <ButtonBack />
                </Block>
            </Row>

            <Row>
                <Row>
                    <TextBasic>
                        Text buttons, with auto width or set with "_width":
                    </TextBasic>
                </Row>
                <Block>
                    <ButtonBasicText _width='460px'>
                        Fixed width button
                    </ButtonBasicText>

                    <ButtonBasicText>
                        button
                    </ButtonBasicText>
                </Block>
                <Row>
                    <TextBasic>
                        Buttons also may have link: &lt;ButtonBasic link="home" /&gt;
                    </TextBasic>
                </Row>
            </Row>

            <Row>
                <Row>
                    <TextBasic>
                        Text elements:
                    </TextBasic>
                </Row>
                <Block>
                    <Row>
                        <Title>
                            Title
                        </Title>
                    </Row>
                    <Row>
                        <TextBasic>
                            TextBasic
                        </TextBasic>
                    </Row>
                    <Row>
                        <ErrorMessage>
                            ErrorMessage
                        </ErrorMessage>
                    </Row>
                    <Row>
                        <TextField _width="600px" value="TextField" onChange={() => 0} />
                    </Row>
                    <Row>
                        <Link>
                            Link
                        </Link>
                    </Row>
                </Block>
            </Row>

            <Row>
                <Row>
                    <TextBasic>
                        Finally, we have a &lt;Loader /&gt; animation here:
                    </TextBasic>
                </Row>
                <Block>
                    <Loader />
                </Block>
            </Row>



            <Row>
                <Title>
                    Wrappers
                </Title>
            </Row>

            <Row>
                <TextBasic>
                    Block
                </TextBasic>
                <Block>
                    <TextBasic>
                        &lt;Block /&gt; with some background and paddings
                    </TextBasic>
                </Block>
            </Row>

            <Row>
                <TextBasic>
                    Row
                </TextBasic>
                <Row style={{ border: "1px red dashed" }}>
                    <TextBasic>
                        Just a &lt;Row /&gt; which have "display: block" behavior, border is not included ;-)
                    </TextBasic>
                </Row>
            </Row>

            <Row>
                <TextBasic>
                    Filled
                </TextBasic>
                <Filled>
                    <TextBasic>
                        This &lt;Filled/&gt; block
                    </TextBasic>
                    <TextBasic>
                         stretches its content to full width
                    </TextBasic>
                </Filled>
            </Row>

            <Row>
                <TextBasic>
                    Centered
                </TextBasic>
                <Centered>
                    <TextBasic>
                        Simply centered content
                    </TextBasic>
                </Centered>
            </Row>



            <Row>
                <Title>
                    Components
                </Title>
            </Row>

            <Row>
                <TextBasic>
                    Header
                </TextBasic>
                <Block>
                    <Header />
                </Block>

                <TextBasic>
                    DateFilter
                </TextBasic>
                <Block>
                    <DateFilter />
                </Block>
            </Row>

            <Row>
                <TextBasic>
                    Block title, not a component, just fir example
                </TextBasic>
                <Block>
                    <BlockTitle>
                        <Title>
                            Block header
                        </Title>
                        <ButtonSearch />
                        <ButtonLogout />
                        <ButtonSave />
                        <ButtonDel />
                        <ButtonBack />
                    </BlockTitle>
                </Block>
            </Row>




            <Row>
                <Title>
                    Blocks
                </Title>
            </Row>


            <Row>
                <TextBasic>
                    That links at the most bottom is &lt;BottomLinks /&gt;
                </TextBasic>
            </Row>


        </MarginContent>
    );
}

export { Everything }