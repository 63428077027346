import React from 'react';

export const IconVk = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-50 -50 588 370">
            <path d="M 526.4,21.7 C 530.1,9.2 526.4,0 508.6,0 h -58.9 c -15,0 -21.9,7.9 -25.6,16.7 0,0 -30,73.1 -72.4,120.5 -13.7,13.7 -20,18.1 -27.5,18.1 -3.7,0 -9.4,-4.4 -9.4,-16.9 V 21.7 C 314.8,6.7 310.6,0 298.2,0 h -92.6 c -9.4,0 -15,7 -15,13.5 0,14.2 21.2,17.5 23.4,57.5 v 86.8 c 0,19 -3.4,22.5 -10.9,22.5 -20,0 -68.6,-73.4 -97.4,-157.4 C 99.9,6.6 94.2,0 79.1,0 H 20.2 C 3.4,0 0,7.9 0,16.7 0,32.3 20,109.8 93.1,212.2 141.8,282.1 210.4,320 272.8,320 c 37.5,0 42.1,-8.4 42.1,-22.9 0,-66.8 -3.4,-73.1 15.4,-73.1 8.7,0 23.7,4.4 58.7,38.1 40,40 46.6,57.9 69,57.9 h 58.9 c 16.8,0 25.3,-8.4 20.4,-25 -11.2,-34.9 -86.9,-106.7 -90.3,-111.5 -8.7,-11.2 -6.2,-16.2 0,-26.2 0.1,-0.1 72,-101.3 79.4,-135.6 z" />
        </svg>
    )
}


