import React from 'react';
import styled from 'styled-components';

const LoaderContainer = styled.div`

    height: ${ props => props.height || '64px' };

    & > svg {
        height: 100%;
        fill: #00a8ff;
        enable-background:new 0 0 50 50;
    }
`;

const Loader = (props) => {

    return (
        <LoaderContainer title="loading..." {...props} >
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 30">
                <rect x="0" y="13" width="7" height="5" rx="2" ry="2" >
                    <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite" />
                </rect>
                <rect x="10" y="13" width="7" height="5" rx="2" ry="2" >
                    <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                </rect>
                <rect x="20" y="13" width="7" height="5" rx="2" ry="2" >
                    <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                </rect>
            </svg>
        </LoaderContainer>
    );
}

export { Loader }