import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { Block } from 'Wrappers/Block';
import { Table } from 'Elements/Table';
import { Link } from 'Elements/Link';

import { dateFromISO } from 'Utils/dateFromISO';
import { isWithinInterval } from "date-fns";

// temp style for column right align
// & > div:first-child > div:nth-child(4n+3),
// & > div:first-child > div:nth-child(4n+4) {
// text-align: right;
// }

const TableStyled = styled(Table)`
    & > div:first-child > div:nth-child(4n+3) span,
    & > div:first-child > div:nth-child(4n+4) span {
        padding-right: 0;
    }
`;

const BlockTable = (props) => {

    const {
        linkStore,
        counterStore,
        filterText
    } = props;

    const [ orderBy, setOrderBy ] = useState({
        key: 'created_at',
        asc: false,
    });

    /**
     * если сортировка уже по текущей колонке - просто меняем направление
     */
    const setSortOrder = (key) => {

        console.log(key, orderBy.key);

        setOrderBy({
            key: key,
            asc: orderBy.key === key ? ! orderBy.asc : true,
        });
    }

    /**
     *
     */
    const makeTableContent = () => {
        var data = {
            header: {
                'created' : <Link onClick={() => setSortOrder('created_at')} to="#">Created</Link>,
                'name'    : <Link onClick={() => setSortOrder('name_text')} to="#">Name</Link>,
                'unique'  : 'Unique',
                'total'   : 'Total',
            },
            content: []
        };

        var contentData = [];

        const pushElement = (elem, isLink, isCounter) => {

            // TODO сделать определение более точным - что будет, если будет третий тип?
            const path = isLink ? 'links' : isCounter && 'counters';

            if (filterText) {
                if (elem['title'].toUpperCase().indexOf(filterText.toUpperCase()) < 0) {
                    return;
                }
            }

            contentData.push({
                'created'    : dateFromISO(elem['created_at']),
                'created_at' : elem['created_at'],
                'name_text'  : elem['title'],
                'name'       : <Link to={`/${path}/${elem['id']}`}>
                                        {elem['title']}
                                </Link>,
                'unique'     : elem['count_unique'] ?? 0,
                'total'      : elem['count_total'] ?? 0,
            });
        };

        linkStore.data.forEach(elem => {
            pushElement(elem, true, false);
        });

        counterStore.data.forEach(elem => {
            pushElement(elem, false, true);
        });

        contentData.sort((a, b) => {

            var valueA;
            var valueB;

            var orderKey = orderBy.key in a ? orderBy.key : Object.keys(a)[0];

            switch (orderKey) {

// this is not needed as created-at already comes in sort-ready format
//                case 'created_at':
//                    valueA = a['created_at'].substr(6, 4) + a['created_at'].substr(3, 2) + a['created_at'].substr(0, 2);
//                    valueB = b['created_at'].substr(6, 4) + b['created_at'].substr(3, 2) + b['created_at'].substr(0, 2);
//                    break;

                default:
                    valueA = a[orderKey].toString().toUpperCase();
                    valueB = b[orderKey].toString().toUpperCase();
            }

            if (valueA > valueB) return orderBy.asc ? 1 : -1;
            if (valueA < valueB) return orderBy.asc ? -1 : 1;
            return 0;
        });

        data.content = contentData;

        return data;
    }

    /**
     *
     */
    return (
        <Block>
            <TableStyled
                loading={props.linkStore.loading || props.counterStore.loading}
                noDataText={ props.filterText ? 'Nothing found' : 'No links or counters yet' }
                data={makeTableContent()}
            />
        </Block>
    );
}

const BlockTableObserver = observer(BlockTable);

export { BlockTableObserver as BlockTable }
