import React from 'react';

import { Filled } from 'Wrappers/Filled';

import { Link } from 'Elements/Link';
import { TextBasic } from 'Elements/TextBasic';
import { ConfigStore } from 'DataSource/Stores/ConfigStore.js';

const CounterInfo = (props) => {

    const {
        title,
        tail,
    } = props;

    const fullLinkUrl = `${ConfigStore.config.BASE_LINK_URL}/c/${tail}?r=[timestamp]`;

    /**
     *
     */
    return (
        <>
            <Filled style={{ marginTop: '16px' }}>
                <TextBasic>
                    {title}
                </TextBasic>
            </Filled>
            <Filled>
                <Link to={fullLinkUrl}>
                    {fullLinkUrl}
                </Link>
            </Filled>
            <Filled>
                <TextBasic>
                    If your system does not support "[timestamp]" tag, replace "[timestamp]" with your system's random number generator tag to protect against caching
                </TextBasic>
            </Filled>
        </>
    );
};

export { CounterInfo }
