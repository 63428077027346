import styled from 'styled-components';

const Block = styled.div`
    padding: 18px 18px;
    width: ${props => props._width ?? '100%'};
    background-color: ${props => props.transparent ? 'transparent' : '#252525'};
    margin: 10px auto;
    border-radius: 10px;
`;

export { Block }
