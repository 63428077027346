import React from 'react';
import { ButtonBasicIcon } from 'Elements/ButtonBasicIcon';

const ButtonLogout = (props) => {

    return (
        <ButtonBasicIcon {...props}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6488 1.145C13.9018 0.752 12.9998 1.278 12.9998 2.123C12.9998 2.552 13.2558 2.925 13.6348 3.126C16.4158 4.602 18.2008 7.716 17.6908 11.174C17.1868 14.602 14.3338 17.343 10.8898 17.728C6.20482 18.251 2.22282 14.581 2.22282 10C2.22282 7.004 3.92482 4.4 6.41282 3.101C6.76382 2.918 6.99982 2.572 6.99982 2.176V2.084C6.99982 1.256 6.11782 0.745 5.38282 1.129C1.85882 2.966 -0.433177 6.841 0.0688234 11.191C0.594823 15.742 4.30082 19.43 8.85382 19.936C14.8898 20.607 19.9998 15.9 19.9998 10C19.9998 6.155 17.8308 2.818 14.6488 1.145ZM8.99982 9V1C8.99982 0.447 9.44782 0 9.99982 0C10.5528 0 10.9998 0.447 10.9998 1V9C10.9998 9.552 10.5528 10 9.99982 10C9.44782 10 8.99982 9.552 8.99982 9Z" />
            </svg>
        </ButtonBasicIcon>
    );
}

export { ButtonLogout }


