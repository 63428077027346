import React from 'react';
import { observer } from 'mobx-react';

import { FillAndCenter } from 'Wrappers/FillAndCenter';
import { Loader } from 'Elements/Loader';

export const LoadingPage = observer((props) => {

    return (
        <FillAndCenter>
            <Loader />
        </FillAndCenter>
    );
})
