import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Block } from 'Wrappers/Block';
import { Table } from 'Elements/Table';

// this easyly can be broken if <Table> will change its structure

// temp style for column right align
// & > div:first-child > div:nth-child(3n+2),
// & > div:first-child > div:nth-child(3n+3) {
//     text-align: right;
// }

const TableStyled = styled(Table)`
    & > div:first-child > div:nth-child(3n+2) span,
    & > div:first-child > div:nth-child(3n+3) span {
        padding-right: 0;
    }
`;

export const BlockStat = observer((props) => {

    const makeTableContent = () => {

        var data = {
            header: {
                'date'    : props.firstColumnTitle,
                'unique'  : 'Unique',
                'total'   : 'Total',
            },
            content: []
        };

        var contentData = [];

        props.store.data.forEach(elem => {

            contentData.push({
                'date'   : elem['d'],
                'unique' : elem['u'],
                'total'  : elem['t'],
            });
        });

        data.content = contentData;

        return data;
    }

    /**
     *
     */
    return (
        <Block>
            <TableStyled
                columnTemplate='60% 20% 20%'
                loading={props.store.loading}
                noDataText='No data'
                data={makeTableContent()}
            />
        </Block>
    );
});
