import React from 'react';
import DatePickerOriginal, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'css/custom-datepicker.sass';
import enUs from 'date-fns/locale/en-US';

enUs.options.weekStartsOn = 1;
registerLocale('en-us-mod', enUs);

const DatePicker = (props) => {

    return <DatePickerOriginal { ...props } locale="en-us-mod" />
}

export { DatePicker }
