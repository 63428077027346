import React from 'react';
import styled from 'styled-components';

const DetailsDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 30px;
    align-self: center;
`;

const Details = (props) => {
    return (
        <DetailsDiv {...props}>
            {props.children}
        </DetailsDiv>
    );
}

export { Details }
