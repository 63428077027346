import React, {useState} from 'react';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';

import { ButtonBasicText } from 'Elements/ButtonBasicText';

const BlockCreateTypeSelector = (props) => {

    const {
        onLinkClick,
        onCounterClick,
        onRichmediaClick,
        onSurveyClick
    } = props;

    const [ linkButtonActive, stateSetLinkButtonActive ] = useState(false);
    const [ counterButtonActive, stateSetCounterButtonActive ] = useState(false);
    const [ richmediaButtonActive, stateSetRichmediaButtonActive ] = useState(false);
    const [ surveyButtonActive, stateSetSurveyButtonActive ] = useState(false);

    const clearActiveButtons = () => {
        stateSetLinkButtonActive(false);
        stateSetCounterButtonActive(false);
        stateSetRichmediaButtonActive(false);
        stateSetSurveyButtonActive(false);
    };

    /**
     *  Установка активности кнопки линка
     */
    const setLinkButtonActive = () => {
        clearActiveButtons();
        stateSetLinkButtonActive(true);
        onLinkClick();
    };

    /**
     *  Установка активности кнопки коунтеров
     */
    const setCountersButtonActive = () => {
        clearActiveButtons();
        stateSetCounterButtonActive(true);
        onCounterClick();
    };

    /**
     *  Установка активности кнопки ричмедиа
     */
    const setRichmediaButtonActive = () => {
        clearActiveButtons();
        stateSetRichmediaButtonActive(true);
        onRichmediaClick();
    };

    /**
     *  Установка активности кнопки ричмедиа
     */
    const setSurveyButtonActive = () => {
        clearActiveButtons();
        stateSetSurveyButtonActive(true);
        onSurveyClick();
    };

    /**
     *
     */
    return (
        <Block>
            <Filled>
                <ButtonBasicText onClick={ setLinkButtonActive } active={ linkButtonActive } >
                    Short link
                </ButtonBasicText>
                <ButtonBasicText onClick={ setCountersButtonActive } active={ counterButtonActive } >
                    Counters
                </ButtonBasicText>
                <ButtonBasicText onClick={ setRichmediaButtonActive } disabled active={ richmediaButtonActive } >
                    Richmedia
                </ButtonBasicText>
                <ButtonBasicText onClick={ setSurveyButtonActive } disabled active={ surveyButtonActive } >
                    Survey
                </ButtonBasicText>
            </Filled>
        </Block>
    );
};

export { BlockCreateTypeSelector }
