import React, { useState } from 'react';
import {observer} from "mobx-react";

import { CounterStore } from 'DataSource/Stores/CounterStore';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';

import { Title } from 'Elements/Title';
import { ButtonClose } from 'Elements/ButtonClose';
import { ErrorMessage } from 'Elements/ErrorMessage';
import { ButtonBasicText } from 'Elements/ButtonBasicText';
import { ButtonNotRubberText } from "Elements/ButtonNotRubberText";
import { Counter } from "./Counter";


const BlockCreateCounters = (props) => {

    const {
        setCreateCountersMode,
        campaignId,
        refresh,
        resultCallback,
    } = props;

    const [ errorMessage, stateSetErrorMessage ] = useState('');


    /**
     *  Валидация каунтеров перед отправкой на сервер
     */
    const validate = () => {

        let valid = true;

        CounterStore.counters.forEach(counter => {
            if(!counter.title){
                valid = false;
            }
        });

        if(!valid){
            stateSetErrorMessage('All counter names is required');
            return false;
        }else{
            return true;
        }
    };

    /**
     *  Отправка каунтеров на сервер
     */
    const onSubmitCountersCreate = () => {

        stateSetErrorMessage('');

        if ( ! validate()) {
            return;
        }

        var data = {
            campaign_id: campaignId,
            counters: [...CounterStore.counters]
        };

        CounterStore
            .createMass(data)
            .then( (response) => {
                refresh();

                setCreateCountersMode(false);

                CounterStore.clearCounters();

                if (resultCallback) {
                    resultCallback(response.data);
                }
            })
            .catch( (error, data) => {
                stateSetErrorMessage(error.response?.data?.message);
            });
    };

    /**
     *  Обработка нажатия на добавление каунтера
     */
    const onAddClick = () => {
        CounterStore.addCounter();
    };

    /**
     *  Обработка нажатия на закрытие блока создания каунтеров
     */
    const onCloseClick = () => {
        setCreateCountersMode(false);
        CounterStore.clearCounters();
    };

    /**
     *
     */
    return (
        <Block>
            <Filled>
                <Title>
                    Create new counters
                </Title>
                <ButtonNotRubberText onClick={ onAddClick } _width={'65px'}>
                    Add
                </ButtonNotRubberText>
                <ButtonClose onClick={() => onCloseClick()}/>
            </Filled>

            {
                CounterStore.counters?.length !== 0 && CounterStore.counters?.map((counter, index) =>  {
                    return (
                        <Counter
                            index={index}
                            key={`counter-${index}`}
                            showRemoveButton={index !== 0}
                        />
                    )
                })
            }

            <Filled>
                { errorMessage &&
                <ErrorMessage>
                    { errorMessage }
                </ErrorMessage>
                }
            </Filled>

            <Filled>
                <ButtonBasicText onClick={onSubmitCountersCreate}>
                    Create counters
                </ButtonBasicText>
            </Filled>
        </Block>
    );
};


const CountersBlockObserver = observer(BlockCreateCounters);

export { CountersBlockObserver as BlockCreateCounters }
