import React from 'react';
import styled from 'styled-components';

import { Logo } from 'Elements/Logo';
import { Image404 } from './Image404';

const Wrapper404 = styled.div`
    width: 500px;
    max-width: 100%;
    margin: 100px auto;

    .logo-404 {
        width: 80%;
        max-width: 100%;
        margin: 0 auto 50px auto;
    }
    .logo-404 svg {
        max-width: 100%;
    }

    .digits-404 {
        width: 100%;
        max-width: 100%;
    }
    .digits-404 svg {
        width: 100%;
        max-width: 100%;
    }
`;

const NotFoundPage = (props) => {

    /**
     *
     */
    return (
        <Wrapper404>
            <Logo className="logo-404" />
            <div className="digits-404">
                <Image404 />
            </div>
        </Wrapper404>
    );
}

export { NotFoundPage }
