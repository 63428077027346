import React from 'react';
import styled from 'styled-components';
import FadeIn from 'react-fade-in';

import { Partner } from 'Blocks/Partner';
import { Header } from 'Components/Header';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';

import { Title } from 'Elements/Title';
import { TextBasic } from 'Elements/TextBasic';
import { ButtonBack } from 'Elements/ButtonBack';
import { ButtonBasicIcon } from 'Elements/ButtonBasicIcon';

import { IconFacebook } from './IconFacebook';
import { IconVk } from './IconVk';
import { IconTwitter } from './IconTwitter';
import { IconLinkedin } from './IconLinkedin';
import { IconInstagram } from './IconInstagram';
import { IconTelegram } from './IconTelegram';
import { IconWhatsapp } from './IconWhatsapp';

import { LogoIvi } from './LogoIvi';

const FullSizeIconButton = styled(ButtonBasicIcon)`
    padding: 0px;
    cursor: pointer;

    svg {
        width: 100%;
        height: 100%;
    }
`;

const AboutPage = (props) => {

    /**
     *
     */
    return (
        <>
            <FadeIn>
                <Block>
                    <Header />
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Filled>
                        <Title>
                            About
                        </Title>
                        <ButtonBack onClick={ () => window.history.back() } />
                    </Filled>
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Filled>
                        <TextBasic>
                            <p>
                                Я приближался к месту моего назначения. Вокруг меня, прижимаясь к самой дороге, зеленел лес, изредка уступая место полянам,
                                поросшим желтой осокою. Солнце садилось уже который час, все никак не могло сесть и висело низко над горизонтом.
                                Машина катилась по узкой дороге, засыпанной хрустящим гравием. Крупные камни я пускал под колесо,
                                и каждый раз в багажнике лязгали и громыхали пустые канистры.
                            </p>
                            <p>
                                Справа из леса вышли двое, ступили на обочину и остановились, глядя в мою сторону. Один из них поднял руку.
                                Я сбросил газ, их рассматривая. Это были, как мне показалось, охотники, молодые люди, может быть, немного старше меня.
                                Их лица понравились мне, и я остановился. Тот, что поднимал руку, просунул в машину смуглое горбоносое лицо и спросил улыбаясь:
                            </p>
                            <p>
                                — Вы нас не подбросите до Соловца?
                            </p>
                        </TextBasic>
                    </Filled>
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Filled>
                        <Title>
                            Contacts
                        </Title>
                    </Filled>
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Filled>
                        <TextBasic>
                            <p>
                                Второй, с рыжей бородой и без усов, тоже улыбался, выглядывая из-за его плеча. Положительно, это были приятные люди.
                            </p>
                            <p>
                                — Давайте садитесь, — сказал я. — Один вперед, другой назад, а то у меня там барахло, на заднем сиденье.
                            </p>
                            <p>
                                — Благодетель! — обрадованно произнес горбоносый, снял с плеча ружье и сел рядом со мной.
                            </p>
                            <p>
                                Бородатый, нерешительно заглядывая в заднюю дверцу, сказал:
                            </p>
                            <p>
                                — А можно, я здесь немножко того?..
                            </p>
                            <p>
                                Я перегнулся через спинку и помог ему расчистить место, занятое спальным мешком и свернутой палаткой. Он деликатно уселся, поставив ружье между коленей.
                            </p>
                            <p>
                                — Дверцу прикройте получше, — сказал я.
                            </p>
                        </TextBasic>
                    </Filled>
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Filled>
                        <Title>
                            Get in touch
                        </Title>
                    </Filled>
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Filled>
                        <FullSizeIconButton link="https://facebook.com" newWindow>
                            <IconFacebook />
                        </FullSizeIconButton>
                        <FullSizeIconButton style={{ padding: '6px' }} link="https://vk.com" newWindow>
                            <IconVk />
                        </FullSizeIconButton>
                        <FullSizeIconButton style={{ padding: '10px' }} link="https://twitter.com" newWindow>
                            <IconTwitter />
                        </FullSizeIconButton>
                        <FullSizeIconButton style={{ padding: '6px 10px 10px 10px' }} link="https://linkedin.com" newWindow>
                            <IconLinkedin />
                        </FullSizeIconButton>
                        <FullSizeIconButton style={{ padding: '8px' }} link="https://instagram.com" newWindow>
                            <IconInstagram />
                        </FullSizeIconButton>
                        <FullSizeIconButton link="https://telegram.org" newWindow>
                            <IconTelegram />
                        </FullSizeIconButton>
                        <FullSizeIconButton style={{ padding: '10px' }} link="https://whatsapp.com" newWindow>
                            <IconWhatsapp />
                        </FullSizeIconButton>
                    </Filled>
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Filled>
                        <Title>
                            Information
                        </Title>
                    </Filled>
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Filled>
                        <TextBasic>
                            <p>
                                Я проснулся посреди ночи оттого, что в комнате разговаривали. Разговаривали двое,
                                едва слышным шепотом. Голоса были очень похожи, но один был немного сдавленный
                                и хрипловатый, а другой выдавал крайнее раздражение.
                            </p>
                            <p>
                                — Не хрипи, — шептал раздраженный. — Ты можешь не хрипеть?
                            </p>
                            <p>
                                — Могу, — отозвался сдавленный и заперхал.
                            </p>
                            <p>
                                — Да тише ты… — прошипел раздраженный.
                            </p>
                            <p>
                                — Хрипунец, — объяснил сдавленный. — Утренний кашель курильщика… — Он снова заперхал.
                            </p>
                            <p>
                                — Удались отсюда, — сказал раздраженный.
                            </p>

                        </TextBasic>
                    </Filled>
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Filled>
                        <Title>
                            Partners
                        </Title>
                    </Filled>
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Partner
                        image={<LogoIvi />}
                        text="Онлайн-кинотеатр ivi"
                        link="https://www.ivi.tv"
                        linkText="www.ivi.tv"
                    />
                </Block>
            </FadeIn>

            <FadeIn>
                <Block>
                    <Partner
                        image={<LogoIvi />}
                        text="Онлайн-кинотеатр ivi"
                        link="https://www.ivi.tv"
                        linkText="www.ivi.tv"
                    />
                </Block>
            </FadeIn>

        </>
    );
}

export { AboutPage }
