import React from 'react';
import styled from 'styled-components';

const TextBasicSpan = styled.span`
    & > p {
        margin-bottom: 16px;
    }
`;

const TextBasic = (props) => {

    return (
        <TextBasicSpan className={props.className}>
            {props.children}
        </TextBasicSpan>
    );
}

export { TextBasic, TextBasicSpan }