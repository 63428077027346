import React from 'react';
import styled from 'styled-components';
import { Link as ReactLink } from 'react-router-dom'

const style = `
    padding: 0;
    height: 32px;
    background-color: #C4C4C4;
    color: #252525;
    border-radius: 5px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${props => props._width ?? 'auto'};
    cursor: default;
    margin: 6px;
    vertical-align: middle;
    user-select: none;

    &:hover {
        background-color: #00A8FF;
    }
`;

const ButtonBasicDiv = styled.div`
    ${style}
`;

const ButtonBasicLink = styled(ReactLink)`
    text-decoration: none;
    ${style}
`;

const ButtonExternalLink = styled.a`
    ${style}
    text-decoration: none;
    cursor: pointer;
`;

const ButtonBasicDisabled = styled(ButtonBasicDiv)`
    color: rgba(37, 37, 37, 0.4);
    background-color: rgba(196, 196, 196, 0.3);
    
    &:hover {
        background-color: rgba(196, 196, 196, 0.3);
    }
`;

const ButtonBasicActive = styled(ButtonBasicDiv)`
    background-color: #00A8FF
`;

const ButtonBasic = (props) => {

    if (props.link && (props.link.substring(0, 7) === 'http://' || props.link.substring(0, 8) === 'https://')) {
        return (
            <ButtonExternalLink {...props} href={props.link} target={props.newWindow ? "_blank" : null }>
                { props.children }
            </ButtonExternalLink>
        );
    }

    if (props.link) {
        return (
            <ButtonBasicLink {...props} to={props.link}>
                { props.children }
            </ButtonBasicLink>
        );
    }

    if(props.disabled) {
        return (
            <ButtonBasicDisabled {...props} onClick={ () => {} }>
                { props.children }
            </ButtonBasicDisabled>
        )
    }

    if(!props.disabled && props.active) {
        return (
            <ButtonBasicActive {...props} >
                { props.children }
            </ButtonBasicActive>
        )
    }

    return (
        <ButtonBasicDiv {...props} >
            { props.children }
        </ButtonBasicDiv>
    );


}

export { ButtonBasic }


