import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Block } from 'Wrappers/Block';
import { Filled } from 'Wrappers/Filled';

import { Title } from 'Elements/Title';
import { ButtonClose } from 'Elements/ButtonClose';
import { TextField } from 'Elements/TextField';
import { ButtonDel } from 'Elements/ButtonDel';
import { ButtonBasicText } from 'Elements/ButtonBasicText';

const BlockEditCampaign = (props) => {

    const {
        setEditMode,
        CampaignStore,
        refresh,
        campaignId,
    } = props;

    const [ campaignName, stateSetCampaignName ] = useState(CampaignStore.data.length ? CampaignStore.data[0].title : '');
    const [ redirectTo, stateSetRedirectTo ] = useState('');

    /**
     *
     */
    const onSubmitCampaignEdit = () => {

        if (campaignName.trim() === '') {
            console.warn('empty name, no changes will be made');
            return;
        }

        CampaignStore.update({
            id: campaignId,
            title: campaignName,
        })
        .then( () => {
            refresh();
            stateSetCampaignName('');
            setEditMode(false);
        });
    }

    /**
     *
     */
    const onCampaignDelete = () => {

        if ( ! window.confirm('Delete this campaign?')) {
            return;
        }

        CampaignStore.drop(campaignId)
        .then( () => {
            stateSetRedirectTo("/campaigns");
        });
    }

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    /**
     *
     */
    return (

        <Block>
            <Filled>
                <Title>
                    Edit campaign
                </Title>
                <ButtonDel onClick={ onCampaignDelete } title="Delete campaign" />
                <ButtonClose onClick={ () => setEditMode(false) } />
            </Filled>
            <Filled>
                <TextField placeholder="Enter new campaign name" value={campaignName} onChange={ e => stateSetCampaignName(e.target.value) } />
            </Filled>
            <Filled>
                <ButtonBasicText onClick={onSubmitCampaignEdit}>
                    Rename campaign
                </ButtonBasicText>
            </Filled>
        </Block>

    );
}

export { BlockEditCampaign }
