import React from 'react';
import { ButtonBasicIcon } from 'Elements/ButtonBasicIcon';

const ButtonSearch = (props) => {

    return (
        <ButtonBasicIcon {...props}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.243 14.343L5.657 15.757L1.415 20L0 18.586L4.243 14.343ZM12 14C8.692 14 6 11.308 6 8C6 4.691 8.692 2 12 2C15.309 2 18 4.691 18 8C18 11.308 15.309 14 12 14ZM12 0C7.582 0 4 3.582 4 8C4 12.418 7.582 16 12 16C16.419 16 20 12.418 20 8C20 3.582 16.419 0 12 0Z" />
            </svg>
        </ButtonBasicIcon>
    );
}

export { ButtonSearch }


