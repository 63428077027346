import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div`

    width: ${props => props._width ?? 'auto'};
    ${props => props._marginBottom ? `margin-bottom: ${props._marginBottom}` : ''};
    line-height: 0px;

    & > svg {
        fill: #00A8FF;
    }
`;


const Logo = (props) => {

    return (
        <LogoContainer {...props}>
            <svg viewBox="0 0 451 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M409.31 0H406.552V2.75862H409.31V0Z"/>
                <path d="M412.069 0H409.31V2.75862H412.069V0Z"/>
                <path d="M414.828 0H412.069V2.75862H414.828V0Z"/>
                <path d="M420.345 0H417.586V2.75862H420.345V0Z"/>
                <path d="M425.862 0H423.103V2.75862H425.862V0Z"/>
                <path d="M412.069 2.75862H409.31V5.51725H412.069V2.75862Z"/>
                <path d="M414.828 2.75862H412.069V5.51725H414.828V2.75862Z"/>
                <path d="M417.586 2.75862H414.828V5.51725H417.586V2.75862Z"/>
                <path d="M420.345 2.75862H417.586V5.51725H420.345V2.75862Z"/>
                <path d="M423.103 2.75862H420.345V5.51725H423.103V2.75862Z"/>
                <path d="M425.862 2.75862H423.103V5.51725H425.862V2.75862Z"/>
                <path d="M428.621 2.75862L425.862 2.75862V5.51725H428.621V2.75862Z"/>
                <path d="M406.552 5.51724H403.793V8.27586H406.552V5.51724Z"/>
                <path d="M409.31 5.51725L406.552 5.51724V8.27586H409.31V5.51725Z"/>
                <path d="M412.069 5.51725H409.31V8.27586H412.069V5.51725Z"/>
                <path d="M420.345 5.51725H417.586V8.27586H420.345V5.51725Z"/>
                <path d="M428.621 5.51725H425.862L425.862 8.27586H428.621V5.51725Z"/>
                <path d="M406.552 8.27586H403.793V11.0345H406.552V8.27586Z"/>
                <path d="M409.31 8.27586H406.552V11.0345H409.31V8.27586Z"/>
                <path d="M417.586 8.27586L414.828 8.27587V11.0345H417.586V8.27586Z"/>
                <path d="M423.103 8.27587L420.345 8.27586V11.0345H423.103V8.27587Z"/>
                <path d="M406.552 11.0345H403.793V13.7931H406.552V11.0345Z"/>
                <path d="M412.069 11.0345L409.31 11.0345V13.7931H412.069V11.0345Z"/>
                <path d="M428.621 11.0345H425.862V13.7931H428.621V11.0345Z"/>
                <path d="M406.552 13.7931H403.793V16.5517H406.552V13.7931Z"/>
                <path d="M409.31 13.7931H406.552V16.5517H409.31V13.7931Z"/>
                <path d="M417.586 13.7931H414.828V16.5517H417.586V13.7931Z"/>
                <path d="M425.862 13.7931L423.103 13.7931V16.5517H425.862L425.862 13.7931Z"/>
                <path d="M428.621 13.7931H425.862L425.862 16.5517H428.621V13.7931Z"/>
                <path d="M406.552 16.5517H403.793V19.3103H406.552V16.5517Z"/>
                <path d="M412.069 16.5517H409.31V19.3103H412.069V16.5517Z"/>
                <path d="M417.586 16.5517H414.828V19.3103H417.586V16.5517Z"/>
                <path d="M423.103 16.5517H420.345V19.3103H423.103V16.5517Z"/>
                <path d="M428.621 16.5517H425.862L425.862 19.3103H428.621V16.5517Z"/>
                <path d="M406.552 19.3103H403.793V22.069H406.552V19.3103Z"/>
                <path d="M417.586 19.3103H414.828V22.069H417.586V19.3103Z"/>
                <path d="M423.103 19.3103H420.345V22.069H423.103V19.3103Z"/>
                <path d="M425.862 19.3103H423.103V22.069H425.862L425.862 19.3103Z"/>
                <path d="M428.621 19.3103H425.862L425.862 22.069H428.621V19.3103Z"/>
                <path d="M384.483 22.069H381.724V24.8276H384.483V22.069Z"/>
                <path d="M390 22.069H387.241V24.8276H390V22.069Z"/>
                <path d="M392.759 22.069H390V24.8276H392.759V22.069Z"/>
                <path d="M395.517 22.069H392.759V24.8276H395.517V22.069Z"/>
                <path d="M398.276 22.069H395.517V24.8276H398.276V22.069Z"/>
                <path d="M401.034 22.069H398.276V24.8276H401.034V22.069Z"/>
                <path d="M409.31 22.069L406.552 22.069V24.8276H409.31V22.069Z"/>
                <path d="M412.069 22.069H409.31V24.8276H412.069V22.069Z"/>
                <path d="M414.828 22.069L412.069 22.069V24.8276H414.828V22.069Z"/>
                <path d="M417.586 22.069H414.828V24.8276H417.586V22.069Z"/>
                <path d="M434.138 22.069H431.379V24.8276H434.138V22.069Z"/>
                <path d="M436.897 22.069H434.138V24.8276H436.897V22.069Z"/>
                <path d="M439.655 22.069H436.897V24.8276H439.655V22.069Z"/>
                <path d="M442.414 22.069H439.655V24.8276H442.414V22.069Z"/>
                <path d="M445.172 22.069H442.414V24.8276H445.172V22.069Z"/>
                <path d="M384.483 24.8276H381.724V27.5862H384.483V24.8276Z"/>
                <path d="M387.241 24.8276H384.483V27.5862H387.241V24.8276Z"/>
                <path d="M390 24.8276H387.241V27.5862H390V24.8276Z"/>
                <path d="M392.759 24.8276H390V27.5862H392.759V24.8276Z"/>
                <path d="M409.31 24.8276H406.552V27.5862H409.31V24.8276Z"/>
                <path d="M417.586 24.8276H414.828V27.5862H417.586V24.8276Z"/>
                <path d="M420.345 24.8276L417.586 24.8276V27.5862H420.345V24.8276Z"/>
                <path d="M423.103 24.8276H420.345V27.5862H423.103V24.8276Z"/>
                <path d="M428.621 24.8276H425.862V27.5862H428.621V24.8276Z"/>
                <path d="M431.379 24.8276L428.621 24.8276V27.5862H431.379L431.379 24.8276Z"/>
                <path d="M436.897 24.8276H434.138V27.5862H436.897V24.8276Z"/>
                <path d="M447.931 24.8276L445.172 24.8276V27.5862H447.931V24.8276Z"/>
                <path d="M387.241 27.5862H384.483V30.3448H387.241V27.5862Z"/>
                <path d="M392.759 27.5862H390V30.3448H392.759V27.5862Z"/>
                <path d="M398.276 27.5862H395.517V30.3448H398.276V27.5862Z"/>
                <path d="M401.034 27.5862H398.276V30.3448H401.034V27.5862Z"/>
                <path d="M406.552 27.5862L403.793 27.5862V30.3448H406.552V27.5862Z"/>
                <path d="M409.31 27.5862H406.552V30.3448H409.31V27.5862Z"/>
                <path d="M414.828 27.5862L412.069 27.5862V30.3448H414.828V27.5862Z"/>
                <path d="M417.586 27.5862H414.828V30.3448H417.586V27.5862Z"/>
                <path d="M420.345 27.5862H417.586V30.3448H420.345V27.5862Z"/>
                <path d="M423.103 27.5862H420.345V30.3448H423.103V27.5862Z"/>
                <path d="M425.862 27.5862H423.103V30.3448H425.862L425.862 27.5862Z"/>
                <path d="M428.621 27.5862H425.862L425.862 30.3448H428.621V27.5862Z"/>
                <path d="M431.379 27.5862H428.621V30.3448H431.379V27.5862Z"/>
                <path d="M434.138 27.5862H431.379V30.3448H434.138V27.5862Z"/>
                <path d="M442.414 27.5862H439.655V30.3448H442.414V27.5862Z"/>
                <path d="M447.931 27.5862H445.172V30.3448H447.931V27.5862Z"/>
                <path d="M450.69 27.5862L447.931 27.5862V30.3448H450.69V27.5862Z"/>
                <path d="M390 30.3448H387.241V33.1035H390V30.3448Z"/>
                <path d="M403.793 30.3448H401.034V33.1035H403.793L403.793 30.3448Z"/>
                <path d="M412.069 30.3448H409.31V33.1035H412.069V30.3448Z"/>
                <path d="M420.345 30.3448H417.586V33.1035H420.345V30.3448Z"/>
                <path d="M428.621 30.3448H425.862L425.862 33.1035H428.621V30.3448Z"/>
                <path d="M436.897 30.3448L434.138 30.3448V33.1035H436.897V30.3448Z"/>
                <path d="M450.69 30.3448H447.931V33.1035H450.69V30.3448Z"/>
                <path d="M384.483 33.1034H381.724V35.8621H384.483V33.1034Z"/>
                <path d="M387.241 33.1035L384.483 33.1034V35.8621H387.241V33.1035Z"/>
                <path d="M390 33.1035H387.241V35.8621H390V33.1035Z"/>
                <path d="M392.759 33.1034L390 33.1035V35.8621H392.759V33.1034Z"/>
                <path d="M401.034 33.1035L398.276 33.1034V35.8621H401.034V33.1035Z"/>
                <path d="M403.793 33.1035H401.034V35.8621H403.793V33.1035Z"/>
                <path d="M406.552 33.1034L403.793 33.1035V35.8621H406.552V33.1034Z"/>
                <path d="M412.069 33.1035H409.31V35.8621H412.069V33.1035Z"/>
                <path d="M417.586 33.1035L414.828 33.1034V35.8621H417.586V33.1035Z"/>
                <path d="M423.103 33.1034L420.345 33.1035V35.8621H423.103V33.1034Z"/>
                <path d="M431.379 33.1034L428.621 33.1035V35.8621H431.379V33.1034Z"/>
                <path d="M434.138 33.1035L431.379 33.1034V35.8621H434.138V33.1035Z"/>
                <path d="M436.897 33.1035H434.138V35.8621H436.897V33.1035Z"/>
                <path d="M439.655 33.1034L436.897 33.1035V35.8621H439.655V33.1034Z"/>
                <path d="M445.172 33.1034H442.414V35.8621H445.172V33.1034Z"/>
                <path d="M447.931 33.1035L445.172 33.1034V35.8621H447.931V33.1035Z"/>
                <path d="M450.69 33.1035H447.931V35.8621H450.69V33.1035Z"/>
                <path d="M384.483 35.8621H381.724V38.6207H384.483V35.8621Z"/>
                <path d="M390 35.8621H387.241V38.6207H390V35.8621Z"/>
                <path d="M392.759 35.8621H390V38.6207H392.759V35.8621Z"/>
                <path d="M395.517 35.8621L392.759 35.8621V38.6207H395.517V35.8621Z"/>
                <path d="M409.31 35.8621H406.552V38.6207H409.31V35.8621Z"/>
                <path d="M428.621 35.8621L425.862 35.8621V38.6207H428.621V35.8621Z"/>
                <path d="M431.379 35.8621H428.621V38.6207H431.379V35.8621Z"/>
                <path d="M436.897 35.8621H434.138V38.6207H436.897V35.8621Z"/>
                <path d="M442.414 35.8621H439.655V38.6207H442.414V35.8621Z"/>
                <path d="M447.931 35.8621H445.172V38.6207H447.931V35.8621Z"/>
                <path d="M384.483 38.6207H381.724V41.3793H384.483V38.6207Z"/>
                <path d="M392.759 38.6207H390V41.3793H392.759V38.6207Z"/>
                <path d="M395.517 38.6207H392.759V41.3793H395.517V38.6207Z"/>
                <path d="M398.276 38.6207H395.517V41.3793H398.276V38.6207Z"/>
                <path d="M401.034 38.6207H398.276V41.3793H401.034V38.6207Z"/>
                <path d="M414.828 38.6207H412.069V41.3793H414.828V38.6207Z"/>
                <path d="M417.586 38.6207H414.828V41.3793H417.586V38.6207Z"/>
                <path d="M425.862 38.6207H423.103V41.3793H425.862L425.862 38.6207Z"/>
                <path d="M434.138 38.6207H431.379L431.379 41.3793H434.138V38.6207Z"/>
                <path d="M436.897 38.6207H434.138V41.3793H436.897V38.6207Z"/>
                <path d="M439.655 38.6207H436.897V41.3793H439.655V38.6207Z"/>
                <path d="M442.414 38.6207H439.655V41.3793H442.414V38.6207Z"/>
                <path d="M447.931 38.6207H445.172V41.3793H447.931V38.6207Z"/>
                <path d="M450.69 38.6207H447.931V41.3793H450.69V38.6207Z"/>
                <path d="M384.483 41.3793H381.724V44.1379H384.483V41.3793Z"/>
                <path d="M392.759 41.3793H390V44.1379H392.759V41.3793Z"/>
                <path d="M395.517 41.3793H392.759V44.1379H395.517V41.3793Z"/>
                <path d="M403.793 41.3793H401.034V44.1379H403.793V41.3793Z"/>
                <path d="M406.552 41.3793H403.793V44.1379H406.552V41.3793Z"/>
                <path d="M414.828 41.3793H412.069V44.1379H414.828V41.3793Z"/>
                <path d="M423.103 41.3793H420.345V44.1379H423.103V41.3793Z"/>
                <path d="M431.379 41.3793H428.621V44.1379H431.379L431.379 41.3793Z"/>
                <path d="M436.897 41.3793H434.138V44.1379H436.897V41.3793Z"/>
                <path d="M439.655 41.3793H436.897V44.1379H439.655V41.3793Z"/>
                <path d="M450.69 41.3793H447.931V44.1379H450.69V41.3793Z"/>
                <path d="M384.483 44.1379H381.724V46.8965H384.483V44.1379Z"/>
                <path d="M392.759 44.1379H390V46.8965H392.759V44.1379Z"/>
                <path d="M401.034 44.1379L398.276 44.1379V46.8965H401.034V44.1379Z"/>
                <path d="M412.069 44.1379L409.31 44.1379V46.8965H412.069V44.1379Z"/>
                <path d="M414.828 44.1379H412.069V46.8965H414.828V44.1379Z"/>
                <path d="M425.862 44.1379L423.103 44.1379V46.8965H425.862V44.1379Z"/>
                <path d="M428.621 44.1379L425.862 44.1379V46.8965H428.621V44.1379Z"/>
                <path d="M431.379 44.1379H428.621V46.8965H431.379V44.1379Z"/>
                <path d="M434.138 44.1379H431.379V46.8965H434.138V44.1379Z"/>
                <path d="M436.897 44.1379H434.138V46.8965H436.897V44.1379Z"/>
                <path d="M439.655 44.1379H436.897V46.8965H439.655V44.1379Z"/>
                <path d="M445.172 44.1379H442.414V46.8965H445.172V44.1379Z"/>
                <path d="M406.552 46.8966H403.793V49.6552H406.552V46.8966Z"/>
                <path d="M409.31 46.8965L406.552 46.8966V49.6552H409.31V46.8965Z"/>
                <path d="M417.586 46.8966L414.828 46.8965V49.6552H417.586V46.8966Z"/>
                <path d="M420.345 46.8966H417.586V49.6552H420.345V46.8966Z"/>
                <path d="M423.103 46.8965L420.345 46.8966V49.6552H423.103V46.8965Z"/>
                <path d="M428.621 46.8965H425.862L425.862 49.6552H428.621V46.8965Z"/>
                <path d="M439.655 46.8965H436.897L436.897 49.6552H439.655V46.8965Z"/>
                <path d="M442.414 46.8965H439.655V49.6552H442.414V46.8965Z"/>
                <path d="M409.31 49.6552H406.552V52.4138H409.31V49.6552Z"/>
                <path d="M420.345 49.6552H417.586V52.4138H420.345V49.6552Z"/>
                <path d="M423.103 49.6552H420.345V52.4138H423.103V49.6552Z"/>
                <path d="M428.621 49.6552H425.862V52.4138H428.621V49.6552Z"/>
                <path d="M434.138 49.6552H431.379V52.4138H434.138V49.6552Z"/>
                <path d="M439.655 49.6552H436.897V52.4138H439.655V49.6552Z"/>
                <path d="M445.172 49.6552L442.414 49.6552V52.4138H445.172V49.6552Z"/>
                <path d="M447.931 49.6552H445.172V52.4138H447.931V49.6552Z"/>
                <path d="M450.69 49.6552H447.931V52.4138H450.69V49.6552Z"/>
                <path d="M406.552 52.4138H403.793V55.1724H406.552V52.4138Z"/>
                <path d="M409.31 52.4138H406.552V55.1724H409.31V52.4138Z"/>
                <path d="M412.069 52.4138H409.31V55.1724H412.069V52.4138Z"/>
                <path d="M417.586 52.4138H414.828V55.1724H417.586V52.4138Z"/>
                <path d="M420.345 52.4138H417.586V55.1724H420.345V52.4138Z"/>
                <path d="M428.621 52.4138H425.862V55.1724H428.621V52.4138Z"/>
                <path d="M439.655 52.4138H436.897V55.1724H439.655V52.4138Z"/>
                <path d="M442.414 52.4138H439.655V55.1724H442.414V52.4138Z"/>
                <path d="M447.931 52.4138H445.172V55.1724H447.931V52.4138Z"/>
                <path d="M450.69 52.4138H447.931V55.1724H450.69V52.4138Z"/>
                <path d="M406.552 55.1724H403.793V57.931H406.552V55.1724Z"/>
                <path d="M409.31 55.1724H406.552V57.931H409.31V55.1724Z"/>
                <path d="M417.586 55.1724H414.828V57.931H417.586V55.1724Z"/>
                <path d="M423.103 55.1724L420.345 55.1724V57.931H423.103V55.1724Z"/>
                <path d="M425.862 55.1724L423.103 55.1724V57.931H425.862L425.862 55.1724Z"/>
                <path d="M428.621 55.1724H425.862L425.862 57.931H428.621V55.1724Z"/>
                <path d="M431.379 55.1724L428.621 55.1724V57.931H431.379V55.1724Z"/>
                <path d="M434.138 55.1724H431.379V57.931H434.138V55.1724Z"/>
                <path d="M436.897 55.1724L434.138 55.1724V57.931H436.897L436.897 55.1724Z"/>
                <path d="M439.655 55.1724H436.897L436.897 57.931H439.655V55.1724Z"/>
                <path d="M445.172 55.1724H442.414V57.931H445.172V55.1724Z"/>
                <path d="M450.69 55.1724H447.931V57.931H450.69V55.1724Z"/>
                <path d="M406.552 57.931H403.793V60.6896H406.552V57.931Z"/>
                <path d="M409.31 57.931H406.552V60.6896H409.31V57.931Z"/>
                <path d="M412.069 57.931L409.31 57.931V60.6896H412.069V57.931Z"/>
                <path d="M425.862 57.931H423.103V60.6896H425.862V57.931Z"/>
                <path d="M434.138 57.931H431.379L431.379 60.6896H434.138V57.931Z"/>
                <path d="M439.655 57.931H436.897L436.897 60.6896H439.655V57.931Z"/>
                <path d="M442.414 57.931H439.655V60.6896H442.414V57.931Z"/>
                <path d="M445.172 57.931H442.414V60.6896H445.172V57.931Z"/>
                <path d="M447.931 57.931H445.172V60.6896H447.931V57.931Z"/>
                <path d="M450.69 57.931H447.931V60.6896H450.69V57.931Z"/>
                <path d="M406.552 60.6896H403.793V63.4483H406.552V60.6896Z"/>
                <path d="M409.31 60.6896H406.552V63.4483H409.31V60.6896Z"/>
                <path d="M414.828 60.6896L412.069 60.6896V63.4483H414.828V60.6896Z"/>
                <path d="M417.586 60.6896H414.828V63.4483H417.586V60.6896Z"/>
                <path d="M425.862 60.6896H423.103V63.4483H425.862V60.6896Z"/>
                <path d="M442.414 60.6896H439.655V63.4483H442.414V60.6896Z"/>
                <path d="M445.172 60.6896H442.414V63.4483H445.172V60.6896Z"/>
                <path d="M450.69 60.6896H447.931V63.4483H450.69V60.6896Z"/>
                <path d="M409.31 63.4483H406.552V66.2069H409.31V63.4483Z"/>
                <path d="M414.828 63.4483H412.069V66.2069H414.828V63.4483Z"/>
                <path d="M423.103 63.4483L420.345 63.4483V66.2069H423.103V63.4483Z"/>
                <path d="M428.621 63.4483L425.862 63.4483L425.862 66.2069H428.621V63.4483Z"/>
                <path d="M431.379 63.4483H428.621V66.2069H431.379V63.4483Z"/>
                <path d="M436.897 63.4483H434.138V66.2069H436.897V63.4483Z"/>
                <path d="M439.655 63.4483L436.897 63.4483V66.2069H439.655V63.4483Z"/>
                <path d="M442.414 63.4483H439.655V66.2069H442.414V63.4483Z"/>
                <path d="M450.69 63.4483H447.931V66.2069H450.69V63.4483Z"/>
                <path d="M406.552 66.2069H403.793V68.9655H406.552V66.2069Z"/>
                <path d="M409.31 66.2069H406.552V68.9655H409.31V66.2069Z"/>
                <path d="M414.828 66.2069H412.069V68.9655H414.828V66.2069Z"/>
                <path d="M436.897 66.2069H434.138V68.9655H436.897V66.2069Z"/>
                <path d="M439.655 66.2069H436.897V68.9655H439.655V66.2069Z"/>
                <path d="M442.414 66.2069H439.655V68.9655H442.414V66.2069Z"/>
                <path d="M445.172 66.2069H442.414V68.9655H445.172V66.2069Z"/>
                <path d="M447.931 66.2069H445.172V68.9655H447.931V66.2069Z"/>
                <path d="M450.69 66.2069H447.931V68.9655H450.69V66.2069Z"/>
                <path d="M381.724 0V19.3103H401.034V0H381.724ZM398.138 16.4138H384.621V2.89655H398.138V16.4138Z"/>
                <path d="M431.379 0V19.3103H450.69V0H431.379ZM447.793 16.4138H434.276V2.89655H447.793V16.4138Z"/>
                <path d="M381.724 49.6552V68.9655H401.034V49.6552H381.724ZM398.138 66.069H384.621V52.5517H398.138V66.069Z"/>
                <path d="M395.517 5.51724H387.241V13.7931H395.517V5.51724Z"/>
                <path d="M445.172 5.51724H436.897V13.7931H445.172V5.51724Z"/>
                <path d="M395.517 55.1724H387.241V63.4483H395.517V55.1724Z"/>
                <path d="M34.8125 13.4203H18.25V57.2328H16.5625V13.4203H0V11.7328H34.8125V13.4203Z"/>
                <path d="M55.25 52.7953L56.1562 55.5453L57.0625 52.7953L72.3437 11.7328H74.1875L57.1875 57.2328H55.125L38.125 11.7328H39.9688L55.25 52.7953Z"/>
                <path d="M108.781 46.3265C108.781 44.389 108.323 42.7536 107.406 41.4203C106.51 40.0661 105.083 38.8786 103.125 37.8578C101.187 36.8161 98.25 35.7536 94.3125 34.6703C90.375 33.5869 87.4167 32.4932 85.4375 31.389C83.4792 30.2849 82.0417 29.0036 81.125 27.5453C80.2083 26.0869 79.75 24.3161 79.75 22.2328C79.75 18.9203 81.1458 16.2432 83.9375 14.2015C86.7292 12.139 90.3646 11.1078 94.8437 11.1078C97.7604 11.1078 100.375 11.6807 102.687 12.8265C105.021 13.9515 106.823 15.5453 108.094 17.6078C109.365 19.6494 110 21.9619 110 24.5453H108.312C108.312 21.1494 107.062 18.3474 104.562 16.139C102.062 13.9099 98.8229 12.7953 94.8437 12.7953C90.8437 12.7953 87.6042 13.6911 85.125 15.4828C82.6667 17.2536 81.4375 19.4828 81.4375 22.1703C81.4375 24.8994 82.5104 27.0974 84.6562 28.764C86.8021 30.4307 90.3437 31.9099 95.2812 33.2015C100.219 34.4724 103.833 35.9515 106.125 37.639C109.042 39.7849 110.5 42.6599 110.5 46.264C110.5 48.5557 109.854 50.5869 108.562 52.3578C107.271 54.1078 105.437 55.4619 103.062 56.4203C100.687 57.3786 98.0208 57.8578 95.0625 57.8578C91.7708 57.8578 88.8125 57.3161 86.1875 56.2328C83.5625 55.1286 81.5833 53.5661 80.25 51.5453C78.9375 49.5244 78.2812 47.139 78.2812 44.389H79.9687C79.9687 48.1182 81.3646 51.014 84.1562 53.0765C86.9479 55.139 90.5833 56.1703 95.0625 56.1703C99.0417 56.1703 102.323 55.264 104.906 53.4515C107.49 51.6182 108.781 49.2432 108.781 46.3265Z"/>
                <path d="M117.75 55.7015C117.75 55.2224 117.917 54.8161 118.25 54.4828C118.583 54.1286 118.99 53.9515 119.469 53.9515C119.948 53.9515 120.354 54.1286 120.687 54.4828C121.042 54.8161 121.219 55.2224 121.219 55.7015C121.219 56.1807 121.042 56.5869 120.687 56.9203C120.354 57.2536 119.948 57.4203 119.469 57.4203C118.99 57.4203 118.583 57.2536 118.25 56.9203C117.917 56.5869 117.75 56.1807 117.75 55.7015Z"/>
                <path d="M134.844 38.014V57.2328H133.156V11.7328H147.375C152.021 11.7328 155.625 12.9099 158.188 15.264C160.771 17.5974 162.062 20.8474 162.062 25.014C162.062 29.139 160.802 32.3265 158.281 34.5765C155.781 36.8265 152.219 37.9724 147.594 38.014H134.844ZM134.844 36.3265H147.375C151.479 36.3265 154.667 35.3369 156.938 33.3578C159.229 31.3786 160.375 28.6182 160.375 25.0765C160.375 21.4099 159.229 18.5557 156.938 16.514C154.667 14.4515 151.479 13.4203 147.375 13.4203H134.844V36.3265Z"/>
                <path d="M188.906 37.764H173.125V57.2328H171.438V11.7328H185.938C190.5 11.7328 194.125 12.9099 196.812 15.264C199.521 17.6182 200.875 20.8161 200.875 24.8578C200.875 27.9619 199.906 30.6807 197.969 33.014C196.052 35.3265 193.604 36.764 190.625 37.3265L202.781 56.7953V57.2328H201L188.906 37.764ZM173.125 36.0765H187.469C190.969 36.0765 193.792 35.014 195.938 32.889C198.104 30.764 199.188 28.0869 199.188 24.8578C199.188 21.3161 197.99 18.5244 195.594 16.4828C193.219 14.4411 189.958 13.4203 185.812 13.4203H173.125V36.0765Z"/>
                <path d="M245.688 37.889C245.688 41.8265 244.979 45.3161 243.562 48.3578C242.167 51.3786 240.167 53.7224 237.562 55.389C234.958 57.0349 231.958 57.8578 228.562 57.8578C223.438 57.8578 219.292 56.0349 216.125 52.389C212.958 48.7432 211.375 43.8265 211.375 37.639V31.0765C211.375 27.1599 212.073 23.6807 213.469 20.639C214.885 17.5974 216.896 15.2536 219.5 13.6078C222.125 11.9411 225.125 11.1078 228.5 11.1078C231.896 11.1078 234.896 11.9411 237.5 13.6078C240.125 15.2536 242.146 17.6078 243.562 20.6703C244.979 23.7119 245.688 27.1807 245.688 31.0765V37.889ZM244 31.014C244 25.514 242.604 21.1078 239.812 17.7953C237.021 14.4619 233.25 12.7953 228.5 12.7953C223.812 12.7953 220.073 14.4515 217.281 17.764C214.49 21.0557 213.094 25.5453 213.094 31.2328V37.889C213.094 41.514 213.719 44.7224 214.969 47.514C216.24 50.2849 218.042 52.4203 220.375 53.9203C222.708 55.4203 225.438 56.1703 228.562 56.1703C233.312 56.1703 237.073 54.514 239.844 51.2015C242.615 47.889 244 43.3682 244 37.639V31.014Z"/>
                <path d="M258.5 11.7328L277.219 54.764L296 11.7328H298.469V57.2328H296.781V14.2328L277.906 57.2328H276.562L257.781 14.4515V57.2328H256.094V11.7328H258.5Z"/>
                <path d="M343.188 37.889C343.188 41.8265 342.479 45.3161 341.062 48.3578C339.667 51.3786 337.667 53.7224 335.062 55.389C332.458 57.0349 329.458 57.8578 326.062 57.8578C320.938 57.8578 316.792 56.0349 313.625 52.389C310.458 48.7432 308.875 43.8265 308.875 37.639V31.0765C308.875 27.1599 309.573 23.6807 310.969 20.639C312.385 17.5974 314.396 15.2536 317 13.6078C319.625 11.9411 322.625 11.1078 326 11.1078C329.396 11.1078 332.396 11.9411 335 13.6078C337.625 15.2536 339.646 17.6078 341.062 20.6703C342.479 23.7119 343.188 27.1807 343.188 31.0765V37.889ZM341.5 31.014C341.5 25.514 340.104 21.1078 337.312 17.7953C334.521 14.4619 330.75 12.7953 326 12.7953C321.312 12.7953 317.573 14.4515 314.781 17.764C311.99 21.0557 310.594 25.5453 310.594 31.2328V37.889C310.594 41.514 311.219 44.7224 312.469 47.514C313.74 50.2849 315.542 52.4203 317.875 53.9203C320.208 55.4203 322.938 56.1703 326.062 56.1703C330.812 56.1703 334.573 54.514 337.344 51.2015C340.115 47.889 341.5 43.3682 341.5 37.639V31.014Z"/>
            </svg>
        </LogoContainer>
    );

}

export { Logo }
